import React from "react";

export const ReceiptsIcon = ({
  width = 22,
  height = 22,
  color = "#101828",
}) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
    <path
      d="M12.0597 6.04395V17.9556"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.8169 8.21143H10.6813C9.61223 8.21143 8.75416 9.06029 8.75416 10.1024C8.75416 11.1445 9.61926 11.9933 10.6813 11.9933H13.4383C14.5074 11.9933 15.3655 12.8422 15.3655 13.8843C15.3655 14.9333 14.5004 15.7753 13.4383 15.7753H8.74713"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.1203 18.7702C20.1203 20.5507 18.6433 22 16.8287 22H7.29158C5.47699 22 4 20.5507 4 18.7702V5.22981C4 3.44928 5.47699 2 7.29158 2H16.8287C18.6433 2 20.1203 3.44928 20.1203 5.22981"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
