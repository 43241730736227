import React, { createContext, useContext, useState } from "react";
import { UserContext } from "./UserContext";
import { useNavigate } from "react-router-dom";
import { PaymentsContext } from "./PaymentsContext";
import { causationStates } from "constants";
import useFeaturesFlags from "hooks/useFeaturesFlags";
import { FEATURES } from "screens/SettingsScreen/features";

export const CausationContext = createContext();

export function CausationProvider({ children }) {
  const navigate = useNavigate();
  const { currentCompany } = useContext(UserContext);
  const { invoices, internalGetInvoices } = useContext(PaymentsContext);
  const [originalItems, setOriginalItems] = useState([]);

  const hasErpCausation = !!currentCompany.erp_sheet_id;
  const erpName = currentCompany?.erp_name;

  const handleNextInvoice = async (id) => {
    let invoicesRow = invoices;
    try {
      if (!invoicesRow.length) {
        invoicesRow = await internalGetInvoices();
      }

      const index = invoicesRow.findIndex(
        (invoice) => invoice.id === parseInt(id)
      );
      if (index !== -1) {
        const nextInvoice = invoicesRow
          .slice(index + 1)
          .find(
            (invoice) =>
              invoice.causation_state === causationStates.draft &&
              invoice.origin === "dian"
          );
        if (nextInvoice) {
          navigate(`/payments/causation/${nextInvoice.id}`, {
            replace: true,
          });

          return window.location.reload();
        }
      }
    } catch (error) {
      console.log("Error in handleNextCausation:", error);
    }
  };

  const { isFeatureEnabled } = useFeaturesFlags();
  const isTotalizeItemsEnabled = isFeatureEnabled(FEATURES.TOTALIZE_ITEMS);

  return (
    <CausationContext.Provider
      value={{
        hasErpCausation,
        erpName,
        handleNextInvoice,
        originalItems,
        isTotalizeItemsEnabled,
        handleSetOriginalItems: (items) => {
          setOriginalItems(items);
        },
      }}
    >
      {children}
    </CausationContext.Provider>
  );
}
