import { TextField } from "@mui/material";
import React from "react";

export const Observation = ({ handleOnChange, formValues, isCaused }) => {
  return (
    <div
      style={{
        width: "100%",
      }}
    >
      <p
        style={{
          color: "#344054",
          fontSize: "14px",
          fontWeight: 500,
          lineHeight: "20px",
          marginBottom: "6px",
        }}
      >
        Observaciones
      </p>
      <TextField
        fullWidth
        size="small"
        multiline
        placeholder="Deja tus observaciones. También adjuntaremos el PDF de la DIAN."
        rows={5}
        disabled={isCaused}
        variant="outlined"
        onChange={(e) => handleOnChange(e)}
        value={formValues?.observation}
        name="observation"
        sx={{
          "& .MuiInputBase-input": {
            fontSize: "14px",
          },
          "& .MuiInputBase-input::placeholder": {
            fontSize: "14px",
          },
        }}
      />
    </div>
  );
};
