import React, { useMemo, useState, useEffect, useContext } from "react";
import {
  Autocomplete,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";

import { ChangeStatusModal, Table, TableSkeleton } from "../../../commons";
import { UserContext } from "../../../contexts";
import { TableEmptyState } from "../../../commons/Layout/EmptyState";
import { styles } from "../../ReceiptsScreen/styles";
import { StatusFilter } from "../../ReceiptsScreen";
import {
  GetBOTotalReceiptsServices,
  SearchBOCompanies,
  SearchServices,
} from "../../../services/api/backoffice";
import { useNavigate } from "react-router-dom";
import { BackofficeReceiptServicesContext } from "../../../contexts/Backoffice/receiptsServices";
import { boServicesReceiptsColumns, filterNITOptions } from "../../../utils";
import { SearchIcon } from "../../../assets";
import { ReceiptDetails } from "commons/Sidebars";

export const BOServicesReceiptsScreen = () => {
  const [pageIndex, setPageIndex] = useState(1);
  const [totals, setTotal] = useState();
  const [totalPages, setTotalPages] = useState(1);
  const {
    createRequest,
    BOreceiptsService,
    getBOreceiptsService,
    statusModalIsOpen,
    setStatusModalIsOpen,
    changeBOReceiptServiceStatus,
    statusSelected,
    setStatusSelected,
    serviceIdSelected,
    setServiceIdSelected,
    companyIdSelected,
    setCompanyIdSelected,
    hashSelected,
    setHashSelected,
  } = useContext(BackofficeReceiptServicesContext);
  const [detailsModalIsOpen, setDetailModalIsOpen] = useState(false);
  const [selectedTrx, setSelectedTrx] = useState();
  const [searchOptions, setSearchOptions] = useState([]);
  const [secondSearchOptions, setSecondSearchOptions] = useState([]);
  const { currentUser } = useContext(UserContext);
  const navigate = useNavigate();
  const [selectedTrxStatus, setSelectedTrxStatus] = useState();
  const [companySelected, setCompanySelected] = useState({});

  useEffect(() => {
    if (!currentUser.backoffice_user) {
      navigate("/payments", { replace: true });
    }
  }, []);

  useEffect(() => {
    getBOreceiptsService({
      pageIndex,
      status: statusSelected,
      serviceId: serviceIdSelected,
      companyId: companyIdSelected,
      transactionHash: hashSelected,
    });
    GetBOTotalReceiptsServices({
      status: statusSelected,
      serviceId: serviceIdSelected,
      companyId: companyIdSelected,
      transactionHash: hashSelected,
    }).then((res) => {
      setTotal(res);
    });
  }, [
    pageIndex,
    statusSelected,
    serviceIdSelected,
    companyIdSelected,
    hashSelected,
  ]);

  const getSearchOptions = (query) => {
    SearchServices({ query }).then((res) => {
      setSearchOptions(res);
    });
  };

  const handleSearchChange = (ser) => {
    if (ser) {
      setServiceIdSelected(ser.id);
    } else {
      setServiceIdSelected("all");
    }
    setPageIndex(1);
  };

  useEffect(() => {
    if (totals && totals.total > 0) {
      setTotalPages(Math.ceil(totals.total / 50));
    } else {
      setTotalPages(1);
    }
  }, [totals]);

  const columns = useMemo(() => boServicesReceiptsColumns, []);

  const openDetailsModal = (id) => {
    setSelectedTrx(id);
    setDetailModalIsOpen(true);
  };

  const openStatusModal = (id, status) => {
    setSelectedTrx(id);
    setSelectedTrxStatus(status);
    setStatusModalIsOpen(true);
  };

  const handleCompanySearchChange = (company) => {
    if (company) {
      setCompanyIdSelected(company.id);
      setCompanySelected(company);
    } else {
      setCompanyIdSelected("all");
      setCompanySelected({});
    }
    setPageIndex(1);
  };

  const handleThirdSearchChange = (hash) => {
    hash ? setHashSelected(hash.toUpperCase()) : setHashSelected("");
  };

  const getCompanySearchOptions = (query) => {
    SearchBOCompanies(query).then((res) => {
      setSecondSearchOptions(res);
    });
  };

  const renderAutocomplete = () => {
    return (
      <>
        <Autocomplete
          options={searchOptions}
          size="small"
          sx={{ width: "35%", minWidth: "164px" }}
          freeSolo
          getOptionLabel={(option) => option?.alias || ""}
          onChange={(event, option) =>
            option?.id ? handleSearchChange(option) : handleSearchChange()
          }
          onInputChange={(event) =>
            event?.target?.value && getSearchOptions(event.target.value)
          }
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                style: { fontSize: 14, backgroundColor: "#ffffff" },
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              placeholder={"Buscar servicio"}
            />
          )}
        />
        <Autocomplete
          options={secondSearchOptions}
          size="small"
          sx={{ width: "30%", minWidth: "164px" }}
          freeSolo
          filterOptions={filterNITOptions}
          getOptionLabel={(option) => {
            return option.name;
          }}
          onChange={(event, option) => {
            if (option?.id) {
              handleCompanySearchChange(option);
            } else {
              handleCompanySearchChange();
            }
          }}
          onInputChange={(event) => {
            if (event?.target?.value?.length >= 3) {
              getCompanySearchOptions(event.target.value);
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                style: { fontSize: 14, backgroundColor: "#ffffff" },
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              placeholder={"Buscar cliente"}
            />
          )}
          value={
            companyIdSelected !== "all"
              ? companySelected
              : {
                  name: "",
                }
          }
        />
      </>
    );
  };

  return (
    <>
      {statusModalIsOpen && (
        <ChangeStatusModal
          itemId={selectedTrx}
          isOpen={statusModalIsOpen}
          setIsOpen={setStatusModalIsOpen}
          action={changeBOReceiptServiceStatus}
          prevStatus={selectedTrxStatus}
          pageIndex={pageIndex}
          isReceipts={true}
        />
      )}
      {detailsModalIsOpen && (
        <ReceiptDetails
          visible={detailsModalIsOpen}
          onClose={() => setDetailModalIsOpen(false)}
          id={selectedTrx}
          isReceiptsServicesBo
        />
      )}
      <div style={styles.titleContainer}>
        <Typography sx={styles.title} noWrap>
          Backoffice de soportes-services
        </Typography>
      </div>
      <StatusFilter
        statusSelected={statusSelected}
        setStatusSelected={setStatusSelected}
      />
      {createRequest ? (
        <TableSkeleton />
      ) : (
        <Table
          columns={columns}
          data={BOreceiptsService}
          setPageIndex={setPageIndex}
          section="BOReceiptsServices"
          expandAction={openDetailsModal}
          expandIcon
          pageCount={totalPages}
          showAutocomplete={true}
          searchOptions={searchOptions}
          searchPlaceholder="Buscar proveedor"
          getOptions={(query) => getSearchOptions(query)}
          handleSearchChange={handleSearchChange}
          statusPillAction={openStatusModal}
          isBO={true}
          renderAutocomplete={renderAutocomplete}
          emptyState={
            <TableEmptyState
              title={"No se encontraron servicios"}
              subtitle={
                "Aquí se mostrarán los pagos o agrupamientos de facturas que realices."
              }
            />
          }
          extraFilters={true}
          thirdSearchPlaceholder="Buscar código"
          handleThirdSearchChange={handleThirdSearchChange}
          fourthSearchPlaceholder="Buscar banco"
        />
      )}
    </>
  );
};
