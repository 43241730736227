import { Stack, TextField, Typography } from "@mui/material";
import { ValidationMessage } from "../../../components";
import { useForm, useWatch } from "react-hook-form";
import { styles } from "../../SupplierDetails/styles";
import { PrimaryButton } from "../../../buttons";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { es } from "date-fns/locale";
import addMonths from "date-fns/addMonths";
import { CalendarIcon } from "assets";

const SiigoIntegrationURLForm = ({
  primaryAction,
  resendAccessToken,
  loading,
  setLoading,
  showStartDate,
  onlyShowDate,
  credentialsError = false,
  setCredentialsError = () => {},
  data = {},
}) => {
  const { register, handleSubmit, formState, control } = useForm({
    defaultValues: data || {
      url: "",
    },
  });
  const formValues = useWatch({ control });

  const helperText = credentialsError ? "Revisa los datos." : "";

  const onSubmit = async (data) => {
    setLoading(true);
    setCredentialsError(false);
    await primaryAction(data);
    setLoading(false);
  };

  const datePickerProps = register("startDate", {
    required: showStartDate ? true : false,
  });

  return (
    <form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
      <Stack my={2} spacing={2}>
        {showStartDate && (
          <Stack spacing={1}>
            <label htmlFor="startDate" style={styles.inputLabel}>
              Elige una fecha para importar información
            </label>
            <label htmlFor="startDate" style={styles.inputSubLabel}>
              (Puedes elegir hasta 180 días hacia atrás)
            </label>
            <LocalizationProvider
              adapterLocale={es}
              dateAdapter={AdapterDateFns}
            >
              <DatePicker
                value={formValues.startDate}
                format="dd/MM/yyyy"
                slotProps={{
                  textField: {
                    InputProps: {
                      style: { fontSize: 14 },
                    },
                    size: "small",
                    error: false,
                  },
                }}
                maxDate={new Date()}
                minDate={addMonths(new Date(), -6)}
                {...datePickerProps}
                onChange={(value) => {
                  datePickerProps.onChange({
                    target: {
                      name: "startDate",
                      value,
                    },
                  });
                }}
                slots={{
                  openPickerIcon: () => <CalendarIcon stroke="#667085" />,
                }}
              />
            </LocalizationProvider>
            <ValidationMessage errors={formState.errors} field="startDate" />
          </Stack>
        )}
      </Stack>
      {!onlyShowDate && (
        <>
          <Stack my={2} spacing={2}>
            <Stack spacing={1}>
              <Typography sx={styles.inputLabel} noWrap>
                Usuario API
              </Typography>
              <TextField
                size="small"
                variant="outlined"
                placeholder="nombre@empresa.com"
                type="email"
                value={formValues.userName}
                InputProps={{
                  style: { fontSize: 14 },
                }}
                {...register("userName", { required: true })}
              />
              {helperText && (
                <Typography sx={styles.inputError} noWrap>
                  {helperText}
                </Typography>
              )}
            </Stack>
          </Stack>
          <Stack my={2} spacing={2}>
            <Stack spacing={1}>
              <label htmlFor="key" style={styles.inputLabel}>
                Access Key
              </label>
              <textarea
                rows={2}
                style={{
                  resize: "none",
                  padding: "6px",
                  outline: "none",
                  borderRadius: 8,
                  fontSize: "14px",
                }}
                spellCheck={false}
                {...register("key", { required: true })}
                value={formValues.key}
              />
              {helperText && (
                <Typography sx={styles.inputError} noWrap>
                  {helperText}
                </Typography>
              )}
              <ValidationMessage errors={formState.errors} field="key" />
            </Stack>
          </Stack>
        </>
      )}
      <Stack
        direction="row"
        justifyContent="space-between"
        style={{ width: "100%" }}
      >
        <PrimaryButton
          width="100%"
          text="Importar información"
          isDisable={
            loading ||
            (showStartDate && !formValues.startDate) ||
            (!onlyShowDate && (!formValues.userName || !formValues.key))
          }
          loading={loading}
          type="submit"
        />
      </Stack>
    </form>
  );
};
export default SiigoIntegrationURLForm;
