import { TrackJS } from "trackjs";
import { toast } from "react-toastify";
import { PAYMENT_METHOD_PSE_KEY, toastOptions } from "../../constants";
import {
  getTransactionToPay,
  getLinkToCobre,
} from "../../commons/modals/PaymentModal";
import { hasSomeAssociatedDocumentDeleted } from "../../utils";

export const useCobre = (
  transactionPayload,
  redirectUrl,
  setOpenDeletedAlert = () => {},
  setLimitExceededAlert = () => {},
  section = null
) => {
  const makePaymentInCobre = async (isCollections) => {
    try {
      transactionPayload.paymentGateway = "cobre";
      transactionPayload.paymentMethod = PAYMENT_METHOD_PSE_KEY;
      const transaction = await getTransactionToPay(transactionPayload);

      if (transaction?.errors && section !== "customer") {
        setLimitExceededAlert((limitExceeded) => {
          return {
            ...limitExceeded,
            visible: true,
            availableAmount: transaction.errors[0].availableAmount,
            limitAmount: transaction.errors[0].limitAmount,
          };
        });
        return;
      }

      if (hasSomeAssociatedDocumentDeleted(transaction)) {
        setOpenDeletedAlert(true);
        return;
      }

      const linkUrl = await getLinkToCobre(
        transaction.hash,
        redirectUrl,
        isCollections,
        transaction.source_of_funds_id
      );
      window.location = linkUrl;
    } catch (error) {
      TrackJS.console.error(error.message);
      toast.error("Ha ocurrido un error, intente nuevamente", toastOptions);
    }
  };
  return {
    makePaymentInCobre,
  };
};
