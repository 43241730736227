import { getCommissionAmount } from "commons/checkout/getCommissionAmount";

const getPayanaComission = ({
  amountTotalInvoicePayana = 0,
  newPricing,
  newPricingMetadata,
  selectedPaymentMethod,
  totalAmount,
  type,
  cardFeePercentage,
  payoutsNumber,
  formatAmount,
}) => {
  return getCommissionAmount({
    ...newPricingMetadata,
    enabled: newPricing,
    payoutsNumber,
    totalAmount,
    type,
    selectedPaymentMethod,
    amountTotalInvoicePayana,
    cardFeePercentage,
    formatAmount,
  });
};

export default getPayanaComission;
