export const statusCausationPillWording = {
  draft: "Borrador",
  done: "Causada",
};

export const statusCausationStyles = {
  done: {
    color: "#027A48",
    backgroundColor: "#F2F4F7",
  },
  draft: {
    color: "#344054",
    backgroundColor: "#FFFAEB",
  },
};

export const causationTableStatusFilters = () => {
  return [
    baseStatusesFilter.all,
    baseStatusesFilter.draft,
    baseStatusesFilter.done,
  ];
};

const baseStatusesFilter = {
  all: {
    value: "all",
    label: "Todas",
  },
  draft: {
    value: "draft",
    label: "Borrador",
  },
  done: {
    value: "done",
    label: "Causadas",
  },
};

export const causationStates = {
  draft: "draft",
  caused: "done",
};

export const pdfState = {
  in_process: "in_process",
  done: "done",
  failed: "failed",
};

export const causationMarkAs = {
  unmark: "unmarkAsCausation",
  mark: "markAsCausation",
};

export const erpNames = {
  siigoContador: "siigoContador",
  worldOffice: "worldOffice",
  siigoPyme: "siigoPyme",
  siigoNube: "siigoNube",
};

export const erpLabels = {
  siigoContador: "Siigo Contador",
  worldOffice: "World Office",
  siigoPyme: "Siigo Pyme",
  siigoNube: "Siigo Nube",
};

export const urlBaseGoogleSheet = "https://docs.google.com/spreadsheets/d/";
