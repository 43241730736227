import React, { useState, useRef } from "react";
import {
  renderAutocomplete,
  ServicesEmptyState,
} from "screens/ServicesScreenCommons/components";
import { ListPage } from "screens/ListPage";
import { servicesTransactionsColumns } from "utils";
import { ServicesCreatePaymentModal } from "../ServicesPaymentScreen/ServicesCreatePaymentModal";
import { MassiveCreateResultModal } from "../ServicesPaymentScreen/MassiveCreateModal/MassiveCreateResultModal";
import { useMediaQuery } from "react-responsive";
import { Box } from "@mui/material";
import { SubNavigation } from "commons/Layout/components/SubNavigation";

export const ServicesTransactionsScreen = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 481px)" });
  const [query, setQuery] = useState("");
  const [isManualCreateOpen, setIsManualCreateOpen] = useState(false);
  const csvImportButton = useRef(null);
  const openManualCreate = () => setIsManualCreateOpen(true);
  const onClose = () => setIsManualCreateOpen(false);
  const openMassiveCreate = () => csvImportButton.current.click();
  const getSearchOptions = async (q) => setQuery(q);
  const url = `/transactions?page=1&type=service&text=${query}&per_page=100`;
  const autocomplete = () =>
    renderAutocomplete({
      isMobile: isMobile,
      inputValue: query,
      searchOptions: [],
      onTableSearchSelected: () => {},
      getSearchOptions,
    });
  return (
    <>
      <ListPage
        section="serviceTrx"
        preventListRefreshWhen={() => query.length > 1 && query.length < 3}
        url={url}
        queries={query ? { query } : {}}
        pageTitle="Servicios"
        tableColumns={servicesTransactionsColumns}
        pageEmptyState={
          <ServicesEmptyState
            openManualCreate={openManualCreate}
            openMassiveCreate={openMassiveCreate}
            currentScreenTitle={"Pago de servicios"}
            subNavigation={
              <Box sx={{ marginTop: "4px" }}>
                <SubNavigation type="services" />
              </Box>
            }
            pageTitle={"Servicios"}
          />
        }
        renderAutocomplete={autocomplete}
        subNavigation={
          <Box sx={{ mt: 2, mb: 3 }}>
            <SubNavigation type="services" />
          </Box>
        }
        showSubNavigation={true}
      />
      <ServicesCreatePaymentModal
        visible={isManualCreateOpen}
        onClose={onClose}
      />
      <MassiveCreateResultModal csvImportButton={csvImportButton} />
    </>
  );
};
