import { useState } from "react";

const useSteps = (stepsNames) => {
  const [currentStepName, setStep] = useState(stepsNames.INITIAL_STEP);
  return {
    setStep: (stepName) => {
      if (!Object.values(stepsNames).includes(stepName)) {
        throw new Error(`${stepName} is not a step name.`);
      }
      setStep(stepName);
    },
    getValueByStepName: (stepNameToValueMapper) =>
      stepNameToValueMapper[currentStepName] || stepNameToValueMapper.fallback,
  };
};

export { useSteps };
