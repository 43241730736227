import { Box, Divider, TextField, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../contexts";
import { UpdateUser } from "../../../services";
import { styles } from "../styles";
import { ChangePasswordForm } from "./ChangePasswordForm";
import { toast } from "react-toastify";
import { toastOptions } from "../../../constants";
import { PhoneNumberInput } from "../../../commons";
import { phoneChars } from "../../../utils";
import { SettingsFooter } from "./FooterHandler";

export const AccountTab = () => {
  const { currentUser, setCurrentUser, getMe } = useContext(UserContext);
  const [userName, setUserName] = useState(currentUser?.name);
  const [userPhone, setUserPhone] = useState(currentUser?.phone || "+57");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmationPassword, setConfirmationPassword] = useState("");
  const [passwordErrors, setPasswordErrors] = useState({});
  const [isLoadingPasswordChange, setIsLoadingPasswordChange] = useState(false);
  const [saveSettingsButtonIsDisabled, setSaveSettingsButtonIsDisabled] =
    useState(true);
  const [phoneError, setPhoneError] = useState({
    helperText: "",
    error: false,
  });

  useEffect(() => {
    if (currentUser.name) {
      setUserName(currentUser.name);
    }
    if (currentUser.phone) {
      setUserPhone(currentUser.phone);
    }
  }, [currentUser]);

  const updateUserHandler = async () => {
    if (!userPhone) {
      setPhoneError({
        helperText: "Este campo no puede estar vacío",
        error: true,
      });
      return;
    }
    try {
      const response = await UpdateUser({
        id: currentUser.id,
        formValues: {
          name: userName,
          phone: userPhone,
        },
      });
      setCurrentUser(response.user);
      getMe();
      toast.success("¡El usuario se actualizó con éxito!", toastOptions);
      setSaveSettingsButtonIsDisabled(true);
    } catch (error) {
      toast.error(
        "Ha ocurrido un error, por favor intente más tarde.",
        toastOptions
      );
    }
  };

  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          paddingLeft: "0px",
        }}
        px={4}
      >
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          my={2}
          sx={{ marginLeft: "32px" }}
        >
          <Typography sx={styles.inputLabel} noWrap>
            Nombre completo
          </Typography>
          <TextField
            sx={{ width: "512px", backgroundColor: "#FFFFFF" }}
            size="small"
            variant="outlined"
            value={userName || currentUser.name}
            onChange={(e) => {
              setUserName(e.target.value);
              setSaveSettingsButtonIsDisabled(false);
            }}
            InputProps={{ style: { fontSize: 14 } }}
          />
        </Stack>
        <Divider sx={styles.divider} />
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          my={2}
          sx={{ marginLeft: "32px" }}
        >
          <Box>
            <Typography sx={styles.inputLabel} noWrap>
              Correo electrónico
            </Typography>
            <Typography sx={styles.inputSubLabel}>
              Enviaremos las notificaciones a este correo electrónico.
            </Typography>
          </Box>
          <TextField
            sx={{
              width: "512px",
              backgroundColor: "#F9FAFB",
              color: "#D0D5DD",
            }}
            size="small"
            variant="outlined"
            value={currentUser.email}
            disabled
            InputProps={{ style: { fontSize: 14 } }}
          />
        </Stack>
        <Divider sx={styles.divider} />
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          my={2}
          sx={{ marginLeft: "32px" }}
        >
          <Typography
            sx={{ ...styles.inputLabel, justifyContent: "flex-start" }}
          >
            Número de celular
          </Typography>
          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{ width: "512px" }}
          >
            <PhoneNumberInput
              phone={userPhone}
              setPhone={(value) => {
                setUserPhone(value);
                if (value?.length === 0) {
                  setPhoneError({
                    helperText: "Este campo no puede quedar vacío.",
                    error: true,
                  });
                } else if (
                  value?.length < phoneChars(value) ||
                  value?.length > phoneChars(value)
                ) {
                  setPhoneError({
                    helperText: `El número ingresado debe tener ${phoneChars(
                      value
                    )} caracteres.`,
                    error: true,
                  });
                } else {
                  setPhoneError({
                    helperText: "",
                    error: false,
                  });
                }
                setSaveSettingsButtonIsDisabled(false);
              }}
            />
            {phoneError.error && (
              <Typography sx={styles.inputError} noWrap>
                {phoneError.helperText}
              </Typography>
            )}{" "}
          </Stack>
        </Stack>
        <Divider sx={styles.divider} />
        <Stack
          flexDirection="row"
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          my={2}
          sx={{ marginLeft: "32px" }}
        >
          <Box>
            <Typography sx={styles.inputLabel}>Cambiar contraseña</Typography>
            <Typography sx={styles.inputSubLabel}>
              La contraseña debe tener al menos 8 caracteres.
            </Typography>
          </Box>
          <ChangePasswordForm
            oldPassword={oldPassword}
            setOldPassword={setOldPassword}
            newPassword={newPassword}
            setNewPassword={setNewPassword}
            confirmationPassword={confirmationPassword}
            setConfirmationPassword={setConfirmationPassword}
            passwordErrors={passwordErrors}
            setPasswordErrors={setPasswordErrors}
            isLoadingPasswordChange={isLoadingPasswordChange}
            setIsLoadingPasswordChange={setIsLoadingPasswordChange}
          />
        </Stack>
      </Box>
      <SettingsFooter
        saveSettingsChanges={updateUserHandler}
        saveSettingsButtonIsDisable={
          saveSettingsButtonIsDisabled ||
          phoneError.error ||
          passwordErrors.new_password
        }
      />
    </>
  );
};
