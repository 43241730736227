import React from "react";
import { ApprovedIcon } from "../../../assets";
import { PrimaryButton, SecondaryButton } from "commons/buttons";
import { styles } from "./styles";

function SuccessCausation({
  onClose,
  statusCode,
  hasErpCausation,
  handleNextInvoice,
  onCloseModal,
  id,
}) {
  return (
    <div
      style={{ maxWidth: "400px", display: "flex", flexDirection: "column" }}
    >
      <div style={{ display: "flex", alignSelf: "center" }}>
        <ApprovedIcon />
      </div>
      <p style={{ ...styles.modalTitle, color: "#039855", marginTop: "16px" }}>
        ¡Causación exitosa!
      </p>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          textAlign: "left",
          paddingBottom: "5px",
          gap: "4px",
        }}
      >
        <p style={styles.modalText}>
          {hasErpCausation
            ? "Podrás verlo reflejado en el Google Sheets que te brindamos."
            : "Podrás verlo reflejado desde el listado de compra y gasto en Siigo."}
        </p>
        {statusCode === 200 && (
          <span style={{ ...styles.modalText, marginTop: "4px" }}>
            En Payana hemos actualizado tu factura y agregado etiquetas.
          </span>
        )}
      </div>
      <div style={styles.buttonGroup}>
        <SecondaryButton
          text="Entendido"
          action={onClose}
          style={{ width: "50%" }}
        />
        <PrimaryButton
          text="Ir al siguiente documento"
          action={() => {
            onCloseModal();
            handleNextInvoice(id);
          }}
          style={{ width: "50%" }}
        />
      </div>
    </div>
  );
}

export default SuccessCausation;
