import React, { useContext, useState, useEffect } from "react";
import { styles } from "../styles";
import { PayrollContext } from "../../../contexts";
import { NumericFormat } from "react-number-format";
import { CustomPayrollTextField } from "./CustomPayrollTextField";
import { permissions } from "../../../constants";
import { useIsUserAllowedTo } from "../../../utils";
import {
  Box,
  IconButton,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Tooltip } from "commons/components";

export const AmountInput = ({ row, id }) => {
  const { updatePayrollAmount } = useContext(PayrollContext);
  const { isUserAllowedTo } = useIsUserAllowedTo();
  const [amount, setAmount] = useState(row.original.amount || 0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (row) {
      setAmount(row.original.amount || 0);
    }
  }, [row]);

  const hasChanges = amount !== row.original.amount;

  const handleValueChange = (values) => {
    const { floatValue, value } = values;
    const newValue = value === "" ? 0 : floatValue;
    setAmount(newValue);
  };

  const handleConfirm = async () => {
    if (hasChanges) {
      setIsLoading(true);
      try {
        await updatePayrollAmount(row.original.period, id, amount, false);
      } finally {
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      }
    }
  };

  const handleCancel = () => {
    setAmount(row.original.amount || 0);
  };

  const renderEndAdornment = () => {
    if (isLoading) {
      return (
        <InputAdornment position="end" sx={{ marginLeft: 0 }}>
          <CircularProgress size={20} sx={{ color: "#6938EF" }} />
        </InputAdornment>
      );
    }

    return hasChanges ? (
      <InputAdornment position="end" sx={{ marginLeft: 0 }}>
        <Tooltip title="Cancelar">
          <span>
            <IconButton
              onClick={handleCancel}
              edge="end"
              size="small"
              sx={{
                color: "#B42318",
                padding: "4px",
                "&:hover": {
                  backgroundColor: "#FEF3F2",
                },
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title="Confirmar">
          <span>
            <IconButton
              onClick={handleConfirm}
              edge="end"
              size="small"
              sx={{
                color: "#027A48",
                padding: "4px",
                "&:hover": {
                  backgroundColor: "#F0FDF9",
                },
              }}
            >
              <CheckIcon fontSize="small" />
            </IconButton>
          </span>
        </Tooltip>
      </InputAdornment>
    ) : null;
  };

  return (
    <>
      {isUserAllowedTo(permissions.PAYROLLS_UPDATE) ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
            minWidth: "200px",
            maxWidth: "200px",
          }}
        >
          <NumericFormat
            id="amount"
            name="amount"
            thousandSeparator="."
            decimalSeparator=","
            allowNegative={false}
            decimalScale={2}
            isNumericString
            value={amount}
            customInput={CustomPayrollTextField}
            onValueChange={handleValueChange}
            allowLeadingZeros={false}
            prefix="$"
            disabled={isLoading}
            sx={{
              "& .MuiInputBase-root": {
                width: "200px",
              },
              "& .MuiInputBase-input": {
                fontSize: "14px",
                width: "200px",
                transition: "width 0.2s ease-in-out",
              },
            }}
            InputProps={{
              endAdornment: renderEndAdornment(),
            }}
          />
        </Box>
      ) : (
        <div style={styles.amountRow}>
          <p style={styles.revert}>
            <NumericFormat
              value={amount}
              displayType={"text"}
              thousandSeparator={"."}
              decimalSeparator={","}
              prefix={"$"}
              decimalScale={2}
              sx={{
                "& .MuiInputBase-input": {
                  fontSize: "14px",
                },
              }}
            />
          </p>
        </div>
      )}
    </>
  );
};
