export const styles = {
  modal: {
    zIndex: 9999,
  },
  modalBody: {
    padding: "24px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "16px",
    boxShadow: "0px 8px 16px 0px rgba(0, 0, 0, 0.2)",
    borderRadius: "8px",
  },
  modalTitle: {
    color: "#1D2939",
    fontSize: "18px",
    fontWeight: "600",
    textAlign: "center",
    marginBottom: "8px",
  },
  modalText: {
    color: "#667085",
    fontSize: "14px",
    textAlign: "center",
    marginBottom: "16px",
  },
};
