import { DataGrid } from "@mui/x-data-grid";
import React, { useContext } from "react";
import { columns } from "./columns";
import { INITIAL_ITEM_VALUES } from "../../constants";
import { CausationContext } from "contexts";

export const InputsSelect = ({
  products,
  taxesIva,
  taxesRete,
  getSearchOptionsProducts,
  handleChangeMultipleSelect,
  numberItemSelected = 0,
  hasCompanyWareHouse,
  costCenter,
}) => {
  const { erpName, isTotalizeItemsEnabled } = useContext(CausationContext);

  return (
    <div
      style={{
        marginBottom: "8px",
        backgroundColor: "#F9FAFB",
        borderRadius: "8px",
        padding: "12px",
        border: "1px solid #BDB4FE",
        height: "112px",
        display: "flex",
        gap: "8px",
        flexDirection: "column",
      }}
    >
      <p
        style={{
          fontSize: "14px",
          fontWeight: 500,
          lineHeight: "20px",
          color: "#6938EF",
          marginBottom: "6px",
        }}
      >
        {`Asignarle a la selección (${numberItemSelected})`}
      </p>
      <DataGrid
        id={"select-multiple"}
        hideHeader
        columns={columns({
          products,
          taxesIva,
          taxesRete,
          getSearchOptionsProducts,
          handleChangeMultipleSelect,
          hasCompanyWareHouse,
          costCenter,
          erpName,
          isTotalizeItemsEnabled,
        })}
        rows={[{ ...INITIAL_ITEM_VALUES }]}
        hideFooter={true}
        sx={{
          "& .cell-hidden": {
            visibility: "hidden",
          },
          "& .MuiDataGrid-root .MuiDataGrid-main": {
            border: "none",
          },
          "& .MuiDataGrid-row": {
            maxHeight: "40px !important",
            minHeight: "40px !important",
          },
          "& .MuiDataGrid-cell": {
            maxHeight: "40px !important",
            minHeight: "40px !important",
            backgroundColor: "#ffffff",
          },
          "& .MuiDataGrid-columnHeaders": {
            display: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            marginTop: "0px !important",
          },
          "& .MuiDataGrid-cell:before, & .MuiDataGrid-cell:after": {
            content: "none",
          },
          "& .MuiDataGrid-cell:hover": {
            background: "transparent",
            cursor: "default",
          },
          "& .MuiDataGrid-row.Mui-selected": {
            background: "transparent",
          },
          "& .MuiDataGrid-row.Mui-selected:hover": {
            background: "transparent",
          },
          "& .MuiDataGrid-row:hover": {
            backgroundColor: "inherit",
          },
          "& .MuiDataGrid-row.Mui-hovered": {
            backgroundColor: "inherit",
          },
        }}
        style={{
          border: "none",
        }}
      />
    </div>
  );
};
