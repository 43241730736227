import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { styles } from "./styles";
import { StarsIcon, ArrowRightWithoutStick } from "assets";
import { OptionGoTo } from "./components/OptionGoTo";
import { useNavigate } from "react-router-dom";

export function Onboarding() {
  const navigate = useNavigate();

  const options = [
    {
      label: "Pago a proveedores",
      action: () => navigate("/payments/invoices"),
    },
    {
      label: "Pago a nómina de empleados",
      action: () => navigate("/payroll/salaries"),
    },
    { label: "Cobros", action: () => navigate("/collections/index") },
    {
      label: "Causación en Siigo Nube",
      action: () => navigate("/payments/causation"),
    },
  ];

  return (
    <Box sx={styles.emptyStateBox}>
      <Stack width={460}>
        <Stack spacing={2} p={4} sx={styles.emptyStateStack}>
          <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
            <StarsIcon />
          </Box>
          <Typography sx={styles.emptyStateTitle}>
            ¡Te damos la bienvenida a Payana!
          </Typography>
          <Typography sx={styles.emptyStateSubtitle}>
            Elige cómo quieres comenzar
          </Typography>
        </Stack>
        <Stack gap={"16px"}>
          {options.map((option) => (
            <OptionGoTo
              action={option.action}
              icon={<ArrowRightWithoutStick />}
              buttonLabel={option.label}
              styles={{
                height: "52px",
                padding: "14px 16px 14px 16px",
                gap: "4px",
                borderRadius: "8px",
                border: "0px 0px 1px 0px",
                opacity: "0px",
                background: "#FFFFFF",
                textTransform: "none",
                justifyContent: "space-between",
              }}
            />
          ))}
        </Stack>
      </Stack>
    </Box>
  );
}
