import React, { useState, useEffect, useContext } from "react";
import { Box, Typography, TextField, Select, MenuItem } from "@mui/material";
import { CloseButton, Modal, Spinner } from "react-bootstrap";
import { styles } from "./styles";
import { UpdateUser, storeNewUser } from "../../../services";
import { UserContext } from "../../../contexts";
import { PrimaryButton, SecondaryButton } from "../../buttons";
import { ValidationMessage, getValidationMessage } from "../../components";
import { getEmailError, getPasswordError } from "../../forms/validation";
import { CreateUserIcon, EditUserIcon } from "../../../assets";
import { RoleChecklist } from "./components";
import { userRoles } from "constants";
import { EmailInput, PhoneNumberInput } from "commons/inputs";
import { PHONE_NUMBER_VALIDATOR } from "utils";
import { useForm, useWatch } from "react-hook-form";

export const UsersModal = ({
  person,
  visible,
  handleClose,
  notificationHandler,
  toastOptions,
  action,
}) => {
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const { register, handleSubmit, formState, control, setError, setValue } =
    useForm({
      defaultValues: {
        name: "",
        email: "",
        phone: "",
        role_name: "",
        role_id: "",
        password: "",
      },
      mode: "onBlur",
    });
  const formValues = useWatch({ control });
  const [phone, setPhone] = useState(formValues.phone);

  const { getOtherUsers } = useContext(UserContext);

  const cleanFormValues = () => {
    setValue("name", "");
    setValue("email", "");
    setValue("phone", "");
    setValue("role_name", "");
    setValue("role_id", "");
    setValue("password", "");
  };

  useEffect(() => {
    if (visible && person) {
      setValue("name", person.name);
      setValue("email", person.email);
      setValue("phone", person.phone);
      setValue("role_name", person.role_name);
      setValue("role_id", person.role_id);
      setValue("password", "");
    } else if (visible && !person) {
      cleanFormValues();
    }
  }, [visible, person]);

  const closeAndReset = () => {
    cleanFormValues();
    setErrors({});
    handleClose();
  };

  const updateUserHandler = async () => {
    const response = await UpdateUser({
      id: person.id,
      formValues: formValues,
    });
    closeAndReset();
    if (response.message) {
      notificationHandler.success(
        "¡El usuario se actualizó con éxito!",
        toastOptions
      );
    } else notificationHandler.error("Hubo un error.", toastOptions);
    await getOtherUsers();
  };

  const createUserHandler = async () => {
    setLoading(true);
    const errors = [];
    if (formValues.password.length < 8) {
      errors.push({
        field: "password",
        message: "La contraseña debe tener al menos 8 caracteres.",
      });
      setLoading(false);
      setErrors(
        Object.fromEntries(errors.map((error) => [error.field, error]))
      );
      return;
    }

    const emailError = getEmailError(formValues.email);

    if (emailError) {
      errors.push({ field: "email", message: emailError });
    }
    if (errors.length > 0) {
      setLoading(false);
      return setErrors(
        Object.fromEntries(errors.map((error) => [error.field, error]))
      );
    }

    const response = await storeNewUser(formValues);
    const beErrors = response?.response?.data?.errors || [];

    if (beErrors.length === 0) {
      notificationHandler.success(
        "¡El usuario se creó con éxito!",
        toastOptions
      );
      await getOtherUsers();
      closeAndReset();
      setLoading(false);
    } else {
      setLoading(false);
      notificationHandler.error(
        "Hubo un error, por favor intente nuevamente.",
        toastOptions
      );
    }
  };

  return (
    <Modal show={visible} onHide={closeAndReset} centered>
      <Modal.Body>
        <CloseButton
          style={{ position: "absolute", right: "16px" }}
          onClick={closeAndReset}
        />
        <Box sx={styles.container}>
          <form>
            {action === "edit" ? <EditUserIcon /> : <CreateUserIcon />}
            <p style={styles.title}>
              {action === "edit" ? "Editar usuario" : "Crear nuevo usuario"}
            </p>
            <p style={styles.subtitle}>
              {action === "edit"
                ? "Puedes editar este usuario y cambiar el rol que tiene"
                : `Puedes agregar usuarios para que puedan colaborar ayudándote a pagar o a verificar tus transacciones`}
            </p>
            <Box sx={styles.form}>
              <Typography style={styles.label} noWrap>
                Nombre completo
              </Typography>
              <TextField
                sx={styles.input}
                size="small"
                variant="outlined"
                value={formValues.name}
                required={true}
                placeholder="Ejemplo: José Pérez"
                InputProps={{
                  style: { fontSize: 14 },
                }}
                {...register("name", { required: true })}
              />
              <ValidationMessage errors={formState.errors} field="name" />
              <Typography style={styles.label} noWrap>
                Email
              </Typography>
              <EmailInput
                value={formValues.email}
                setValue={(value) => {
                  setValue("email", value, { shouldValidate: true });
                }}
                register={register}
              />
              <ValidationMessage errors={formState.errors} field="email" />
              <Box sx={styles.form}>
                <Typography style={styles.label} noWrap>
                  Número de teléfono
                </Typography>
                <PhoneNumberInput
                  phone={phone}
                  setPhone={(value) => {
                    setValue("phone", value, { shouldValidate: false });
                    setPhone(value);
                  }}
                  {...register("phone", PHONE_NUMBER_VALIDATOR(true))}
                />
                <ValidationMessage errors={formState.errors} field="phone" />
              </Box>
              {action === "create" && (
                <>
                  <Typography style={styles.label} noWrap>
                    Contraseña
                  </Typography>
                  <TextField
                    sx={styles.input}
                    size="small"
                    type="password"
                    variant="outlined"
                    placeholder="••••••••"
                    required={true}
                    onChange={(e) => {
                      const error = getPasswordError(e.target.value);
                      if (error) {
                        setErrors((errors) => ({
                          ...errors,
                          password: {
                            message: error,
                          },
                        }));
                      } else {
                        setErrors(({ password, ...errors }) => errors);
                        setValue("password", e.target.value);
                      }
                    }}
                    value={formValues.password}
                  />
                  <ValidationMessage errors={errors} field="password" />
                </>
              )}
              <Typography style={styles.label} noWrap>
                ¿Qué rol le quieres asignar a este usuario?
              </Typography>
              <Select
                onChange={(e) => {
                  setValue("role_name", e.target.value);
                  setValue(
                    "role_id",
                    userRoles.find(({ value }) => value === e.target.value)?.id
                  );
                }}
                style={!formValues.role_name ? styles.emptyInput : styles.input}
                size="small"
                value={formValues.role_name || ""}
                required={true}
                displayEmpty
              >
                <MenuItem disabled style={{ fontSize: "14px" }} value="">
                  Elige el rol del usuario
                </MenuItem>
                {userRoles.map((role) => {
                  return (
                    <MenuItem
                      key={role.label}
                      style={{ fontSize: "14px" }}
                      value={role.value}
                    >
                      {role.label}
                    </MenuItem>
                  );
                })}
              </Select>
              <ValidationMessage errors={errors} field="role" />
              {formValues.role_name && (
                <RoleChecklist
                  role={formValues.role_name}
                  label={
                    userRoles.find(
                      ({ value }) => value === formValues.role_name
                    )?.label
                  }
                />
              )}
            </Box>
            <div style={styles.row}>
              <SecondaryButton
                width="100%"
                text="Cancelar"
                action={closeAndReset}
              />
              {action === "edit" ? (
                <PrimaryButton
                  width="100%"
                  isDisable={
                    formValues.name === "" ||
                    formValues.lastname === "" ||
                    formValues.email === "" ||
                    formValues.phone === "" ||
                    formValues.role_name === ""
                  }
                  text="Guardar cambios"
                  action={handleSubmit(updateUserHandler)}
                />
              ) : (
                <PrimaryButton
                  width="100%"
                  isDisable={
                    loading ||
                    Object.values(errors).length ||
                    formValues.name === "" ||
                    formValues.lastname === "" ||
                    formValues.email === "" ||
                    formValues.phone === "" ||
                    formValues.role_name === ""
                  }
                  action={handleSubmit(createUserHandler)}
                  text={
                    loading ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      "Crear usuario"
                    )
                  }
                />
              )}
            </div>
          </form>
        </Box>
      </Modal.Body>
    </Modal>
  );
};
