import React, { useContext, useEffect, useState } from "react";
import { TrackJS } from "trackjs";
import { NumericFormat } from "react-number-format";
import { CloseButton, Spinner } from "react-bootstrap";
import {
  Stack,
  TextField,
  Typography,
  Autocomplete,
  Box,
  Dialog,
  Divider,
} from "@mui/material";
import { PaymentsContext, UserContext } from "../../../contexts";
import { styles } from "../../Table/styles";
import { SecondaryButton, WithoutBorderButton } from "../../buttons";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { es } from "date-fns/locale";
import FileUpload from "../ExpandInvoiceModal/components/FileUpload";
import { PrimaryButton } from "../../buttons";
import { CustomTextField } from "./components";
import {
  GetSupplier,
  SearchSuppliers,
  GetService,
  showService,
} from "../../../services";
import {
  BLOCKED_MESSAGE,
  getAccountTypeLabel,
  minimumLengthForSearch,
  toastOptions,
} from "../../../constants";
import { toast } from "react-toastify";
import { objectIsEmpty } from "../../../utils/objectUtils";
import { useMediaQuery } from "react-responsive";
import TagsSelector from "../../components/TagsSelector";
import { RequiredLabel } from "../../components";
import { AttentionModal } from "../AttentionModal";
import { useNavigate } from "react-router-dom";
import { CalendarIcon, CrownIcon } from "assets";
import CheckoutModal from "../CheckoutModal";

const getSupplierFormState = (supplier = {}) => ({
  provider_id: "",
  service_id: "",
  invoice_number: "",
  issue_date: null,
  expiration_date: null,
  amount: "",
  type: "invoice",
  concept: "",
  file_path: "",
  ...supplier,
});

export const PaymentObligationModal = ({
  visible,
  handleClose,
  createdSupplier,
  createdService,
  defaultInvoiceNumber,
  setDrawerIsOpen,
  options,
  setTags,
  type,
  setLimitExceededAlert = () => {},
}) => {
  const [style, setStyle] = useState({});
  const [searchOptions, setSearchOptions] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState({});
  const [selectedService, setSelectedService] = useState({});
  const [createRequestInProcess, setCreateRequestInProcess] = useState(false);
  const [isCheckoutModalVisible, setIsCheckoutModalVisible] = useState(false);
  const [openBlockedModal, setOpenBlockedModal] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [invoiceId, setInvoiceId] = useState([]);
  const [tagsSelected, setTagsSelected] = useState([]);
  const { subscription, suppliers, createInvoice, uploadInvoiceFile } =
    useContext(PaymentsContext);
  const {
    sourceOfFundsNeeded,
    canPayWithCobre,
    blockedCompany,
    currentUserIsAdmin,
    approvationNeeded,
  } = useContext(UserContext);

  const [formValues, setFormValues] = useState(
    getSupplierFormState({ invoice_number: defaultInvoiceNumber })
  );
  const [selectedFiles, setSelectedFiles] = useState(undefined);
  const [invoiceNumber, setInvoiceNumber] = useState(defaultInvoiceNumber);
  const [collectionNumberError, setCollectionNumberError] = useState({
    helperText: "",
    error: false,
  });
  const [conceptError, setConceptError] = useState({
    helperText: "",
    error: false,
  });
  const isMobile = useMediaQuery({ query: "(max-width: 481px)" });
  const navigate = useNavigate();
  const isCollectionAccount = type === "collection_account";

  useEffect(() => {
    if (visible) {
      currentUserIsAdmin && suppliers?.length === 0 && setDrawerIsOpen(true);
      setSelectedSupplier({});
      setInvoiceNumber(defaultInvoiceNumber);
    }
  }, [visible]);

  useEffect(() => {
    setStyle({ padding: "0px", zIndex: 1000 });
  }, []);

  useEffect(() => {
    setFormValues((data) => {
      return {
        ...data,
        provider_id: createdSupplier?.id,
        type: type,
      };
    });
    setInvoiceNumber(defaultInvoiceNumber);
    if (formValues.provider_id) {
      GetSupplier({ id: formValues.provider_id }).then((res) => {
        setSelectedSupplier(res);
      });
    }
  }, [createdSupplier]);

  useEffect(() => {
    setFormValues((data) => {
      return {
        ...data,
        service_id: createdService?.id,
        invoice_number: "",
        type: "service",
      };
    });
    setInvoiceNumber("");
    if (formValues.service_id) {
      GetService({ id: formValues.service_id }).then((res) => {
        setSelectedService(res);
      });
    }
  }, [createdService]);

  const saveAndRedirect = async (payNow = false) => {
    if (createRequestInProcess) {
      return;
    }
    setCreateRequestInProcess(true);
    const uploadedFile = await uploadInvoiceFile(selectedFiles);
    const date = new Date();
    await createInvoice({
      ...formValues,
      file_path: uploadedFile?.location,
      issue_date: date,
      tags: tagsSelected.map((tag) => tag.id),
      invoice_number: invoiceNumber,
      ...(isCollectionAccount && { type: "collection_account" }),
    })
      .then((res) => {
        if (res?.response?.status === 500) {
          throw res;
        }
        if (payNow) {
          if (isCollectionAccount) {
            navigate(`/payments/causation/${res.id}`, {
              replace: true,
              state: {
                createCollectionAccount: true,
                invoiceNumber: invoiceNumber,
                amount: formValues.amount,
                issueDate: date,
              },
            });
          } else {
            setInvoiceId([res.id]);
            setTotalAmount(res.amount_total);
            setIsCheckoutModalVisible(true);
          }
        }
        handleClose(!isCollectionAccount);
        setFormValues(getSupplierFormState());
        setSelectedSupplier({});
        setSelectedService({});
        setTagsSelected([]);
        setSelectedFiles(null);
      })
      .catch((err) => {
        toast.error(
          "Hubo un error creando el pago, por favor intente de nuevo.",
          toastOptions
        );
        TrackJS.track(err);
      })
      .finally(() => {
        setCreateRequestInProcess(false);
      });
  };

  useEffect(() => {
    setSelectedService({});
    createdSupplier && setSelectedSupplier(createdSupplier);
  }, [createdSupplier]);

  useEffect(() => {
    setSelectedSupplier({});
    createdService && setSelectedService(createdService);
  }, [createdService]);

  useEffect(() => {
    if (formValues.provider_id) {
      GetSupplier({ id: formValues.provider_id }).then((res) => {
        setSelectedSupplier(res);
      });
    }
  }, [formValues.provider_id]);

  useEffect(() => {
    if (formValues.service_id) {
      GetService({ id: formValues.service_id }).then((res) => {
        setSelectedService(res);
      });
    }
  }, [formValues.service_id]);

  const getSearchOptions = (query) => {
    SearchSuppliers({ query: query, withAnayap: false }).then((res) => {
      showService(query).then((resService) => {
        setSearchOptions([...res, ...resService]);
      });
    });
  };

  const handleOnChangeAutocomplete = (event, newValue) => {
    if (newValue?.alias || newValue?.id) {
      if (newValue.alias) {
        setFormValues({
          ...formValues,
          service_id: newValue.id,
          provider_id: "",
          type: "service",
          invoice_number: "",
        });
        setInvoiceNumber("");
      } else {
        setFormValues({
          ...formValues,
          provider_id: newValue.id,
          service_id: "",
          invoice_number: "",
          type: type,
        });
        setInvoiceNumber(defaultInvoiceNumber);
      }
    } else {
      setFormValues({
        ...formValues,
        service_id: "",
        provider_id: "",
        type: type,
      });
    }
  };

  const supplierIsIncomplete = () => {
    return (
      !selectedSupplier.name ||
      !selectedSupplier.document_type ||
      !selectedSupplier.document_number ||
      !selectedSupplier.bank_name ||
      (!selectedSupplier.account_type &&
        selectedSupplier.bank_name.toLowerCase() !== "nequi" &&
        selectedSupplier.bank_name.toLowerCase() !== "daviplata") ||
      !selectedSupplier.account_number
    );
  };

  const onTagSelect = (tags) => {
    setTagsSelected(tags);
  };

  const onTagRemove = (tags) => {
    setTagsSelected(tags);
  };

  return (
    <>
      <CheckoutModal
        visible={isCheckoutModalVisible}
        totalAmount={totalAmount}
        handleClose={() => setIsCheckoutModalVisible(false)}
        canPayWithCobre={canPayWithCobre}
        setLimitExceededAlert={setLimitExceededAlert}
        payoutsNumber={invoiceId.length}
        selectedRowsIds={invoiceId}
        sourceOfFundsNeeded={sourceOfFundsNeeded}
      />
      <AttentionModal
        title={BLOCKED_MESSAGE}
        onClose={() => setOpenBlockedModal(false)}
        open={openBlockedModal}
      />
      <Dialog fullScreen open={visible} style={style} onClose={handleClose}>
        <div className="modal-header-payana">
          <div style={styles.headerRow}>
            <CloseButton
              style={styles.nonShadow}
              onClick={() => {
                handleClose();
                setFormValues(getSupplierFormState());
                setSelectedSupplier({});
                setSelectedService({});
                setTagsSelected([]);
                setSelectedFiles(null);
              }}
            />
            <div className="modalTitle" style={styles.title}>
              {isCollectionAccount ? "Nuevo documento" : "Nuevo pago manual"}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              justifyContent: "center",
              alignItems: "center",
              gap: "2px",
            }}
          >
            <SecondaryButton
              text={
                createRequestInProcess ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  "Guardar"
                )
              }
              action={() => saveAndRedirect(false)}
              isDisable={
                createRequestInProcess ||
                !formValues.amount ||
                (!formValues.provider_id && !formValues.service_id)
              }
              width={isMobile ? "113px" : "86px"}
            />
            {isCollectionAccount ? (
              <PrimaryButton
                text={
                  createRequestInProcess ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    "Ir a causar"
                  )
                }
                action={() => {
                  if (blockedCompany) {
                    setOpenBlockedModal(true);
                  } else {
                    saveAndRedirect(true);
                  }
                }}
                isDisable={createRequestInProcess || !formValues.amount}
                style={{ marginLeft: isMobile ? "0px" : "16px" }}
                width="100%"
              />
            ) : (
              (currentUserIsAdmin || !approvationNeeded) && (
                <PrimaryButton
                  icon={
                    subscription?.showIcon ? (
                      <CrownIcon size="small" fill="#EFA83C" />
                    ) : null
                  }
                  text={
                    createRequestInProcess ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      "Pagar ahora"
                    )
                  }
                  action={() => {
                    if (blockedCompany) {
                      setOpenBlockedModal(true);
                    } else {
                      subscription.handleActionSubscription(() =>
                        saveAndRedirect(true)
                      );
                    }
                  }}
                  isDisable={
                    createRequestInProcess ||
                    !formValues.amount ||
                    (!formValues.provider_id && !formValues.service_id) ||
                    (!formValues.service_id && supplierIsIncomplete())
                  }
                  style={{ marginLeft: isMobile ? "0px" : "16px" }}
                  width="100%"
                />
              )
            )}
          </div>
        </div>
        <Box
          sx={{
            minWidth: isMobile ? 380 : 544,
            maxWidth: isMobile ? 380 : 544,
            margin: "0 auto",
            paddingTop: 2,
            paddingLeft: 2,
            paddingRight: 2,
          }}
        >
          <>
            <Stack spacing={1} id="new_payment_supplier_field">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "-8px",
                }}
              >
                <RequiredLabel
                  label="Proveedor/Servicio"
                  sx={styles.invoiceCardTitleRevert}
                />
                <WithoutBorderButton
                  text="Crear nuevo"
                  action={() => setDrawerIsOpen(true)}
                  width="90px"
                />
              </div>
              <Autocomplete
                options={searchOptions}
                freeSolo
                getOptionLabel={(option) => option?.name || option?.alias || ""}
                value={
                  objectIsEmpty(selectedSupplier)
                    ? selectedService
                    : selectedSupplier
                }
                onChange={handleOnChangeAutocomplete}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { fontSize: 14 },
                    }}
                    placeholder={"Ingresa el nombre"}
                  />
                )}
                size="small"
                onInputChange={(event) => {
                  if (event?.target?.value?.length >= minimumLengthForSearch) {
                    getSearchOptions(event?.target?.value);
                  }
                }}
              />
              {formValues.provider_id && !objectIsEmpty(selectedSupplier) && (
                <div style={styles.supplierDataCardContainer}>
                  <div style={styles.supplierDataCard}>
                    <p
                      style={styles.invoiceSupplierCardTitle}
                      className="card-subtitle mb-2"
                    >
                      Entidad bancaria
                    </p>
                    <p
                      style={styles.invoiceSupplierCardText}
                      className="card-subtitle mb-2"
                    >
                      {selectedSupplier.bank_name}
                    </p>
                  </div>
                  <Divider sx={{ marginBottom: "8px" }} />
                  <div style={styles.supplierDataCard}>
                    <p
                      style={styles.invoiceSupplierCardTitle}
                      className="card-subtitle mb-2"
                    >
                      {selectedSupplier.account_type
                        ? getAccountTypeLabel(selectedSupplier.account_type)
                        : "Número de celular"}
                    </p>
                    <p
                      style={styles.invoiceSupplierCardText}
                      className="card-subtitle mb-2"
                    >
                      {selectedSupplier.account_number}
                    </p>
                  </div>
                  <Divider sx={{ marginBottom: "8px" }} />
                  <div style={styles.supplierDataCard}>
                    <p
                      style={styles.invoiceSupplierCardTitle}
                      className="card-subtitle mb-2"
                    >
                      {selectedSupplier?.document_type
                        ? selectedSupplier.document_type
                        : "NIT"}
                    </p>
                    <p
                      style={styles.invoiceSupplierCardText}
                      className="card-subtitle mb-2"
                    >
                      {selectedSupplier.document_number}
                    </p>
                  </div>
                </div>
              )}
              {formValues.service_id && !objectIsEmpty(selectedService) && (
                <div style={styles.supplierDataCardContainer}>
                  <div style={styles.supplierDataCard}>
                    <p
                      style={styles.invoiceSupplierCardTitle}
                      className="card-subtitle mb-2"
                    >
                      Información de pago
                    </p>
                    <p
                      style={styles.invoiceSupplierCardText}
                      className="card-subtitle mb-2"
                    >
                      {selectedService.referral_code}
                    </p>
                  </div>
                </div>
              )}
            </Stack>
            <div style={styles.invoiceRow}>
              <div style={styles.invoiceColumn}>
                <Stack spacing={1} id="new_payment_amount_field">
                  <RequiredLabel label="Monto" sx={styles.invoiceCardTitle} />
                  <NumericFormat
                    id="amount"
                    name="amount"
                    thousandSeparator="."
                    decimalSeparator=","
                    allowNegative={false}
                    isNumericString={true}
                    value={formValues.amount}
                    customInput={CustomTextField}
                    onValueChange={(values, sourceInfo) => {
                      setFormValues({
                        ...formValues,
                        amount: values.floatValue,
                      });
                    }}
                  />
                </Stack>
              </div>
            </div>
            <div style={styles.invoiceRow}>
              <div style={styles.invoiceColumn}>
                <Stack spacing={1} sx={{ marginRight: "12px" }}>
                  <Typography sx={styles.invoiceCardTitle}>
                    Número de comprobante
                  </Typography>
                  <TextField
                    size="small"
                    variant="outlined"
                    placeholder="Número de comprobante"
                    InputProps={{
                      style: { fontSize: 14 },
                    }}
                    onChange={(e) => {
                      setFormValues({
                        ...formValues,
                        invoice_number: e.target.value,
                      });
                      setInvoiceNumber(e.target.value);
                      if (e?.target?.value?.length === 0) {
                        setCollectionNumberError({
                          helperText: "Este campo no puede estar vacío",
                          error: true,
                        });
                      } else {
                        if (!e.target.value.match(/^[A-Za-z0-9_-]+$/)) {
                          setCollectionNumberError({
                            helperText: "El formato del campo no es válido",
                            error: true,
                          });
                        } else {
                          setCollectionNumberError({
                            helperText: "",
                            error: false,
                          });
                        }
                      }
                    }}
                    defaultValue={invoiceNumber}
                    value={invoiceNumber}
                    error={collectionNumberError.error}
                  />
                  {collectionNumberError.error && (
                    <Typography sx={styles.inputError} noWrap>
                      {collectionNumberError.helperText}
                    </Typography>
                  )}
                </Stack>
              </div>
              <div style={styles.invoiceColumn}>
                <Stack spacing={1}>
                  <Typography sx={styles.invoiceCardTitle}>
                    Fecha de vencimiento
                  </Typography>
                  <LocalizationProvider
                    adapterLocale={es}
                    dateAdapter={AdapterDateFns}
                  >
                    <DatePicker
                      value={formValues.expiration_date}
                      onChange={(value) => {
                        setFormValues({
                          ...formValues,
                          expiration_date: value,
                        });
                      }}
                      slotProps={{
                        textField: {
                          InputProps: {
                            style: { fontSize: 14 },
                          },
                          size: "small",
                          error: false,
                        },
                      }}
                      format="dd/MM/yyyy"
                      slots={{
                        openPickerIcon: () => <CalendarIcon stroke="#667085" />,
                      }}
                    />
                  </LocalizationProvider>
                </Stack>
              </div>
            </div>
            <div style={styles.invoiceRow}>
              <div style={styles.invoiceColumn}>
                <Stack spacing={1}>
                  <Typography sx={styles.invoiceCardTitle}>Concepto</Typography>
                  <TextField
                    size="small"
                    variant="outlined"
                    placeholder="Ej: Anticipo por reparación maquinaria"
                    InputProps={{
                      style: { fontSize: 14 },
                    }}
                    onChange={(e) => {
                      setFormValues({
                        ...formValues,
                        concept: e.target.value,
                      });
                      if (e?.target?.value?.length === 0) {
                        setConceptError({
                          helperText: "",
                          error: false,
                        });
                      } else {
                        if (!e.target.value.match(/^[\p{L}\p{N}\p{Zs}]+$/gmu)) {
                          setConceptError({
                            helperText: "El formato del campo no es válido",
                            error: true,
                          });
                        } else {
                          setConceptError({
                            helperText: "",
                            error: false,
                          });
                        }
                      }
                    }}
                    error={conceptError.error}
                  />
                  {conceptError.error && (
                    <Typography sx={styles.inputError} noWrap>
                      {conceptError.helperText}
                    </Typography>
                  )}
                </Stack>
              </div>
            </div>
            <div style={styles.invoiceRow}>
              <div style={styles.invoiceColumn}>
                <Stack spacing={1}>
                  <Typography sx={styles.invoiceCardTitle}>
                    Etiquetado
                  </Typography>
                  <TagsSelector
                    options={options}
                    onSelect={onTagSelect}
                    onRemove={onTagRemove}
                    searchBoxStyle={{ borderRadius: "8px" }}
                    inputFieldStyle={{
                      fontSize: "14px",
                      marginLeft: "6px",
                      marginBottom: "2px",
                      color: "black",
                    }}
                    setTags={setTags}
                    setTagsSelected={setTagsSelected}
                    selectedValues={tagsSelected}
                    className={"checkbox"}
                  />
                </Stack>
              </div>
            </div>
            {!formValues.service_id && (
              <div style={styles.invoiceRow}>
                <div style={styles.invoiceColumn}>
                  <Stack spacing={1}>
                    <FileUpload
                      selectedFiles={selectedFiles}
                      setSelectedFiles={setSelectedFiles}
                      isInvoiceFile={true}
                    />
                  </Stack>
                </div>
              </div>
            )}
          </>
        </Box>
      </Dialog>
    </>
  );
};
