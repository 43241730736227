import React, { useContext, useRef } from "react";
import { expandCausationColumns } from "../../../../utils";
import { DataGrid, GridCellModes } from "@mui/x-data-grid";
import { styles } from "../styles";
import { MultipleSelect } from "./MultipleSelected";
import { MultipleSelectedIntro } from "./MultipleSelected/MultipleSelectedIntro";
import { CausationContext } from "contexts";
import { Button } from "@mui/material";
import { PlusCircleIcon } from "assets";
import { ItemTotalizer } from "./ItemTotalizer";

export function TableCausation({
  data,
  addNewTableInfo,
  handleOnChangeItem,
  handleDeleteItem,
  getSearchOptionsProducts,
  isCaused,
  selectItem,
  handleChangeMultipleSelect,
  handleSelectAllItems,
  activateOverlade,
  hasCompanyWareHouse,
  search,
  getSearchOptionsSupplier,
  handleSetItems,
}) {
  const [cellModesModel, setCellModesModel] = React.useState({});

  const labelDisplayedRows = ({ from, to, count, estimated }) => {
    if (!estimated) {
      return `${from}–${to} de ${count !== -1 ? count : `${to}`}`;
    }
    return `${from}–${to} de ${
      count !== -1 ? count : `${estimated > to ? estimated : to}`
    }`;
  };

  const handleCellClick = React.useCallback((params, event) => {
    if (!params.isEditable) {
      return;
    }

    if (
      event.target.nodeType === 1 &&
      !event.currentTarget.contains(event.target)
    ) {
      return;
    }

    setCellModesModel((prevModel) => {
      return {
        ...Object.keys(prevModel).reduce(
          (acc, id) => ({
            ...acc,
            [id]: Object.keys(prevModel[id]).reduce(
              (acc2, field) => ({
                ...acc2,
                [field]: { mode: GridCellModes.View },
              }),
              {}
            ),
          }),
          {}
        ),
        [params.id]: {
          ...Object.keys(prevModel[params.id] || {}).reduce(
            (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
            {}
          ),
          [params.field]: { mode: GridCellModes.Edit },
        },
      };
    });
  }, []);

  const handleCellModesModelChange = React.useCallback((newModel) => {
    setCellModesModel(newModel);
  }, []);

  const numberItemSelected = data?.filter((item) => item.isSelected)?.length;

  const idCellRef = useRef(null);

  const { hasErpCausation, erpName, isTotalizeItemsEnabled } =
    useContext(CausationContext);
  const {
    product: products,
    taxIva: taxesIva,
    taxRetefuente: taxesRete,
    costCenter,
  } = search;

  return (
    <>
      {isTotalizeItemsEnabled && (
        <ItemTotalizer handleSetItems={handleSetItems} data={data} />
      )}
      {!activateOverlade && (
        <MultipleSelectedIntro
          idCellRef={idCellRef}
          activateOverlade={activateOverlade}
        />
      )}
      <div
        style={{
          ...styles.tableContainer,
          marginBottom: isCaused ? "24px" : "0px",
        }}
      >
        <MultipleSelect
          numberItemSelected={numberItemSelected}
          products={products}
          taxesIva={taxesIva}
          taxesRete={taxesRete}
          getSearchOptionsProducts={getSearchOptionsProducts}
          handleChangeMultipleSelect={handleChangeMultipleSelect}
          hasCompanyWareHouse={hasCompanyWareHouse}
          costCenter={costCenter}
          isCaused={isCaused}
        />
        <DataGrid
          autoHeight
          columns={expandCausationColumns({
            searchProductsOptions: products,
            taxesIva,
            taxesRete,
            handleOnChangeItem,
            handleDeleteItem,
            getSearchOptionsProducts,
            isCaused,
            selectItem,
            idCellRef,
            handleSelectAllItems,
            hasCompanyWareHouse,
            hasErpCausation,
            erpName,
            costCenter,
            getSearchOptionsSupplier,
            isTotalizeItemsEnabled,
          })}
          rows={data}
          hideFooter={data.length <= 25}
          disableSelectionOnClick
          disableColumnMenu
          disableColumnReorder
          disableColumnSort
          getRowHeight={() => "auto"}
          cellModesModel={cellModesModel}
          onCellModesModelChange={handleCellModesModelChange}
          onCellClick={handleCellClick}
          initialState={{
            ...data.initialState,
            pagination: { paginationModel: { pageSize: 25 } },
          }}
          localeText={{
            MuiTablePagination: {
              labelRowsPerPage: "Mostrar",
              labelDisplayedRows,
            },
          }}
          hideFooterSelectedRowCount={true}
          pageSizeOptions={[25, 50, 100]}
          sx={{
            "& .MuiDataGrid-cell": {
              color: isCaused ? "rgba(0, 0, 0, 0.38)" : "#344054",
              fontSize: "14px",
              borderBottom: "1px solid #EAECF0 !important",
              borderLeft: "1px solid #EAECF0",
            },
            "& .MuiDataGrid-columnHeader": {
              fontSize: "12px",
              color: "#344054",
              fontWeight: 500,
              borderBottom: "1px solid #EAECF0",
            },
            "& .MuiDataGrid-row": {
              "&:hover": {
                backgroundColor: "#F9FAFB",
              },
              maxHeight: "40px !important",
            },
            "& .MuiDataGrid-columnHeaders": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeader:last-child": {
              border: "none",
            },
            ...(!isCaused && {
              "& .MuiDataGrid-cell:last-child": {
                borderLeft: "1px solid #EAECF0",
                borderTop: "none",
                borderBottom: "none !important",
              },
            }),
            ...(isCaused && {
              "& .MuiDataGrid-cell:last-child": {
                borderTop: "1px solid #EAECF0",
                borderRight: "1px solid #EAECF0",
              },
            }),
            border: "none",
          }}
        />
        {!isCaused && (
          <div style={styles.textContainerAddRow}>
            <Button
              onClick={addNewTableInfo}
              sx={{
                color: "#5925DC",
                textTransform: "none",
                "&:hover": { backgroundColor: "transparent" },
                marginTop: "8px",
                marginBottom: "16px",
                display: "flex",
                gap: "8px",
              }}
            >
              <PlusCircleIcon width="20" height="20" />
              Agregar ítem
            </Button>
          </div>
        )}
      </div>
    </>
  );
}
