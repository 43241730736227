import { IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import { EditIcon, NewIcon, WarningIcon } from "../../../assets";
import { styles } from "../styles";
import { permissions } from "constants";
import { useIsUserAllowedTo } from "utils";
import { Tooltip } from "commons/components";
import { PayanaIcon } from "assets/svg/PayanaIcon";

export const SupplierRow = ({
  cell,
  isIncomplete,
  linkTo,
  itemId,
  isNew,
  setDrawerIsOpen,
  className,
  showPayanaIcon,
}) => {
  const { isUserAllowedTo } = useIsUserAllowedTo();
  return (
    <div
      style={{
        ...styles.row,
        justifyContent: "flex-start",
        maxWidth: "300px",
      }}
    >
      <div
        title={cell.value}
        className={`limit-text incompleted-provider-indicator ${
          cell.value !== null ? className : ""
        }`}
        style={{
          color: isIncomplete ? "" : "#475467",
          margin: "revert",
          display: "flex",
          alignItems: "center",
          gap: "8px",
        }}
      >
        {showPayanaIcon && <PayanaIcon />}
        <div className="limit-text" style={{ maxWidth: "300px" }}>
          {cell.value !== null ? cell.value.toUpperCase() : "NO INFORMADO"}
        </div>
        {isUserAllowedTo(permissions.INVOICES_UPDATE) &&
          isIncomplete &&
          !cell.row.original.provider_id && (
            <Tooltip title="Carga la información de tu proveedor">
              <Link to={linkTo + itemId}>
                <IconButton>
                  <EditIcon />
                </IconButton>
              </Link>
            </Tooltip>
          )}
        {isNew && (
          <div style={{ marginLeft: "8px", marginRight: "8px" }}>
            <NewIcon />
          </div>
        )}
      </div>
      {isUserAllowedTo(permissions.INVOICES_UPDATE) &&
        isIncomplete &&
        cell.row.original.provider_id && (
          <Tooltip title="Completa la información de tu proveedor">
            <span
              onClick={(e) => {
                e.stopPropagation();
                setDrawerIsOpen(cell.row.original.provider_id);
              }}
              className="incompleted-provider-indicator"
              style={{
                textTransform: "none",
                display: "flex",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "8px",
                }}
              >
                <WarningIcon width="34" height="34" showRect={false} />
              </div>
            </span>
          </Tooltip>
        )}
    </div>
  );
};
