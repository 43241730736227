import { NumericFormat } from "react-number-format";
import stylesCSS from "commons/Table/styles.module.css";
import "./debt-list-item.css";
import { DebtListInnerItem } from "./DebtListInnerItem";
import { CompanyLogo } from "./CompanyLogo";
import { Collapse, Divider, IconButton, Skeleton } from "@mui/material";
import { DEBT_AMOUNT_TYPES } from "./constants";
import { useState } from "react";
import { ChevronDownIcon, ChevronUpIcon } from "assets";

const styles = {
  input: {
    width: "150px",
    padding: "8px 12px",
    border: "1px solid #D0D5DD",
    borderRadius: "8px",
    fontSize: "14px",
    color: "#344054",
    WebkitAppearance: "none",
    MozAppearance: "textfield",
    "&::WebkitOuterSpinButton, &::WebkitInnerSpinButton": {
      WebkitAppearance: "none",
      margin: 0,
    },
  },
};

const DebtListItemSkeleton = ({ serviceCompanyLogoUrl }) => (
  <button className="button-without-default-style">
    <div className="debt-list-item-container">
      <div className="debt-list-item-inner-container">
        <div style={{ display: "flex" }}>
          <input
            style={{ alignSelf: "center", marginRight: "8px" }}
            className={stylesCSS.input}
            type="checkbox"
            disabled
          />
          <CompanyLogo logoUrl={serviceCompanyLogoUrl} />
        </div>
        <div className="debt-list-item-inner-list">
          <Skeleton width={55} height={20} />
          <Skeleton width={115} height={20} />
          <Skeleton width={165} height={20} />
        </div>
      </div>
    </div>
  </button>
);

const DebtExpiration = ({ debt }) => {
  const { expirationDate } = debt;
  return expirationDate && <span> - Vence {expirationDate}</span>;
};

const Checkbox = ({ checked, disabled, onChange }) => {
  return (
    <input
      style={{ alignSelf: "flex-start", marginRight: "8px", marginTop: "17px" }}
      className={stylesCSS.input}
      type="checkbox"
      checked={checked}
      disabled={disabled}
      onChange={onChange}
    />
  );
};

const PriceFormat = ({ amount }) => {
  return (
    <NumericFormat
      value={amount}
      displayType="text"
      thousandSeparator="."
      decimalSeparator=","
      prefix="$"
      decimalScale={2}
    />
  );
};

export const DebtListItem = ({
  loading,
  debt,
  toggleSelectedRow,
  addSelectedRow,
  removeSelectedRow,
  isChecked,
  serviceCompanyName,
  serviceCompanyLogoUrl,
  identifierValue,
}) => {
  if (loading) {
    return (
      <DebtListItemSkeleton serviceCompanyLogoUrl={serviceCompanyLogoUrl} />
    );
  }
  return (
    <DebtListItemLoaded
      debt={debt}
      toggleSelectedRow={toggleSelectedRow}
      addSelectedRow={addSelectedRow}
      removeSelectedRow={removeSelectedRow}
      isChecked={isChecked}
      serviceCompanyName={serviceCompanyName}
      serviceCompanyLogoUrl={serviceCompanyLogoUrl}
      identifierValue={identifierValue}
    />
  );
};

const DebtListItemLoaded = ({
  debt,
  toggleSelectedRow,
  addSelectedRow,
  removeSelectedRow,
  isChecked,
  serviceCompanyName,
  serviceCompanyLogoUrl,
  identifierValue,
}) => {
  const amount = parseFloat(debt.amount);
  const isAmountOpen = debt.amountType === DEBT_AMOUNT_TYPES.OPEN;
  const isAmountClose = debt.amountType === DEBT_AMOUNT_TYPES.CLOSED;

  const [inputValue, setInputValue] = useState(amount);
  const [isInputVisible, setIsInputVisible] = useState(false);
  const isInputValueInRange =
    inputValue >= debt.minAmount && inputValue <= debt.maxAmount;

  const handleAmountChange = ({ floatValue, userIsChanging = false }) => {
    setInputValue(floatValue);
    if (!userIsChanging) {
      return;
    }
    const isInputValueInRange =
      floatValue >= debt.minAmount && floatValue <= debt.maxAmount;
    if (isInputValueInRange) {
      addSelectedRow({
        ...debt,
        amount: floatValue,
        originalAmount: debt.amount,
      });
    } else {
      removeSelectedRow(debt);
    }
  };

  const onCheckboxChange = () => {
    if (isAmountOpen && isInputValueInRange) {
      toggleSelectedRow({
        ...debt,
        amount: inputValue,
        originalAmount: debt.amount,
      });
    } else if (isAmountClose) {
      toggleSelectedRow(debt);
    }
  };

  return (
    <div className="debt-list-item-container">
      <div className="debt-list-item-inner-container">
        <div style={{ display: "flex" }}>
          <Checkbox
            checked={isChecked(debt)}
            disabled={isAmountOpen && !isInputValueInRange}
            onChange={() => onCheckboxChange()}
          />
          <CompanyLogo logoUrl={serviceCompanyLogoUrl} />
        </div>
        <div className="debt-list-item-inner-list">
          <DebtListInnerItem>{serviceCompanyName}</DebtListInnerItem>
          <DebtListInnerItem>
            <PriceFormat amount={amount} />
            <DebtExpiration debt={debt} />
          </DebtListInnerItem>
          <DebtListInnerItem>{identifierValue}</DebtListInnerItem>
          {isAmountOpen && (
            <>
              <Divider sx={{ margin: "6px 0px", width: "100%" }} />
              <DebtListInnerItem>
                <button
                  className="button-without-default-style hint"
                  onClick={() => {
                    setIsInputVisible(!isInputVisible);
                  }}
                >
                  Puedes modificar el valor a abonar.
                </button>
                <IconButton
                  onClick={() => {
                    setIsInputVisible(!isInputVisible);
                  }}
                >
                  {isInputVisible ? <ChevronUpIcon /> : <ChevronDownIcon />}
                </IconButton>
              </DebtListInnerItem>
              <Collapse in={isInputVisible}>
                <DebtListInnerItem>
                  <NumericFormat
                    defaultValue={amount}
                    value={inputValue}
                    type="text"
                    thousandSeparator="."
                    decimalSeparator=","
                    prefix="$"
                    decimalScale={2}
                    onValueChange={({ floatValue }) => {
                      handleAmountChange({
                        floatValue,
                        userIsChanging: true,
                      });
                    }}
                    onBlur={() => {
                      if (!isInputValueInRange) {
                        handleAmountChange({
                          floatValue: amount,
                          userIsChanging: true,
                        });
                      }
                    }}
                    style={{
                      ...styles.input,
                      borderColor:
                        inputValue && !isInputValueInRange
                          ? "#FDA29B"
                          : "#D0D5DD",
                    }}
                    className="no-spinners"
                  />
                </DebtListInnerItem>
                <span className="hint">
                  Mín: <PriceFormat amount={parseFloat(debt.minAmount)} /> -
                  Máx: <PriceFormat amount={parseFloat(debt.maxAmount)} />
                </span>
              </Collapse>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
