const PAYMENT_METHOD_CARD_KEY = "card";
const PAYMENT_METHOD_PSE_KEY = "others";
const PAYMENT_METHOD_BANKING_CORRESPONDENT_KEY = "banking_correspondent";

class PaymentMethod {
  constructor(name) {
    this.name = name;
  }
  isNamed(nameToCompare) {
    return nameToCompare === this.name;
  }
}

const PAYMENT_METHOD = {
  CreditCard: new PaymentMethod(PAYMENT_METHOD_CARD_KEY),
  PSE: new PaymentMethod(PAYMENT_METHOD_PSE_KEY),
  BankingCorrespondent: new PaymentMethod(
    PAYMENT_METHOD_BANKING_CORRESPONDENT_KEY
  ),
};

export {
  PAYMENT_METHOD,
  PAYMENT_METHOD_CARD_KEY,
  PAYMENT_METHOD_PSE_KEY,
  PAYMENT_METHOD_BANKING_CORRESPONDENT_KEY,
};
