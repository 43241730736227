import { TextField, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { UpdatePassword } from "../../../services";
import { ValidationMessage } from "../../../commons";
import { toast } from "react-toastify";
import { toastOptions } from "../../../constants";
import { styles } from "../styles";
import { PrimaryButton, SecondaryButton } from "../../../commons/buttons";

export const ChangePasswordForm = ({
  oldPassword,
  setOldPassword,
  newPassword,
  setNewPassword,
  confirmationPassword,
  setConfirmationPassword,
  passwordErrors,
  setPasswordErrors,
  isLoadingPasswordChange,
  setIsLoadingPasswordChange,
}) => {
  const resetForm = () => {
    setOldPassword("");
    setNewPassword("");
    setConfirmationPassword("");
    setPasswordErrors({});
  };

  const changePassword = async () => {
    if (newPassword.length < 8) {
      return setPasswordErrors({
        new_password: {
          message: "La contraseña debe tener al menos 8 caracteres.",
        },
      });
    }
    setIsLoadingPasswordChange(true);
    const response = await UpdatePassword(oldPassword, newPassword);
    const message = response?.response?.data?.message;
    if (!message) {
      resetForm();
      toast.success("La contraseña se ha cambiado con éxito.", toastOptions);
    } else {
      setPasswordErrors({
        password: {
          message,
        },
      });
    }
    setIsLoadingPasswordChange(false);
  };

  const runPasswordMatchValidation = (newPass, confirmationPass) => {
    if (newPass !== confirmationPass) {
      setPasswordErrors({
        new_password: {
          message: "Las contraseñas no coinciden",
        },
      });
    } else {
      setPasswordErrors((errors) => ({ ...errors, new_password: null }));
    }
  };
  const passConfirmation = (e) => {
    setConfirmationPassword(e.target.value);
    runPasswordMatchValidation(newPassword, e.target.value);
  };
  const runPasswordValidation = (password) => {
    if (/\s/.test(password)) {
      return setPasswordErrors({
        password: {
          message: "La contraseña no puede contener espacios en blanco.",
        },
      });
    } else {
      setPasswordErrors({
        password: null,
      });
    }
  };

  return (
    <Stack spacing={3} sx={{ width: "512px" }}>
      <Stack spacing={1}>
        <Typography sx={styles.inputLabel} noWrap>
          Contraseña actual
        </Typography>
        <TextField
          sx={{ width: "100%", backgroundColor: "#FFFFFF" }}
          size="small"
          variant="outlined"
          error={!!passwordErrors.password}
          placeholder="********"
          type="password"
          onChange={(e) => {
            setOldPassword(e.target.value);
            runPasswordValidation(e.target.value);
          }}
          value={oldPassword}
          inputProps={{
            autoComplete: "new-password",
          }}
        />
        <ValidationMessage errors={passwordErrors} field="password" />
      </Stack>
      <Stack spacing={1}>
        <Typography sx={styles.inputLabel} noWrap>
          Nueva contraseña
        </Typography>
        <TextField
          size="small"
          variant="outlined"
          placeholder="********"
          sx={{ backgroundColor: "#FFFFFF" }}
          type="password"
          onChange={(e) => {
            setNewPassword(e.target.value);
            runPasswordMatchValidation(e.target.value, confirmationPassword);
          }}
          value={newPassword}
        />
      </Stack>
      <Stack spacing={1}>
        <Typography sx={styles.inputLabel} noWrap>
          Confirmar nueva contraseña
        </Typography>
        <TextField
          size="small"
          variant="outlined"
          error={!!passwordErrors.new_password}
          sx={{ backgroundColor: "#FFFFFF" }}
          placeholder="********"
          type="password"
          onChange={passConfirmation}
          value={confirmationPassword}
        />
        <ValidationMessage errors={passwordErrors} field="new_password" />
      </Stack>
      <Stack alignItems="flex-end">
        <SecondaryButton
          loading={isLoadingPasswordChange}
          text="Cambiar contraseña"
          action={changePassword}
          width="fit-content"
          isDisable={
            passwordErrors.new_password ||
            !confirmationPassword ||
            !newPassword ||
            !oldPassword
          }
          style={{ marginBottom: "24px" }}
        />
      </Stack>
    </Stack>
  );
};
