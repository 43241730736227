import React, { useContext, useEffect, useState } from "react";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import { styles } from "./styles";
import { AccountTab, CompanyTab, UsersTab, RemindersTab } from "./components";
import { UserContext } from "../../contexts";
import { useLocation } from "react-router-dom";
import CommunicationsTab from "./components/CommunicationsTab";
import { TableSkeleton } from "../../commons";
import { permissions } from "../../constants";
import { useIsUserAllowedTo } from "../../utils";

export const SettingsScreen = () => {
  const [value, setValue] = useState("account");
  const { isUserAllowedTo } = useIsUserAllowedTo();
  const [loading, setLoading] = useState(true);
  const { getMe, currentUserIsBackofficer } = useContext(UserContext);
  const location = useLocation();

  const handleChange = (event, newValue) => {
    setValue(newValue);
    getMe();
  };

  useEffect(() => {
    getMe().then(() => setLoading(false));
    location.state && location.state.goToSync && setValue("accountingSys");
    location.state && location.state.goToUsers && setValue("users");
  }, []);

  return (
    <>
      <div style={styles.screenContainer}>
        <div
          className="layout-container"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <div style={styles.titleContainer}>
            <Typography sx={styles.title} noWrap>
              Configuración
            </Typography>
          </div>
          {!loading ? (
            <>
              <Box sx={styles.tabTitlesContainer}>
                <Tabs value={value} onChange={handleChange}>
                  {isUserAllowedTo(permissions.CONFIG_ACCOUNT_TAB) && (
                    <Tab
                      sx={{ textTransform: "capitalize" }}
                      label="Cuenta"
                      value="account"
                    />
                  )}
                  {isUserAllowedTo(
                    permissions.CONFIG_ROLES_PERMISSIONS_TAB
                  ) && (
                    <Tab
                      sx={{ textTransform: "none" }}
                      label="Roles y permisos"
                      value="users"
                    />
                  )}
                  {isUserAllowedTo(permissions.CONFIG_COMPANY_TAB) && (
                    <Tab
                      sx={{ textTransform: "capitalize" }}
                      label="Empresa"
                      value="company"
                    />
                  )}
                  {currentUserIsBackofficer && (
                    <Tab
                      sx={{ textTransform: "capitalize" }}
                      label="Notificaciones"
                      value="notifications"
                    />
                  )}
                  {isUserAllowedTo(permissions.CONFIG_REMINDERS_TAB) && (
                    <Tab
                      sx={{ textTransform: "capitalize" }}
                      label="Recordatorios"
                      value="reminders"
                    />
                  )}
                </Tabs>
              </Box>
              {isUserAllowedTo(permissions.CONFIG_COMPANY_TAB) &&
                value === "company" && <CompanyTab />}
              {isUserAllowedTo(permissions.CONFIG_ACCOUNT_TAB) &&
                value === "account" && <AccountTab />}
              {isUserAllowedTo(permissions.CONFIG_ROLES_PERMISSIONS_TAB) &&
                value === "users" && <UsersTab />}
              {currentUserIsBackofficer && value === "notifications" && (
                <CommunicationsTab />
              )}
              {isUserAllowedTo(permissions.CONFIG_REMINDERS_TAB) &&
                value === "reminders" && <RemindersTab />}
            </>
          ) : (
            <TableSkeleton />
          )}
        </div>
      </div>
    </>
  );
};
