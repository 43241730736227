import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router";
import { Box, IconButton, Typography } from "@mui/material";
import {
  NotifyWompiModal,
  Table,
  TableEmptyState,
  TableSkeleton,
  PaymentModal,
  AttentionModal,
  Tooltip,
} from "../../commons";
import { customerColumns, SortingQuery } from "../../utils";
import { styles } from "./styles";
import { StatusTabs } from "./components";
import { GetCustomerByHash, GetTotalCollectionsByHash } from "../../services";
import { CollectionsContext } from "../../contexts";
import { DownloadFileIcon, PayanaLogo } from "../../assets";
import { CustomerPayFooter } from "./components/CustomerPayFooterHandler";
import { WelcomeModal } from "../../commons/modals/WelcomeModal";
import { useMediaQuery } from "react-responsive";
import { objectIsEmpty } from "../../utils/objectUtils";
import { TxReferenceModal } from "../../commons/modals/TxReferenceModal";
import Downloader from "../../services/Downloader";
import { ContactInfo } from "./ContactInfo";

export const CustomerPayScreen = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowsIds, setSelectedRowsIds] = useState([]);
  const companyWithWompi = [
    3, 494, 32, 1185, 1411, 18, 5081, 6685, 2699, 4117, 7853, 34, 7286, 6531,
    557, 7915,
  ];
  const [canPayWithCobre, setCanPayWithCobre] = useState(true);

  const isChecked = (row) =>
    !!selectedRows.find((selectedRow) => selectedRow.original.id === row.id);

  const toggleSelectedRow = (row) => {
    isChecked(row)
      ? setSelectedRows((rows) => rows.filter((r) => r.original.id !== row.id)) // si ya esta checked, sacamos la row
      : setSelectedRows((rows) => [...rows, { original: row }]); // si no, agregamos la row las seleccionadas
  };

  const toggleSelectedAll = (row, selectAllChecked) => {
    if (selectAllChecked) {
      !isChecked(row) &&
        setSelectedRows((rows) => [...rows, { original: row }]);
    } else {
      setSelectedRows((rows) => rows.filter((r) => r.original.id !== row.id));
    }
  };
  const navigate = useNavigate();
  const [totals, setTotals] = useState();
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalPages, setTotalPages] = useState(1);

  let selectedRowsAmount = 0;
  let selectedRowsIdsArr = [];
  let selectedCustomersArr = [];
  const {
    collectionsByHash,
    getCollectionsByHash,
    perPageSelected,
    setPerPageSelected,
    sortingSelected,
    setSortingQuery,
    collectionsScreen,
  } = useContext(CollectionsContext);
  const location = useLocation();
  const isMobile = useMediaQuery({ query: "(max-width: 481px)" });
  const customerIdSelected = collectionsScreen?.customerIdSelected?.value;
  const pageIndex = collectionsScreen?.pageIndex;
  const setPageIndex = collectionsScreen?.setPageIndex;
  const [statusSelected, setStatusSelected] = useState("sent");
  const [tabSelected, setTabSelected] = useState("sent");
  const [customer, setCustomer] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openDeletedCollectionsAlert, setOpenDeletedCollectionsAlert] =
    useState(false);
  const queryParameters = new URLSearchParams(window.location.search);
  const hash = queryParameters.get("hash");

  const onStatusSelectedChanged = (status) => {
    setTabSelected(status);
    setStatusSelected(status);
    setPageIndex(1);
  };

  const setSorting = (fieldToSort) => {
    const sq = new SortingQuery(sortingSelected);
    sq.addField(fieldToSort);
    setSortingQuery(sq.sort);
  };

  useEffect(() => {
    hash &&
      GetCustomerByHash({ hash: hash })
        .then((res) => {
          if (res.at(0)) {
            setCustomer(res[0]);
            setCanPayWithCobre(!companyWithWompi.includes(res[0]?.company_id));
          } else {
            navigate("/error", {
              state: {
                isCustomer: true,
              },
            });
          }
        })
        .finally(() => {
          setLoading(false);
        });

    location.state &&
      location.state.statusSelected &&
      onStatusSelectedChanged(location.state.statusSelected);
  }, []);

  useEffect(() => {
    hash &&
      GetCustomerByHash({ hash: hash })
        .then((res) => {
          if (res.at(0)) {
            setCustomer(res[0]);
          } else {
            navigate("/error", {
              state: {
                isCustomer: true,
              },
            });
          }
        })
        .finally(() => {
          setLoading(false);
        });

    location.state &&
      location.state.statusSelected &&
      onStatusSelectedChanged(location.state.statusSelected);
  }, [hash, location.state]);

  useEffect(() => {
    getCollectionsByHash(
      pageIndex,
      statusSelected,
      hash,
      perPageSelected,
      sortingSelected
    );

    GetTotalCollectionsByHash({
      status: statusSelected,
      hash: hash,
    }).then((res) => {
      setTotals(res);
    });
  }, [pageIndex, statusSelected, hash, perPageSelected, sortingSelected]);

  useEffect(() => {
    if (totals) {
      if (totals.total > perPageSelected) {
        setTotalPages(Math.ceil(totals.total / perPageSelected));
      } else {
        setTotalPages(1);
      }
    }
  }, [totals]);

  useEffect(() => {
    // obtener array de ids para la transacción
    selectedRows.forEach((row) => {
      if (row.original.amount) {
        selectedRowsAmount = selectedRowsAmount + parseInt(row.original.amount);
      }
      selectedRowsIdsArr.push(row.original.id);
      if (
        row.original.customer_id &&
        !selectedCustomersArr.includes(row.original.customer_id)
      ) {
        selectedCustomersArr.push(row.original.customer_id);
      }
    });
    setTotalAmount(selectedRowsAmount);
    setSelectedRowsIds(selectedRowsIdsArr);
  }, [selectedRows]);

  useEffect(() => {
    if (selectedRows.length > 0) {
      let rowsUpdated = [];
      selectedRows.forEach((row) => {
        const collectionFromApi = collectionsByHash.find(
          (collection) => collection.id === row.original.id
        );
        if (
          collectionFromApi &&
          JSON.stringify({ original: collectionFromApi }) !==
            JSON.stringify(row)
        ) {
          rowsUpdated.push({ original: collectionFromApi });
        }
      });
      if (rowsUpdated.length > 0) {
        const selectedRowsUpdated = [...selectedRows].filter(
          (row) => row.original.id !== rowsUpdated[0]?.original?.id
        );
        selectedRowsUpdated.push(rowsUpdated[0]);
        setSelectedRows(selectedRowsUpdated);
      }
    }
  }, [collectionsByHash]);

  const id = queryParameters.get("id");

  const [wompiModalIsVisible, setWompiModalIsVisible] = useState(false);
  const [notifyWompiModalIsVisible, setNotifyWompiModalIsVisible] =
    useState(id);

  const closeWompiModal = () => setWompiModalIsVisible(false);

  const closeNotifyWompiModal = (userHash, statusSelected = "paid") => {
    setNotifyWompiModalIsVisible(false);
    navigate(`/pay?hash=${userHash}`, {
      replace: true,
      state: { statusSelected },
    });
  };

  const disableExpand = (target, html) => {
    const dropdownOptions =
      html.toLowerCase().includes("dropdown") ||
      html.toLowerCase().includes("descargar") ||
      html.toLowerCase().includes("eliminar");
    return (
      target === "input" ||
      target === "svg" ||
      target === "button" ||
      target === "path" ||
      target === "label" ||
      (dropdownOptions && target === "p") ||
      (dropdownOptions && target === "div")
    );
  };

  const handleOnClickRow = (row, target, html) => {
    row.original.status !== "sent" &&
      !disableExpand(target, html) &&
      navigate(`/pay/${row.original.id}?hash=${hash}`);
  };

  const handleCloseDeletedCollectionsAlert = () => {
    setOpenDeletedCollectionsAlert(null);
    window.location.reload(true);
  };

  const downloadFile = async (filePath, concept) => {
    const extension = filePath.split(".").at(-1);
    return Downloader.push(filePath, `${concept}.${extension}`);
  };

  const renderRowEndButtons = (row) => {
    if (isMobile) {
      return (
        <>
          {row.original.file_path && (
            <Tooltip title="Descargar documento">
              <IconButton
                onClick={() =>
                  downloadFile(row.original.file_path, row.original.concept)
                }
              >
                <DownloadFileIcon size={20} stroke={"#98A2B3"} />
              </IconButton>
            </Tooltip>
          )}
        </>
      );
    } else {
      return (
        <td style={{ backgroundColor: "transparent" }}>
          {row.original.file_path && (
            <Tooltip title="Descargar documento">
              <IconButton
                onClick={() =>
                  downloadFile(row.original.file_path, row.original.concept)
                }
              >
                <DownloadFileIcon size={20} stroke={"#98A2B3"} />
              </IconButton>
            </Tooltip>
          )}
        </td>
      );
    }
  };

  const renderAutocomplete = () => {
    return null;
  };

  const handleConfirm = (id, userHash) => {
    navigate(`/pay?hash=${userHash}`, {
      replace: true,
      state: { statusSelected: "paid" },
    });
  };

  const renderLogo = () => {
    return loading ? null : customer?.company_logo ? (
      <img
        style={{
          height: "48px",
          width: "auto",
        }}
        src={customer?.company_logo}
        alt="companyLogo"
      />
    ) : null;
  };

  return (
    <>
      <nav className="customer-nav">
        {isMobile ? (
          <div className="navMobile" style={styles.containerFullWidth}>
            <div className="navDiv">{renderLogo()}</div>
          </div>
        ) : (
          <>{renderLogo()}</>
        )}
      </nav>
      {totalAmount > 0 && (
        <PaymentModal
          selectedRowsIds={selectedRowsIds}
          visible={wompiModalIsVisible}
          totalAmount={totalAmount}
          handleClose={closeWompiModal}
          canPayWithCobre={canPayWithCobre}
          displayCobreAlert={false}
          section="customer"
          type="collection"
          redirectUrl={`${process.env.REACT_APP_BASE_URL}/pay`}
          customerHash={hash}
          payoutsNumber={selectedRowsIds.length}
        />
      )}
      <WelcomeModal />
      <TxReferenceModal section="customer" onConfirm={handleConfirm} />
      <NotifyWompiModal
        visible={notifyWompiModalIsVisible}
        handleClose={closeNotifyWompiModal}
        type="customer"
      />
      <AttentionModal
        title={`Uno o más de los documentos seleccionados fueron eliminados por ${
          !objectIsEmpty(customer) ? customer.company_name : "tu cobrador"
        }`}
        description="Para poder continuar, es necesario que refresques la página."
        buttonText="Refrescar página"
        onClose={handleCloseDeletedCollectionsAlert}
        open={!!openDeletedCollectionsAlert}
      />
      <div style={{ marginBottom: "60px" }} id="page-wrap">
        {loading ? (
          <TableSkeleton />
        ) : (
          <div className="customer-layout-container">
            {!objectIsEmpty(customer) && (
              <>
                <div
                  style={
                    isMobile
                      ? styles.titleContainerMobile
                      : styles.titleContainer
                  }
                >
                  <Typography sx={styles.title} noWrap>
                    Hola {customer.customer_name}
                  </Typography>
                </div>
                <Typography
                  sx={isMobile ? styles.subtitleMobile : styles.subtitle}
                  noWrap
                >
                  Aquí podrás ver tus cuentas por pagar a{" "}
                  <strong>{customer.company_name}</strong>
                </Typography>
              </>
            )}
            {isMobile ? (
              <StatusTabs
                onChange={onStatusSelectedChanged}
                tabSelected={tabSelected}
                setTabSelected={setTabSelected}
              />
            ) : (
              <div className="invoices-data-filters">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "baseline",
                    margin: "0px 2.5%",
                    justifyContent: "space-between",
                    flex: "0 0 auto",
                  }}
                >
                  <StatusTabs
                    onChange={onStatusSelectedChanged}
                    tabSelected={tabSelected}
                    setTabSelected={setTabSelected}
                  />
                </Box>
              </div>
            )}
            <Table
              pageNumber={pageIndex}
              columns={customerColumns(setSorting, sortingSelected)}
              data={collectionsByHash}
              amountModal
              expandIcon
              expandLink={`/payments/invoices/`}
              setSelectedRows={() => {}}
              setPageIndex={setPageIndex}
              checkboxVisible={true}
              pageCount={totalPages}
              supplier={collectionsScreen?.customerFilter?.value}
              setSupplier={collectionsScreen?.customerFilter?.setter}
              section="customer"
              hasFileIcon
              toggleSelectedRow={toggleSelectedRow}
              isChecked={isChecked}
              renderRowEndButtons={renderRowEndButtons}
              toggleSelectedAll={toggleSelectedAll}
              perPage={perPageSelected}
              setPerPage={setPerPageSelected}
              perPageVisible={true}
              statusSelected={statusSelected}
              providerIdSelected={customerIdSelected}
              onClickRow={handleOnClickRow}
              renderAutocomplete={renderAutocomplete}
              emptyState={
                <TableEmptyState
                  title={"No se encontraron resultados para esta búsqueda"}
                  subtitle={"Por favor intente nuevamente"}
                />
              }
              showFilters={collectionsByHash.length > 99 || pageIndex !== 1}
            />
          </div>
        )}
      </div>
      {!loading && <ContactInfo customer={customer} />}
      {collectionsByHash.length > 0 && (
        <CustomerPayFooter
          selectedRows={selectedRows}
          selectedRowsIds={selectedRowsIds}
          totalAmount={totalAmount}
          openWompiModal={() => setWompiModalIsVisible(true)}
          totalSelected={selectedRows.length}
          pageIndex={pageIndex}
        />
      )}
    </>
  );
};
