import React from "react";
import {
  Autocomplete,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { BackgroundInformation, SearchIcon } from "assets";
import { Table, TableEmptyState } from "commons";
import { companiesBOSubmenu } from "utils";
import {
  ModalBilling,
  ModalAlert,
  SectionCompanyInformation,
  SubmenuSubscription,
} from "./components";
import { useBOSubscription } from "./useBOSubscription";
import { boSubcriptionColumns, columnsBilling, VIEW } from "./utils";
import { PrimaryButton } from "commons/buttons";

export const BOSubscription = () => {
  const {
    companies,
    handleShowModalAlert,
    showModalAlert,
    companySelected,
    handleSelectedCompany,
    searchBOCompanies,
    handleChangeSubscription,
    featuresTable,
    billing,
    billingModal,
    openBillingModal,
    closeBillingModal,
    refreshSubscription,
    handleCleanSubscriptionsTable,
    view,
    handleChangeView,
    billings,
    refreshSetCompanyBillings,
    isShowDetail,
    handleSetBilling,
  } = useBOSubscription();

  return (
    <>
      {showModalAlert && (
        <ModalAlert
          show={showModalAlert}
          onHide={() => handleShowModalAlert(false)}
          features={featuresTable}
          companySelected={companySelected}
          refreshSubscription={refreshSubscription}
          hasBilling={billings?.length > 0}
        />
      )}
      {billingModal && (
        <ModalBilling
          billings={billings}
          handleSetBilling={handleSetBilling}
          show={billingModal}
          onHide={() => closeBillingModal()}
          billing={billing}
          companySelected={companySelected}
          showUser={
            !billings?.find((bill) => bill.accountantUserId)?.accountantUserId
          }
          refreshSetCompanyBillings={refreshSetCompanyBillings}
          isShowDetail={isShowDetail}
        />
      )}
      <Stack
        gap={2}
        style={{ marginTop: "16px", marginLeft: "2.5%", marginRight: "2.5%" }}
      >
        <Typography
          sx={{
            fontWeight: "500",
            fontSize: "20px",
            lineHeight: "24px",
            color: "#101828",
          }}
          noWrap
        >
          Backoffice de Suscripciones
        </Typography>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Autocomplete
            options={companies}
            size="small"
            sx={{ width: "35%", minWidth: "164px" }}
            freeSolo
            filterOptions={(options, { inputValue }) => {
              const inputValueLower = inputValue.toLowerCase();
              return options.filter(
                (option) =>
                  option.name.toLowerCase().includes(inputValueLower) ||
                  option.identification_number
                    .toString()
                    .includes(inputValueLower)
              );
            }}
            getOptionLabel={(option) => {
              return option?.name || "";
            }}
            onChange={(event, option) => {
              handleSelectedCompany(option);
            }}
            onInputChange={(event) => {
              if (event?.target?.value?.length >= 2) {
                searchBOCompanies(event.target.value);
              } else {
                handleCleanSubscriptionsTable();
              }
            }}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option?.id}>
                  {option?.name}
                </li>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  style: { fontSize: 14, backgroundColor: "#ffffff" },
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                placeholder={"Buscar compañía"}
              />
            )}
            value={companySelected}
          />
        </Stack>
      </Stack>
      {!!companySelected && (
        <>
          <SectionCompanyInformation company={companySelected} />
          <SubmenuSubscription
            items={companiesBOSubmenu}
            handleChangeView={handleChangeView}
            view={view}
          />
        </>
      )}
      {!!companySelected && view === VIEW.subscription && (
        <>
          <Table
            columns={boSubcriptionColumns({
              handleChangeSubscription,
              openBillingModal,
              companySelected,
              billings,
            })}
            data={featuresTable}
            showFilters={false}
            perPageVisible={false}
            showPagination={false}
            emptyState={
              <TableEmptyState
                title={"No se encontraron compañias"}
                subtitle={"Aquí se mostrarán todas las compañias"}
              />
            }
          />

          <Stack
            alignItems={"flex-end"}
            style={{
              marginLeft: "2.5%",
              marginRight: "2.5%",
              marginBottom: "2.5%",
            }}
          >
            <PrimaryButton
              text="Guardar"
              action={() => handleShowModalAlert(true)}
              style={{
                height: "50px",
                width: "120px",
                fontSize: "18px",
              }}
            />
          </Stack>
        </>
      )}
      {!!companySelected && view === VIEW.billing && (
        <>
          <Table
            columns={columnsBilling({ openBillingModal })}
            data={billings}
            showFilters={false}
            perPageVisible={false}
            showPagination={false}
            emptyState={
              <TableEmptyState
                title={"No se encontraron datos"}
                subtitle={"Aquí se mostrará la información de facturación"}
                hasButton={true}
                buttonText={"Nuevo"}
                buttonAction={() => openBillingModal()}
              />
            }
          />
          {billings?.length === 1 && (
            <Stack
              style={{ marginLeft: "2.5%", marginRight: "2.5%" }}
              alignItems={"flex-end"}
            >
              <PrimaryButton text={"Nuevo"} action={() => openBillingModal()} />
            </Stack>
          )}
        </>
      )}
      {!companySelected && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            marginTop: "100px",
          }}
        >
          <BackgroundInformation />
          <p
            style={{
              margin: "18px 0px",
              textAlign: "center",
              fontSize: "20px",
              fontStyle: "normal",
              fontWeight: 600,
            }}
          >
            Busca una compañía
          </p>
        </div>
      )}
    </>
  );
};
