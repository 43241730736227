import React, { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";
import {
  Stack,
  TextField,
  Typography,
  Autocomplete,
  Box,
  Divider,
} from "@mui/material";
import { styles } from "../../../Table/styles";
import { minimumLengthForSearch, permissions } from "../../../../constants";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { es } from "date-fns/locale";
import { WithoutBorderButton } from "../../../buttons";
import { objectIsEmpty } from "../../../../utils/objectUtils";
import CollectionFileUpload from "./CollectionFileUpload";
import CollectionNotes from "./CollectionNotes";
import CustomerDetailsCollectionForm from "./customerDetailsCollectionForm";
import { CollectionDetail } from "./CollectionDetail";
import {
  AddresseeIcon,
  CalendarIcon,
  CornerDownRightIcon,
} from "../../../../assets";
import { CustomTextField } from "../../CollectionObligationModal";
import PayanaComission from "./PayanaComission";
import { getDetailsToCollect } from "../../../../screens/CollectionsScreen/comission/calcCollectionsComission";
import { useIsUserAllowedTo, isExceeded } from "../../../../utils";
import isCustomerIncomplete from "../../../../screens/CollectionsScreen/isCustomerIncomplete";
import CustomerIncompletedMessage from "./CustomerIncompletedMessage";
import ReceiversSection from "./ReceiversSection";
import DisabledReceivers from "./DisabledReceivers";

export const CollectionForm = ({
  formValues,
  collectionId,
  setFormValues,
  collection,
  customer,
  setCustomer,
  customers,
  selectedFiles,
  setSelectedFiles,
  createdCustomer,
  setDrawerIsOpen,
  getOptions,
  setReceiverDrawerIsOpen,
  companyCollectionsCommission,
  companyCollectionsFixedCommission,
  conceptError,
  setConceptError,
  currentUser,
  collectionNumberError,
  setCollectionNumberError,
  openCustomerForm,
  hasCollectionReceiversEnabled,
}) => {
  const { isUserAllowedTo } = useIsUserAllowedTo();
  const collectionStatusIncludes = (...states) =>
    states.includes(collection.status.toLowerCase());
  const location = useLocation();
  const customerName =
    location?.state?.customerName ||
    collection.customer_name ||
    createdCustomer?.name;
  const isMobile = useMediaQuery({ query: "(max-width: 481px)" });
  const checked = formValues.checked;
  const [isSecondReceiverFormShown, setAddSecondReceiver] = useState(false);
  const receiverOption = formValues.amount_type;
  const setReceiverOption = (option) => {
    setFormValues((formValues) => {
      return {
        ...formValues,
        amount_type: option,
        first_receiver_amount: "",
        second_receiver_amount: "",
      };
    });
  };
  const setChecked = () => {
    setFormValues((formValues) => {
      const checked = !formValues.checked;
      return {
        ...formValues,
        checked,
        receivers: !checked ? [] : formValues.receivers,
        first_receiver_amount: "",
        second_receiver_amount: "",
        firstReceiverOptionSelected: undefined,
        secondReceiverOptionSelected: undefined,
      };
    });
  };
  const showSecondReceiverForm = () => setAddSecondReceiver(true);
  const hideSecondReceiverForm = () => setAddSecondReceiver(false);
  const deleteSecondReceiver = () => {
    hideSecondReceiverForm();
    setFormValues((formValues) => {
      return {
        ...formValues,
        receivers: formValues.receivers.slice(0, 1),
        second_receiver_amount: "",
        secondReceiverOptionSelected: undefined,
      };
    });
  };

  const firstReceiverAmountState = formValues.first_receiver_amount;
  const secondReceiverAmountState = formValues.second_receiver_amount;

  const updateReceivers = (receiverPosition, value) => {
    const receivers = formValues.receivers || [];
    const output = getDetailsToCollect(
      formValues.amount,
      companyCollectionsCommission,
      companyCollectionsFixedCommission,
      receiverPosition === 0 ? value : firstReceiverAmountState,
      receiverPosition === 1 ? value : secondReceiverAmountState,
      receiverOption
    );
    const firstReceiverAmount =
      output.receivers.at(receiverPosition)?.round_comission || 0;
    receivers[receiverPosition] = {
      ...receivers[receiverPosition],
      amount: firstReceiverAmount,
      percentage: formValues.amount_type === "percentage" ? value : null,
    };
    return receivers;
  };

  const setFirstReceiverAmount = (value) => {
    setFormValues((formValues) => {
      const receivers = updateReceivers(0, value);
      return {
        ...formValues,
        receivers,
        first_receiver_amount: value,
      };
    });
  };
  const setSelectedReceiver = (receiverPosition, receiverFieldName, value) => {
    setFormValues((formValues) => {
      const receivers = formValues.receivers || [];
      receivers[receiverPosition] = {
        ...receivers[receiverPosition],
        receiver_id: value?.id,
      };
      return {
        ...formValues,
        receivers,
        [receiverFieldName]: value,
      };
    });
  };
  const setFirstSelectedReceiver = (value) =>
    setSelectedReceiver(0, "firstReceiverOptionSelected", value);
  const setSecondSelectedReceiver = (value) =>
    setSelectedReceiver(1, "secondReceiverOptionSelected", value);

  const setSecondReceiverAmount = (value) => {
    setFormValues((formValues) => {
      const receivers = updateReceivers(1, value);
      return {
        ...formValues,
        receivers,
        second_receiver_amount: value,
      };
    });
  };

  useEffect(() => {
    if (!customerName) {
      const customerNew = customers.find(
        (customer) =>
          customer?.name === collection.customer_name || customerName
      );
      if (!objectIsEmpty(customerNew)) {
        setFormValues({
          ...formValues,
          customer_id: customer?.id,
        });
      }
    }
  }, [customerName, customers]);

  const canModifyCustomer =
    collection.status === "pending" ||
    collection.status === "outstanding_balance";

  const handleOnChangeAutocomplete = (event, newValue) => {
    if (newValue?.id) {
      setFormValues({
        ...formValues,
        customer_id: newValue.id,
      });
      setCustomer(customers.find((customer) => customer.id === newValue.id));
    } else {
      setFormValues({
        ...formValues,
        customer_id: "",
      });
    }
  };

  const { round_comission, receivers, total_to_collector } =
    getDetailsToCollect(
      formValues.amount,
      companyCollectionsCommission,
      companyCollectionsFixedCommission,
      firstReceiverAmountState,
      secondReceiverAmountState,
      receiverOption
    );
  const amountComission = round_comission;
  const firstReceiverAmount = receivers.at(0)?.round_comission || 0;
  const secondReceiverAmount = receivers.at(1)?.round_comission || 0;

  const companyAmount = () => total_to_collector || 0;

  const isReceiverAmountExceeded = isExceeded(
    receiverOption,
    parseInt(firstReceiverAmountState),
    parseInt(secondReceiverAmountState) || 0,
    parseInt(formValues.amount - amountComission)
  );

  const amountWithoutComission =
    parseInt(formValues.amount - amountComission) || 0;

  return (
    <>
      <Stack
        direction={isMobile ? "column" : "row"}
        spacing={2}
        sx={{ margin: "auto", justifyContent: "center" }}
      >
        {collectionStatusIncludes("pending") &&
        isUserAllowedTo(permissions.COLLECTIONS_UPDATE) ? (
          <>
            <Box
              sx={{
                minWidth: isMobile ? 380 : 448,
                maxWidth: isMobile ? 380 : 448,
                margin: "0 auto",
                paddingTop: 2,
                paddingLeft: 2,
                paddingRight: 2,
                marginBottom: "24px",
                width: isMobile ? "100%" : "50%",
              }}
            >
              <>
                <Stack spacing={1} id="new_payment_supplier_field">
                  <div style={styles.sectionRow}>
                    <AddresseeIcon />
                    <span style={styles.cardTitle}>Cliente</span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "-8px",
                    }}
                  >
                    <Typography sx={styles.invoiceCardTitleRevert}>
                      Cliente
                    </Typography>
                    {canModifyCustomer && (
                      <WithoutBorderButton
                        text="Crear nuevo"
                        action={() => setDrawerIsOpen(true)}
                        width="90px"
                      />
                    )}
                  </div>
                  <Autocomplete
                    size="small"
                    options={customers}
                    freeSolo
                    getOptionLabel={(option) => option?.name || ""}
                    onChange={handleOnChangeAutocomplete}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          style: { fontSize: 14 },
                        }}
                        placeholder={"Ingresa el nombre"}
                      />
                    )}
                    value={!objectIsEmpty(customer) ? customer : { name: "" }}
                    disabled={!canModifyCustomer}
                    onInputChange={(event) => {
                      if (
                        event?.target?.value?.length >= minimumLengthForSearch
                      ) {
                        getOptions(event?.target?.value);
                      }
                    }}
                  />
                  {!objectIsEmpty(customer) &&
                  isCustomerIncomplete(customer) ? (
                    <CustomerIncompletedMessage
                      openCustomerForm={openCustomerForm}
                    />
                  ) : (
                    formValues.customer_id && (
                      <CustomerDetailsCollectionForm
                        documentType={customer?.document_type}
                        documentNumber={customer?.document_number}
                        email={customer?.email}
                      />
                    )
                  )}
                </Stack>
                <div style={styles.invoiceRow}>
                  <div style={styles.invoiceColumn}>
                    <Stack spacing={1} id="new_payment_amount_field">
                      <Typography sx={styles.invoiceCardTitle}>
                        Monto
                      </Typography>
                      <NumericFormat
                        id="amount"
                        name="amount"
                        thousandSeparator="."
                        decimalSeparator=","
                        allowNegative={false}
                        isNumericString={true}
                        value={formValues.amount}
                        customInput={CustomTextField}
                        onValueChange={(values, sourceInfo) => {
                          setFormValues({
                            ...formValues,
                            amount: values.floatValue,
                          });
                        }}
                      />
                    </Stack>
                  </div>
                </div>
                <div style={styles.invoiceRow}>
                  <div style={styles.invoiceColumn}>
                    <Stack spacing={1}>
                      <Typography sx={styles.invoiceCardTitle}>
                        Concepto
                      </Typography>
                      <TextField
                        size="small"
                        variant="outlined"
                        placeholder="Ej: Anticipo por reparación maquinaria"
                        InputProps={{
                          style: { fontSize: 14 },
                        }}
                        onChange={(e) => {
                          setFormValues({
                            ...formValues,
                            concept: e.target.value,
                          });
                          if (e?.target?.value?.length === 0) {
                            setConceptError({
                              helperText: "Este campo no puede estar vacío",
                              error: true,
                            });
                          } else {
                            if (
                              !e.target.value.match(/^[\p{L}\p{N}\p{Zs}]+$/gmu)
                            ) {
                              setConceptError({
                                helperText: "El formato del campo no es válido",
                                error: true,
                              });
                            } else {
                              setConceptError({
                                helperText: "",
                                error: false,
                              });
                            }
                          }
                        }}
                        value={formValues.concept}
                        error={conceptError.error}
                      />
                      {conceptError.error && (
                        <Typography sx={styles.inputError} noWrap>
                          {conceptError.helperText}
                        </Typography>
                      )}
                    </Stack>
                  </div>
                </div>
                <div style={styles.invoiceRow}>
                  <div style={styles.invoiceColumn}>
                    <Stack spacing={1} sx={{ marginRight: "12px" }}>
                      <Typography sx={styles.invoiceCardTitle}>
                        Número de comprobante
                      </Typography>
                      <TextField
                        size="small"
                        variant="outlined"
                        placeholder="Número de comprobante"
                        onChange={(e) => {
                          setFormValues({
                            ...formValues,
                            collection_number: e.target.value,
                          });
                          if (e?.target?.value?.length === 0) {
                            setCollectionNumberError({
                              helperText: "Este campo no puede estar vacío",
                              error: true,
                            });
                          } else {
                            if (!e.target.value.match(/^[A-Za-z0-9_-]+$/)) {
                              setCollectionNumberError({
                                helperText: "El formato del campo no es válido",
                                error: true,
                              });
                            } else {
                              setCollectionNumberError({
                                helperText: "",
                                error: false,
                              });
                            }
                          }
                        }}
                        value={formValues.collection_number}
                        InputProps={{
                          style: { fontSize: 14 },
                        }}
                        error={collectionNumberError.error}
                      />
                      {collectionNumberError.error && (
                        <Typography sx={styles.inputError} noWrap>
                          {collectionNumberError.helperText}
                        </Typography>
                      )}
                    </Stack>
                  </div>
                  <div style={styles.invoiceColumn}>
                    <Stack spacing={1}>
                      <Typography sx={styles.invoiceCardTitle}>
                        Fecha de vencimiento
                      </Typography>
                      <LocalizationProvider
                        adapterLocale={es}
                        dateAdapter={AdapterDateFns}
                      >
                        <DatePicker
                          value={formValues.expiration_date}
                          onChange={(value) => {
                            setFormValues({
                              ...formValues,
                              expiration_date: value,
                            });
                          }}
                          slotProps={{
                            textField: {
                              InputProps: {
                                style: { fontSize: 14 },
                              },
                              size: "small",
                              error: false,
                            },
                          }}
                          format="dd/MM/yyyy"
                          slots={{
                            openPickerIcon: () => (
                              <CalendarIcon stroke="#667085" />
                            ),
                          }}
                        />
                      </LocalizationProvider>
                    </Stack>
                  </div>
                </div>
                <div style={styles.invoiceRow}>
                  <div style={styles.invoiceColumn}>
                    <Stack spacing={1}>
                      <Typography sx={styles.invoiceCardTitle}>
                        {collection.file_path
                          ? "Reemplazar documento"
                          : "Agregar documento"}
                      </Typography>
                      <CollectionFileUpload
                        selectedFiles={selectedFiles}
                        setSelectedFiles={setSelectedFiles}
                        isInvoiceFile={true}
                      />
                    </Stack>
                  </div>
                </div>
                {hasCollectionReceiversEnabled ? (
                  <ReceiversSection
                    checked={checked}
                    setChecked={setChecked}
                    receiverOption={receiverOption}
                    setReceiverOption={setReceiverOption}
                    firstReceiverAmountState={firstReceiverAmountState}
                    setFirstReceiverAmount={setFirstReceiverAmount}
                    setReceiverDrawerIsOpen={setReceiverDrawerIsOpen}
                    formValues={formValues}
                    setFirstSelectedReceiver={setFirstSelectedReceiver}
                    isReceiverAmountExceeded={isReceiverAmountExceeded}
                    amountWithoutComission={amountWithoutComission}
                    isSecondReceiverFormShown={isSecondReceiverFormShown}
                    showSecondReceiverForm={showSecondReceiverForm}
                    secondReceiverAmountState={secondReceiverAmountState}
                    setSecondReceiverAmount={setSecondReceiverAmount}
                    setSecondSelectedReceiver={setSecondSelectedReceiver}
                    deleteSecondReceiver={deleteSecondReceiver}
                  />
                ) : (
                  <DisabledReceivers />
                )}
              </>
              {!isMobile && (
                <CollectionNotes
                  collectionId={collectionId}
                  status={collection.status.toLowerCase()}
                  width={
                    isMobile
                      ? collection.status.toLowerCase() === "pending"
                        ? 380
                        : 360
                      : collection.status.toLowerCase() === "pending"
                      ? 428
                      : 544
                  }
                />
              )}
            </Box>
            <Box
              sx={{
                width: isMobile ? "100%" : "50%",
                minWidth: isMobile ? 380 : 400,
                maxWidth: isMobile ? 380 : 400,
              }}
            >
              <div
                style={{
                  marginTop: "24px",
                  width: "100%",
                  backgroundColor: "#F2F3FF",
                  borderRadius: "8px",
                  padding: "24px",
                  position: "sticky",
                  top: "8px",
                }}
              >
                <p style={{ ...styles.cardTitle, marginBottom: "24px" }}>
                  Detalle del cobro
                </p>
                <div style={styles.supplierDataCard}>
                  <p
                    style={styles.invoiceSupplierCardTitle}
                    className="card-subtitle mb-2"
                  >
                    Importe de la factura
                  </p>
                  <p
                    style={styles.collectionTotalsCardText}
                    className="card-subtitle mb-2"
                  >
                    $
                    <NumericFormat
                      value={parseInt(formValues.amount) || 0}
                      displayType={"text"}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      decimalScale={2}
                    />
                  </p>
                </div>
                <PayanaComission
                  amount={parseInt(formValues.amount)}
                  companyCollectionsCommission={companyCollectionsCommission}
                  companyCollectionsFixedCommission={
                    companyCollectionsFixedCommission
                  }
                />
                <Divider sx={{ marginBottom: "8px" }} />
                <div style={styles.supplierDataCard}>
                  <p
                    style={styles.collectionTotalCardTitle}
                    className="card-subtitle mb-2"
                  >
                    Total a recibir
                  </p>
                  <p
                    style={styles.collectionTotalCardText}
                    className="card-subtitle mb-2"
                  >
                    $
                    <NumericFormat
                      value={amountWithoutComission}
                      displayType={"text"}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      decimalScale={2}
                    />
                  </p>
                </div>
                <>
                  <div style={styles.receiverDataCard}>
                    <p
                      style={styles.receiverCardTitle}
                      className="card-subtitle mb-2"
                    >
                      <CornerDownRightIcon /> Destinatario{" "}
                      {currentUser?.company?.name}
                    </p>
                    <p
                      style={styles.collectionTotalsCardText}
                      className="card-subtitle mb-2"
                    >
                      $
                      <NumericFormat
                        value={parseInt(companyAmount(formValues.amount))}
                        displayType={"text"}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        decimalScale={2}
                      />
                    </p>
                  </div>
                  {checked &&
                    !objectIsEmpty(formValues?.firstReceiverOptionSelected) && (
                      <div style={styles.receiverDataCard}>
                        <p
                          style={styles.receiverCardTitle}
                          className="card-subtitle mb-2"
                        >
                          <CornerDownRightIcon /> Destinatario{" "}
                          {formValues?.firstReceiverOptionSelected?.receiverName?.toUpperCase() ||
                            formValues?.firstReceiverOptionSelected?.name?.toUpperCase()}
                        </p>
                        <p
                          style={styles.collectionTotalsCardText}
                          className="card-subtitle mb-2"
                        >
                          $
                          <NumericFormat
                            value={firstReceiverAmount || 0}
                            displayType={"text"}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            decimalScale={2}
                          />
                        </p>
                      </div>
                    )}
                  {checked && !objectIsEmpty(formValues?.receivers?.at(1)) && (
                    <div style={styles.receiverDataCard}>
                      <p
                        style={styles.receiverCardTitle}
                        className="card-subtitle mb-2"
                      >
                        <CornerDownRightIcon /> Destinatario{" "}
                        {formValues?.secondReceiverOptionSelected?.receiverName?.toUpperCase() ||
                          formValues?.secondReceiverOptionSelected?.name?.toUpperCase()}
                      </p>
                      <p
                        style={styles.collectionTotalsCardText}
                        className="card-subtitle mb-2"
                      >
                        $
                        <NumericFormat
                          value={secondReceiverAmount || 0}
                          displayType={"text"}
                          thousandSeparator={"."}
                          decimalSeparator={","}
                          decimalScale={2}
                        />
                      </p>
                    </div>
                  )}
                </>
              </div>
            </Box>
          </>
        ) : (
          <Box
            sx={{
              minWidth: isMobile ? 360 : 544,
              maxWidth: isMobile ? 360 : 544,
              margin: "auto",
              marginBottom: "24px",
            }}
          >
            <CollectionDetail collection={collection} customer={customer} />
          </Box>
        )}
      </Stack>
      {(!collectionStatusIncludes("pending") || isMobile) && (
        <CollectionNotes
          collectionId={collectionId}
          status={collection.status.toLowerCase()}
          width={
            isMobile
              ? collection.status.toLowerCase() === "pending"
                ? 380
                : 360
              : collection.status.toLowerCase() === "pending"
              ? 448
              : 544
          }
        />
      )}
    </>
  );
};
