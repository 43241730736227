import React, { useContext, useState } from "react";
import { Collapse, Divider, MenuItem, Select, Stack } from "@mui/material";
import { TrackJS } from "trackjs";
import { PrimaryButton, SecondaryButton } from "../../buttons";
import { ValidationMessage } from "../../components";
import { styles } from "../PaymentModal/styles";
import { toast } from "react-toastify";
import {
  DEFAULT_PAYMENT_CARD_FEE,
  PAYMENT_METHOD_CARD_KEY,
  PAYMENT_METHOD_PSE_KEY,
  toastOptions,
} from "../../../constants";
import { UserContext } from "../../../contexts";
import ComissionRetentions from "../PaymentModal/ComissionRetentions";
import { payanaComissionRetentions } from "../../../utils";
import PaymentModalRow from "../PaymentModal/PaymentModalRow";
import getPayanaComission from "../PaymentModal/getPayanaComission";

const ConfirmSourceOfFundsModal = ({
  sourcesOfFunds,
  selectedId = "",
  loading = false,
  canPayWithCobre,
  onRegisterNew = () => {},
  onSelected = () => {},
  onSkipFlow = () => {},
  openCobreFlow = () => {},
  openWompiFlow = () => {},
  totalAmount,
  selectedRowsIds = [],
  type,
  setTotalAmountToPay,
  amountTotalInvoicePayana,
  payoutsNumber,
  section,
  selectedPaymentMethod,
}) => {
  const [linkGenerated, setLinkGenerated] = useState(false);
  const {
    newPricing,
    newPricingMetadata,
    comissionRetentions,
    comissionRetentionIsEnabled,
  } = useContext(UserContext);
  const [isLoadingPayment, setLoadingPayment] = useState(false);
  const selectedSourcesOfFunds = sourcesOfFunds.find(
    (sourceOfFund) => sourceOfFund.id === selectedId
  );
  const value =
    selectedSourcesOfFunds?.type === "card_reference"
      ? PAYMENT_METHOD_CARD_KEY
      : selectedSourcesOfFunds?.type === "bank_account"
      ? PAYMENT_METHOD_PSE_KEY
      : "";

  let formatAmount = new Intl.NumberFormat("es-CO", {
    style: "currency",
    currency: "COP",
    minimumFractionDigits: 0,
  });
  const cardFeePercentage = DEFAULT_PAYMENT_CARD_FEE;

  const payoutsNumberOrDefault = payoutsNumber || selectedRowsIds.length;

  const { grossComission: payanaComission, label: commissionLabel } =
    getPayanaComission({
      amountTotalInvoicePayana,
      newPricing,
      newPricingMetadata,
      selectedPaymentMethod: value,
      totalAmount,
      type,
      cardFeePercentage,
      payoutsNumber: payoutsNumberOrDefault,
      formatAmount,
    });

  const totalToPay = (selectedPaymentMethod, retentions) => {
    const amountToPay =
      comissionRetentionIsEnabled &&
      selectedPaymentMethod === PAYMENT_METHOD_PSE_KEY
        ? newPricing
          ? payanaComission -
            payanaComissionRetentions(
              payanaComission,
              selectedPaymentMethod,
              retentions
            ) +
            totalAmount
          : totalAmount
        : payanaComission + totalAmount;
    setTotalAmountToPay(amountToPay);
    return amountToPay;
  };

  const isComissionRetentionsVisible =
    section !== "customer" &&
    comissionRetentionIsEnabled &&
    comissionRetentions.length > 0 &&
    newPricing &&
    payanaComission > 0 &&
    selectedPaymentMethod === PAYMENT_METHOD_PSE_KEY;

  return (
    <div
      className="source-of-funds-form register-new-form"
      style={{ display: "inline" }}
    >
      <Stack justifyContent="space-between">
        {sourcesOfFunds.length > 0 ? (
          <SourceOfFundsSelector
            onRegisterNew={onRegisterNew}
            onSelected={onSelected}
            selectedId={selectedId}
            sourcesOfFunds={sourcesOfFunds}
          />
        ) : (
          <PrimaryButton
            width="100%"
            text="Agregar origen de fondos"
            action={onRegisterNew}
          />
        )}
        <Stack style={{ marginTop: 12 }}>
          {[PAYMENT_METHOD_PSE_KEY, PAYMENT_METHOD_CARD_KEY].includes(
            value
          ) && (
            <div style={styles.sourceOfFundsInfoContainer}>
              <PaymentModalRow
                label={`Pagos a realizar (x${payoutsNumberOrDefault})`}
                value={formatAmount.format(totalAmount)}
              />
              <PaymentModalRow
                condition={commissionLabel && payanaComission > 0}
                label={commissionLabel}
                value={formatAmount.format(payanaComission)}
              />
              <Divider sx={styles.divider} />
              <Collapse
                sx={{ width: "100%" }}
                in={isComissionRetentionsVisible}
              >
                <ComissionRetentions
                  comissionRetentions={comissionRetentions}
                  formatAmount={formatAmount}
                  payanaComission={payanaComission}
                />
                <Divider sx={styles.divider} />
              </Collapse>
              <PaymentModalRow
                label="Total a pagar"
                value={formatAmount.format(
                  totalToPay(value, comissionRetentions)
                )}
              />
            </div>
          )}
        </Stack>
        <Buttons
          disabled={!selectedId || linkGenerated || isLoadingPayment}
          isLoadingPayment={isLoadingPayment || loading}
          skipFlow={onSkipFlow}
          handleClick={async () => {
            try {
              setLoadingPayment(true);
              if (canPayWithCobre && value !== PAYMENT_METHOD_CARD_KEY) {
                await openCobreFlow();
              } else {
                await openWompiFlow();
              }
              setLinkGenerated(true);
            } catch (error) {
              TrackJS.console.error(error);
              toast.error(
                "Ha ocurrido un error, intente nuevamente",
                toastOptions
              );
            } finally {
              setLoadingPayment(false);
            }
          }}
          value={value}
        />
      </Stack>
    </div>
  );
};

function SourceOfFundsSelector({
  onRegisterNew,
  onSelected,
  selectedId,
  sourcesOfFunds,
}) {
  return (
    <div style={{ marginBottom: 6 }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        style={{ marginBottom: 6 }}
      >
        <div
          style={{
            color: "#344054",
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "20px",
          }}
        >
          Seleccione el origen de sus fondos
        </div>
        <div
          style={{
            color: "#6938EF",
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "20px",
            cursor: "pointer",
          }}
          onClick={onRegisterNew}
        >
          Agregar
        </div>
      </Stack>
      <Stack spacing={1}>
        <Select
          size="small"
          variant="outlined"
          displayEmpty
          inputProps={{
            id: "sourceOfFunds",
          }}
          onChange={(e) => onSelected(e.target.value)}
          value={selectedId || ""}
        >
          <MenuItem disabled value="">
            Elige desde dónde quieres pagar
          </MenuItem>
          {sourcesOfFunds.map((type) => {
            return (
              <MenuItem key={type.id} value={type.id}>
                {type.label}
              </MenuItem>
            );
          })}
        </Select>
        <ValidationMessage errors={{}} field="sourceOfFunds" />
      </Stack>
    </div>
  );
}
function Buttons({
  handleClick,
  isLoadingPayment = false,
  skipFlow,
  disabled,
  value,
}) {
  return (
    <>
      {value && (
        <Stack direction="row" justifyContent="space-between" pt={1}>
          <PrimaryButton
            isDisable={disabled}
            loading={isLoadingPayment}
            width="100%"
            text={
              value === PAYMENT_METHOD_PSE_KEY
                ? "Pagar con PSE"
                : "Pagar con tarjeta de crédito"
            }
            action={handleClick}
          />
        </Stack>
      )}
      <Stack direction="row" justifyContent="space-between" pt={1}>
        <SecondaryButton
          loading={isLoadingPayment}
          width="100%"
          text="No registrar origen de los fondos"
          action={skipFlow}
        />
      </Stack>
    </>
  );
}

export { ConfirmSourceOfFundsModal };
