import { Autocomplete, Box, IconButton, TextField } from "@mui/material";
import { BlockIcon, DeleteIcon } from "../assets";
import { AutocompleteTable, Tooltip } from "commons/components";
import {
  AutocompleteWareHouse,
  InputDescription,
  InputEditNumberRow,
  InputNumberRow,
} from "commons/modals/ExpandCausationModal/components";
import { CheckboxPayana } from "commons/components/CheckboxPayana.js";
import "intro.js/introjs.css";
import { erpNames } from "constants";

export const expandCausationColumns = ({
  searchProductsOptions,
  taxesIva,
  taxesRete,
  handleOnChangeItem,
  handleDeleteItem,
  getSearchOptionsProducts,
  isCaused,
  selectItem,
  idCellRef,
  handleSelectAllItems,
  hasCompanyWareHouse,
  hasErpCausation,
  erpName,
  costCenter,
  getSearchOptionsSupplier,
  isTotalizeItemsEnabled,
}) => {
  const columns = [
    {
      field: "product",
      headerName: "Producto",
      sortable: false,
      flex: 1,
      cellClassName: (params) => {
        return params.row.isSelected
          ? "super-app-theme--cell  cell-select"
          : "super-app-theme--cell ";
      },
      renderCell: (params) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              marginLeft: "4px",
            }}
            className="multipleSelect"
            ref={idCellRef}
          >
            {!isCaused && (
              <CheckboxPayana
                onClick={() => selectItem(params.row.id)}
                checked={params.row.isSelected}
              />
            )}
            <AutocompleteTable
              name="product"
              disabled={isCaused}
              options={searchProductsOptions}
              tabIndex={params.tabIndex}
              getOptionLabel={(option) =>
                option?.fname
                  ? `${option?.fcode.trim()} - ${option?.fname.trim()}`
                  : ""
              }
              onChange={(event, option) => {
                event.preventDefault();
                handleOnChangeItem(params, "product", option);
              }}
              onInputChange={(event) => {
                getSearchOptionsProducts("product", event?.target?.value);
              }}
              value={params.value || ""}
              tableConfig={{
                columns: [
                  {
                    headerName: "Código",
                    getText: (option) => option?.fcode,
                  },
                  {
                    headerName: "Descripción",
                    getText: (option) => option?.fname?.trim(),
                  },
                ],
                rowClassName: "causation",
                getRowTooltipText: (option) =>
                  `${option?.fcode} - ${option?.fname}`,
              }}
            />
          </div>
        );
      },
      renderHeader: (params) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "12px",
              padding: "1px",
            }}
          >
            {!isCaused && <CheckboxPayana onClick={handleSelectAllItems} />}
            <p
              style={{
                fontSize: "12px",
                color: "#101828",
                fontWeight: 600,
                lineHeight: "18px",
                marginBottom: "0px",
              }}
            >
              Producto
            </p>
          </div>
        );
      },
    },
    {
      field: "description",
      headerName: "Descripción",
      sortable: false,
      flex: 1,
      renderHeader: (params) => {
        return (
          <p
            style={{
              fontSize: "12px",
              color: "#101828",
              fontWeight: 600,
              lineHeight: "18px",
              marginBottom: "0px",
            }}
          >
            Descripción
          </p>
        );
      },
      renderCell: (params) => {
        return (
          <InputDescription
            isCaused={isCaused}
            valueRow={params.value}
            onChange={(value) =>
              handleOnChangeItem(params, "description", value)
            }
          />
        );
      },
    },
    {
      field: "quantity",
      headerName: "Cant.",
      type: "number",
      editable: !isCaused,
      width: 80,
      sortable: false,
      renderHeader: (params) => {
        return (
          <p
            style={{
              fontSize: "12px",
              color: "#101828",
              fontWeight: 600,
              lineHeight: "18px",
              marginBottom: "0px",
            }}
          >
            Cant.
          </p>
        );
      },
      renderEditCell: (params) => {
        return (
          <InputEditNumberRow
            isCaused={isCaused}
            valueRow={params.value}
            onChange={(value) => handleOnChangeItem(params, "quantity", value)}
            formatMoney={false}
            {...params}
          />
        );
      },
    },
    {
      field: "unitValue",
      headerName: "Valor unitario",
      type: "number",
      editable: !isCaused,
      sortable: false,
      width: 160,
      renderHeader: (params) => {
        return (
          <p
            style={{
              fontSize: "12px",
              color: "#101828",
              fontWeight: 600,
              lineHeight: "18px",
              marginBottom: "0px",
            }}
          >
            Valor unitario
          </p>
        );
      },
      renderCell: (params) => {
        return <InputNumberRow value={params.value} />;
      },
      renderEditCell: (params) => {
        return (
          <InputEditNumberRow
            isCaused={isCaused}
            valueRow={params.value}
            onChange={(value) => handleOnChangeItem(params, "unitValue", value)}
            formatMoney={true}
            {...params}
          />
        );
      },
    },
    {
      field: "discount",
      headerName: "Descuento",
      type: "number",
      editable: !isCaused,
      sortable: false,
      width: 130,
      renderHeader: (params) => {
        return (
          <p
            style={{
              fontSize: "12px",
              color: "#101828",
              fontWeight: 600,
              lineHeight: "18px",
              marginBottom: "0px",
            }}
          >
            Descuento
          </p>
        );
      },
      renderCell: (params) => {
        return <InputNumberRow value={params.value} />;
      },
      renderEditCell: (params) => {
        return (
          <InputEditNumberRow
            isCaused={isCaused}
            valueRow={params.value}
            onChange={(value) => handleOnChangeItem(params, "discount", value)}
            formatMoney={true}
            {...params}
          />
        );
      },
    },
    {
      field: "taxIva",
      headerName: "Imp. Cargo",
      sortable: false,
      width: isTotalizeItemsEnabled ? 150 : 105,
      renderHeader: (params) => {
        return (
          <p
            style={{
              fontSize: "12px",
              color: "#101828",
              fontWeight: 600,
              lineHeight: "18px",
              marginBottom: "0px",
            }}
          >
            Imp. Cargo
          </p>
        );
      },
      cellClassName: (params) => {
        return `${
          params.row.isSelected ? "cell-select" : ""
        } tax-iva-container`;
      },
      renderCell: (params) => {
        return (
          <>
            {isTotalizeItemsEnabled && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "#F2F4F7",
                  width: "20%",
                  height: "100%",
                  color: "#667085",
                  textAlign: "right",
                }}
              >
                <p style={{ margin: 0 }}>{`${
                  params?.row?.taxIvaPercentage >= 0
                    ? `${params?.row?.taxIvaPercentage}%`
                    : ""
                }`}</p>
              </div>
            )}
            <Tooltip title={params?.value?.description}>
              <Autocomplete
                disabled={isCaused}
                PaperComponent={({ children }) => (
                  <Box className="autocomplete-container">{children}</Box>
                )}
                options={taxesIva}
                getOptionLabel={(option) => option.description || ""}
                value={
                  taxesIva.find((tax) => tax.id === params.value.id) || null
                }
                onChange={(_, newValue) =>
                  handleOnChangeItem(params, "taxIva", newValue?.id || null)
                }
                size="small"
                sx={{
                  ...stylesSelectedTaxs,
                  width: isTotalizeItemsEnabled ? "80%" : "100%",
                }}
                noOptionsText="No se encontró ningún resultado para tu búsqueda"
                renderOption={(props, option) => {
                  return (
                    <li
                      {...props}
                      key={option?.id}
                      style={{ fontSize: "14px" }}
                    >
                      {option.description}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { fontSize: 14, minWidth: "100%" },
                    }}
                    sx={{
                      "& .MuiAutocomplete-input": {
                        fontSize: "14px",
                        minWidth: "100%",
                      },
                    }}
                  />
                )}
              />
            </Tooltip>
          </>
        );
      },
    },
    {
      field: "taxRetefuente",
      headerName: "Imp. Retención",
      sortable: false,
      width: 105,
      renderHeader: (params) => {
        return (
          <p
            style={{
              fontSize: "12px",
              color: "#101828",
              fontWeight: 600,
              lineHeight: "18px",
              marginBottom: "0px",
            }}
          >
            Imp. Retención
          </p>
        );
      },
      cellClassName: (params) => {
        return params.row.isSelected ? "cell-select" : "";
      },
      renderCell: (params) => (
        <Tooltip title={params?.value?.description}>
          <Autocomplete
            disabled={isCaused}
            PaperComponent={({ children }) => (
              <Box className="autocomplete-container">{children}</Box>
            )}
            options={taxesRete}
            getOptionLabel={(option) => option.description || ""}
            value={taxesRete.find((tax) => tax.id === params.value.id) || null}
            onChange={(_, newValue) =>
              handleOnChangeItem(params, "taxRetefuente", newValue?.id || null)
            }
            size="small"
            sx={stylesSelectedTaxs}
            noOptionsText="No se encontró ningún resultado para tu búsqueda"
            renderOption={(props, option) => {
              return (
                <li {...props} key={option?.id} style={{ fontSize: "14px" }}>
                  {option.description}
                </li>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  style: { fontSize: 14, minWidth: "100%" },
                }}
                sx={{
                  "& .MuiAutocomplete-input": {
                    fontSize: "14px",
                    minWidth: "100%",
                  },
                }}
              />
            )}
          />
        </Tooltip>
      ),
    },
    {
      field: "amountTotal",
      headerName: "Valor Total",
      sortable: false,
      width: 110,
      renderHeader: (params) => {
        return (
          <p
            style={{
              fontSize: "12px",
              color: "#101828",
              fontWeight: 600,
              lineHeight: "18px",
              marginBottom: "0px",
            }}
          >
            Valor Total
          </p>
        );
      },
      type: "number",
      renderCell: (params) => {
        return <InputNumberRow value={params.value} />;
      },
    },
  ];

  if (!isCaused) {
    const deleteItemColumn = {
      field: "deleteItem",
      headerName: "",
      sortable: false,
      width: 60,
      renderCell: (params) => (
        <IconButton onClick={() => handleDeleteItem(params.id)}>
          <DeleteIcon />
        </IconButton>
      ),
    };
    columns.push(deleteItemColumn);
  }

  if (hasCompanyWareHouse) {
    const warehouseColumn = {
      field: "warehouse",
      headerName: "Bodega",
      width: 163,
      sortable: false,
      cellClassName: (params) => {
        return params.row?.isSelected &&
          params.row?.product?.is_inventory_control
          ? "super-app-theme--cell  cell-select"
          : "super-app-theme--cell ";
      },
      renderHeader: (params) => {
        return (
          <p
            style={{
              fontSize: "12px",
              color: "#101828",
              fontWeight: 600,
              lineHeight: "18px",
              marginBottom: "0px",
            }}
          >
            Bodega
          </p>
        );
      },
      renderCell: (params) => {
        const { is_inventory_control = false } = params.row?.product;

        if (!params.row?.product?.id) {
          return (
            <Tooltip title={"Ingresa el producto primero"}>
              <div
                style={{ height: "100%", width: "100%", cursor: "not-allowed" }}
              ></div>
            </Tooltip>
          );
        }

        return is_inventory_control ? (
          <AutocompleteWareHouse
            params={params}
            handleOnChangeItem={handleOnChangeItem}
          />
        ) : (
          <Tooltip
            title={
              "Este producto no requiere bodega debido a su configuración actual"
            }
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "#F2F4F7",
                width: "100%",
                height: "100%",
              }}
            >
              <BlockIcon width={24} height={24} fill="#667085" />
            </div>
          </Tooltip>
        );
      },
    };
    columns.splice(2, 0, warehouseColumn);
  }

  if (
    hasErpCausation &&
    [erpNames.worldOffice, erpNames.siigoPyme].includes(erpName)
  ) {
    const costCenterColumn = {
      field: "itemCostCenter",
      headerName: "itemCostCenter",
      width: 163,
      sortable: false,
      cellClassName: (params) => {
        return params.row?.isSelected
          ? "super-app-theme--cell  cell-select"
          : "super-app-theme--cell ";
      },
      renderHeader: (params) => {
        return (
          <p
            style={{
              fontSize: "12px",
              color: "#101828",
              fontWeight: 600,
              lineHeight: "18px",
              marginBottom: "0px",
            }}
          >
            Centro de costos
          </p>
        );
      },
      renderCell: (params) => {
        return (
          <Autocomplete
            name="itemCostCenter"
            size="small"
            options={costCenter}
            freeSolo
            getOptionLabel={(option) => ` ${option.name}`}
            onChange={(event, value) => {
              handleOnChangeItem(params, "costCenter", value);
            }}
            onInputChange={(event) => {
              getSearchOptionsSupplier("costCenter", event?.target?.value);
            }}
            filterOptions={(options, { inputValue }) => {
              const inputValueLower = inputValue.toLowerCase();
              return options?.filter(
                (option) =>
                  option?.name?.toLowerCase().includes(inputValueLower) ||
                  option?.code?.toString().includes(inputValueLower)
              );
            }}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option?.id}>
                  {option?.name}
                </li>
              );
            }}
            sx={{
              width: "100%",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              "& fieldset": {
                border: "none",
              },
            }}
            value={
              costCenter.find(
                (item) => item.id === params.row.costCenter?.id
              ) || null
            }
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  style: { fontSize: 14 },
                }}
              />
            )}
          />
        );
      },
    };

    columns.splice(2, 0, costCenterColumn);
  }

  return columns;
};

const stylesSelectedTaxs = {
  fontSize: "13px",
  width: "100%",
  height: "100%",
  "& fieldset": {
    border: "none",
  },
  "& .MuiSvgIcon-root": {
    display: "none",
  },
  "& .MuiInputBase-root": {
    width: "100%",
  },
  "& .MuiSelect-select": {
    padding: "0px !important",
    width: "100%",
    height: "100%",
    maxHeight: "50px",
    display: "flex",
    alignItems: "center",
    textOverflow: "ellipsis",
    whiteSpace: "normal",
  },
  "& .MuiAutocomplete-input": {
    fontSize: "14px",
  },
  "& .MuiAutocomplete-option": {
    fontSize: "14px",
  },
};
