import React from "react";
import { Modal } from "react-bootstrap";
import { PrimaryButton } from "commons/buttons";
import { styles } from "./styles";

export const CantRegisterServiceModal = ({ visible, handleClose }) => {
  return (
    <Modal show={visible} onHide={handleClose} centered style={styles.modal}>
      <Modal.Body style={styles.modalBody}>
        <div style={styles.modalTitle}>
          Servicio no disponible para registro en agenda
        </div>
        <div style={styles.modalText}>
          Este servicio no esta disponible para recibir nuevas deudas de manera
          automática.
          <br />
          Si tienes deudas puedes realizar el pago de este servicio, pero no
          recibirás notificaciones de nueva deuda.
        </div>
        <PrimaryButton text="Entendido" action={handleClose} width="100%" />
      </Modal.Body>
    </Modal>
  );
};
