import useSWR from "swr";
import { requestContainer } from "../services/api/requestContainer";
import { getAmountToPayWithCreditCard } from "../commons";
import { PAYMENT_METHOD_PSE_KEY } from "constants";
import { PAYMENT_METHOD_CARD_KEY } from "constants";

const fetcher = (requestConfig) => {
  if (requestConfig.section !== "customer") {
    return {
      data: {
        card: {
          limit_exceeded: false,
        },
        banking_correspondent: {
          show: false,
        },
      },
    };
  }
  return requestContainer(requestConfig);
};

const getAmountToPayWith = (totalAmount, paymentMethod) => {
  if (paymentMethod === "card") {
    return getAmountToPayWithCreditCard(totalAmount);
  }
  return totalAmount;
};

const usePaymentMethods = (amount = 0, hash = "", section = "") => {
  const creditCardAmount = getAmountToPayWith(amount, PAYMENT_METHOD_CARD_KEY);
  const othersAmount = getAmountToPayWith(amount, PAYMENT_METHOD_PSE_KEY);
  const bankingCorrespondentAmount = getAmountToPayWith(
    amount,
    "banking_correspondent"
  );
  const params = new URLSearchParams({
    credit_card_amount: creditCardAmount,
    others_amount: othersAmount,
    banking_correspondent_amount: bankingCorrespondentAmount,
    hash,
  });
  const result = useSWR(
    {
      method: "get",
      url: `/payment-methods?${params.toString()}`,
      section,
    },
    fetcher
  );
  return {
    isCardLimitExceeded: result.data?.card?.limit_exceeded,
    showBankingCorrespondent: result.data?.banking_correspondent?.show,
    isBankingCorrespondentLimitExceeded:
      result.data?.banking_correspondent?.limit_exceeded,
  };
};

export default usePaymentMethods;
