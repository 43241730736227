import React, { useContext, useState } from "react";
import { Box, Stack, TextField, Typography } from "@mui/material";
import { TrackJS } from "trackjs";
import { styles } from "./styles";
import { PayanaLogo } from "../../assets";
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "../../contexts/UserContext";
import { addTrackingMetadata } from "../../utils/addTrackingMetadata";
import LoadingButton from "../../commons/buttons/LoadingButton";
import { GetUserCompanies } from "services";
import { AttentionModal } from "commons";
import { BLOCKED_MESSAGE } from "constants";

export const Login = (props) => {
  const queryParameters = new URLSearchParams(window.location.search);
  const emailParam = queryParameters.get("email");
  const [email, setEmail] = useState(emailParam || null);
  const [loading, setLoading] = useState(false);
  const [pass, setPass] = useState(null);
  const [openBlockedModal, setOpenBlockedModal] = useState(false);
  const navigate = useNavigate();
  const { login, loginError } = useContext(UserContext);

  const loginUser = async () => {
    setLoading(true);
    try {
      const res = await login(email, pass);
      if (res.isBlocked) {
        setOpenBlockedModal(true);
        return;
      }

      if (res?.status !== 401) {
        TrackJS.console.info(res);
      }

      if (res.status === 200) {
        const companies = await GetUserCompanies();
        if (companies.length > 1) {
          navigate("/companies", {
            replace: true,
            state: { companies: companies },
          });
        } else {
          if (res.user.backoffice_user) {
            navigate("/backoffice-receipts", { replace: true });
          } else {
            navigate("/payments/invoices", { replace: true });
          }
        }
        addTrackingMetadata(res.user);
      }
    } catch (error) {
      if (error?.response?.status !== 401) {
        TrackJS.console.info(email);
        TrackJS.track(error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <AttentionModal
        title={BLOCKED_MESSAGE}
        onClose={() => setOpenBlockedModal(false)}
        open={openBlockedModal}
      />
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          marginTop: "-64px",
          marginLeft: "-80px",
        }}
      >
        <Stack justifyContent="center" alignItems="center" mt={9} ml={8}>
          <PayanaLogo width={190} height={42} />
          <Typography sx={styles.title} mb={4} mt={4}>
            Ingresa a tu cuenta
          </Typography>

          <Stack
            spacing={3}
            sx={{
              width: 360,
            }}
          >
            <Box>
              <Typography mb={1} sx={styles.label}>
                Correo electrónico
              </Typography>
              <TextField
                variant="outlined"
                fullWidth
                placeholder="Ingrese su email"
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                size="small"
                onKeyDown={(event) => {
                  if (event.key === "Enter" && pass && email) {
                    loginUser();
                  }
                }}
                defaultValue={emailParam ? emailParam : ""}
                sx={{
                  backgroundColor: "white",
                }}
              />
            </Box>
            <Box>
              <Stack
                mb={1}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography sx={styles.label}>Contraseña</Typography>
                <Link style={{ textDecoration: "none" }} to="/restore-password">
                  <Typography sx={styles.labelButton}>
                    Recuperar contraseña
                  </Typography>
                </Link>
              </Stack>
              <TextField
                fullWidth
                variant="outlined"
                type="password"
                placeholder="******"
                onChange={(e) => setPass(e.target.value)}
                size="small"
                onKeyDown={(event) => {
                  if (event.key === "Enter" && pass && email) {
                    loginUser();
                  }
                }}
                sx={{
                  backgroundColor: "white",
                }}
              />
            </Box>
            {loginError && (
              <Typography sx={styles.errorLabel}>
                Usuario o contraseña inválidos.
              </Typography>
            )}
            <LoadingButton
              loading={loading}
              disabled={!email || !pass}
              onClick={loginUser}
            >
              Iniciar sesión
            </LoadingButton>
            <Stack
              mt={2}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Typography sx={styles.label} mr={1}>
                ¿No tienes cuenta?
              </Typography>
              <Link style={{ textDecoration: "none" }} to="/signup">
                <Typography sx={styles.labelButton}>¡Créala gratis!</Typography>
              </Link>
            </Stack>
          </Stack>
        </Stack>
      </div>
    </>
  );
};
