const strategiesWithFallback = (fallback) => {
  const fallbackStrategy = {
    condition: () => true,
    execute: fallback,
  };
  const strategies = [];

  return {
    when(condition, strategy) {
      strategies.push({
        condition,
        execute: strategy,
      });
      return this;
    },
    execute(metadata) {
      strategies.push(fallbackStrategy);
      const strategyToExecute = strategies.find((strategyToTest) =>
        strategyToTest.condition(metadata)
      );
      return strategyToExecute.execute(metadata);
    },
  };
};

export { strategiesWithFallback };
