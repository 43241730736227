import { parse, isBefore } from "date-fns";
import { DEFAULT_PAYMENT_CARD_FEE, TRANSACTION_TYPE } from "../../../constants";

const payoutEntityNames = {
  [TRANSACTION_TYPE.SERVICE]: "servicio",
  fallback: "proveedor",
};

const getComissionLabel = (
  transaction,
  selectedRowsIds = [],
  suppliers = []
) => {
  const formatAmount = new Intl.NumberFormat("es-CO", {
    style: "currency",
    currency: "COP",
    minimumFractionDigits: 0,
  });
  const isDateBefore = (dateStr) => {
    const isoDate = new Date(dateStr);
    const limitDate = parse("21/09/2023", "dd/MM/yyyy", new Date());
    return isBefore(isoDate, limitDate);
  };
  const payoutEntityName =
    payoutEntityNames[transaction.type] || payoutEntityNames.fallback;

  if (transaction.payment_method === "card") {
    return `Comisión Tarjeta de crédito (${DEFAULT_PAYMENT_CARD_FEE}%)`;
  }
  if (transaction?.created_at && isDateBefore(transaction?.created_at)) {
    return `Comisión Payana (${formatAmount.format(
      transaction.amount_comission / selectedRowsIds.length
    )}/documento)`;
  }
  if (transaction.comission_type === "percentage") {
    return `Comisión Payana (${transaction.comission_value}%)`;
  }
  return `Comisión Payana (${formatAmount.format(
    transaction.amount_comission / suppliers.length
  )}/${payoutEntityName})`;
};

export default getComissionLabel;
