import { Box, Stack, Typography } from "@mui/material";
import { styles } from "../styles";
import { InvoicesButtonGroup } from "./InvoicesButtonGroup";
import { InfoPayment } from "../../../commons/components/InfoPayment";
import { useMediaQuery } from "react-responsive";
import { CausationBanner } from "./CausationBanner";

export const InvoicesEmptyState = (params) => {
  const isMobile = useMediaQuery({ query: "(max-width: 481px)" });

  return (
    <div
      style={{
        paddingLeft: isMobile ? "0px" : "2.5%",
        paddingRight: isMobile ? "0px" : "2.5%",
        height: "100vh",
      }}
    >
      <Box sx={isMobile ? styles.emptyStateBox : styles.newEmptyStateBox}>
        <Stack
          spacing={2}
          p={4}
          sx={isMobile ? styles.emptyStateStack : styles.newEmptyStateStack}
        >
          <Box sx={{ alignSelf: isMobile ? "center" : "flex-start" }}>
            <Typography
              sx={isMobile ? styles.emptyStateTitle : styles.newEmptyStateTitle}
            >
              Prepara tu primer pago a proveedores
            </Typography>
            <Typography
              sx={
                isMobile
                  ? styles.emptyStateSubtitle
                  : styles.newEmptyStateSubtitle
              }
            >
              Selecciona cómo ingresarás los detalles del pago y del
              destinatario.
            </Typography>
          </Box>
          <InvoicesButtonGroup
            openUploadFileModal={params.openUploadFileModal}
            openPaymentObligationModal={params.openPaymentObligationModal}
            setIsDianModalVisible={params.setIsDianModalVisible}
            setIsSiigoModalVisible={params.setIsSiigoModalVisible}
            setIsLinearProgressModalVisible={
              params.setIsLinearProgressModalVisible
            }
            setOpenModalAlegra={params.setOpenModalAlegra}
            setInitialStep={params.setInitialStep}
            setSyncBanner={params.setSyncBanner}
          />
        </Stack>
        {!isMobile && (
          <Stack spacing={2} p={4} sx={styles.newEmptyStateStack}>
            <Box sx={{ alignSelf: "flex-start" }}>
              <Typography sx={styles.newEmptyStateTitle}>
                Causa tus facturas desde Payana
              </Typography>
            </Box>
            <CausationBanner
              type={"emptyState"}
              customStyle={{
                marginLeft: "0px",
                marginRight: "0px",
                marginTop: "16px",
                width: "100%",
              }}
            />
            <InfoPayment />
          </Stack>
        )}
      </Box>
    </div>
  );
};
