import { MinusIcon, NewCheckIcon, PlusCircleIcon } from "assets";
import { Tooltip } from "commons";
import { typeInvoice } from "constants";
import { originInvoice } from "constants";
import { causationStates } from "constants";
import React from "react";

const commonStyle = {
  height: "100%",
  width: "100%",
  padding: "10px 5px",
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
};

export const CausationDetailColumn = ({ row, navigateToCausation }) => {
  const getProps = {
    causationMarkAs: (row) => {
      return {
        title: "Documento marcado como causado",
        icon: <NewCheckIcon fill="#EBE9FE" stroke="#7A5AF8" />,
        style: commonStyle,
        onClick: () => navigateToCausation(row),
      };
    },
    causationDraft: (row) => {
      return {
        title: "Realizar causación",
        icon: <PlusCircleIcon stroke="#6938EF" />,
        style: commonStyle,
        onClick: () => navigateToCausation(row),
      };
    },
    causationDone: () => {
      return {
        title: "Documento causado",
        icon: <NewCheckIcon />,
        style: commonStyle,
        onClick: () => navigateToCausation(row),
      };
    },
    invoice: () => {
      return {
        title: "Causación está deshabilitado para anticipos",
        icon: <MinusIcon />,
        style: commonStyle,
        onClick: () => {},
      };
    },
  };

  const isCollectionAccount =
    (row.type === typeInvoice.invoice && row.origin === originInvoice.dian) ||
    (row.type === typeInvoice.invoice && row.origin === originInvoice.api) ||
    row.type === typeInvoice.collectionAccount;

  const getPropsFunction = (row, isCollectionAccount) => {
    if (!isCollectionAccount) return getProps["invoice"](row);

    if (row.causation_state === causationStates.caused && !row.causation_at)
      return getProps["causationMarkAs"](row);

    return row.causation_state === causationStates.draft
      ? getProps["causationDraft"](row)
      : getProps["causationDone"](row);
  };

  const props = getPropsFunction(row, isCollectionAccount);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        cursor: "pointer",
      }}
      onClick={props.onClick}
    >
      <div
        style={{
          width: "60%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Tooltip
          title={props.title}
          sx={{ cursor: "pointer" }}
          onClick={props.onClick}
        >
          <div>
            {isCollectionAccount &&
            row.causation_state === causationStates.draft ? (
              <div>
                <PlusCircleIcon stroke="#6938EF" />
              </div>
            ) : (
              <div style={props.style}>{props.icon}</div>
            )}
          </div>
        </Tooltip>
      </div>
    </div>
  );
};
