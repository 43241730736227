import React, { useState, useContext, useEffect } from "react";
import { Box, Divider, Typography, IconButton } from "@mui/material";
import { Stack } from "@mui/system";
import { toast } from "react-toastify";
import { DeleteIcon } from "../../../assets";
import { UserContext } from "../../../contexts";
import { SecondaryButton, ThirdButton } from "../../../commons/buttons";
import { toastOptions } from "../../../constants";
import { styles } from "../styles";
import { NotificationEmailsModal } from "../../../commons";

const NotificationEmails = () => {
  const {
    notificationEmails,
    getEmailNotifications,
    addNotificationEmail,
    deleteNotificationEmail,
  } = useContext(UserContext);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    getEmailNotifications();
  }, []);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const emailExist = (email) => {
    let emailExist;
    notificationEmails.forEach((item) => {
      if (item.email === email) {
        emailExist = true;
      }
    });
    return emailExist;
  };

  const addEmail = (email) => {
    if (emailExist(email)) {
      toast.error("Este mail ya existe", toastOptions);
    } else {
      addNotificationEmail(email);
      handleCloseModal();
    }
  };

  return (
    <Box sx={styles.notificationTable}>
      <NotificationEmailsModal
        visible={openModal}
        handleClose={handleCloseModal}
        addEmail={addEmail}
      />
      <Box sx={styles.notificationContainer}>
        {notificationEmails.length > 0 && (
          <Box sx={styles.tableContainer}>
            {notificationEmails.map((email) => (
              <Box key={email.id}>
                <Stack
                  direction={"row"}
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={1}
                  my={1}
                >
                  <Typography sx={styles.emailField}>{email.email}</Typography>
                  <IconButton onClick={() => deleteNotificationEmail(email.id)}>
                    <DeleteIcon />
                  </IconButton>
                </Stack>
                <Divider />
              </Box>
            ))}
          </Box>
        )}
        <SecondaryButton
          text="Agregar email"
          width="fit-content"
          action={handleOpenModal}
        />
      </Box>
    </Box>
  );
};

export default NotificationEmails;
