export const styles = {
  screenContainer: {
    backgroundColor: "#FFFFFF",
    display: "flex",
    flexDirection: "column",
    marginBottom: "64px",
  },
  tabTitlesContainer: {
    borderBottom: 1,
    borderColor: "divider",
    padding: "0px 24px",
    margin: "20px 0px",
  },
  title: {
    fontWeight: "500",
    fontSize: "20px",
    lineHeight: "30px",
    color: "#101828",
  },
  titleContainer: {
    justifyContent: "space-between",
    display: "flex",
    marginLeft: "2.5%",
    marginRight: "2.5%",
    marginTop: "25px",
    alignItems: "center",
  },
  configModalBox: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "8px",
    boxShadow: 24,
    p: 4,
  },
  accountSysLabel: {
    border: "1px solid grey",
    borderRadius: "8px",
    margin: "10px 0px",
    justifyContent: "space-between",
    padding: "10px",
  },
  inputLabel: {
    fontSize: "14px",
    color: "#344054",
    fontWeight: "500",
    width: "280px",
    maxWidth: "280px",
  },
  inputSubLabel: {
    fontSize: "14px",
    color: "#667085",
    fontWeight: "400",
    marginTop: "4px",
    width: "200px",
    maxWidth: "200px",
  },
  remindersLabel: {
    fontSize: "14px",
    color: "#667085",
    fontWeight: "400",
    marginTop: "4px",
  },
  longInputLabel: {
    fontSize: "14px",
    color: "#344054",
    fontWeight: "500",
    width: "280px",
    alignSelf: "flex-start",
  },
  notificationTable: {
    width: "512px",
  },
  table: { width: "75%" },
  tableContainer: {
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: "8px",
    padding: "15px",
    backgroundColor: "#FFFFFF",
  },
  nameField: {
    fontWeight: "600",
    fontSize: "14px",
    textOverflow: "ellipsis",
    alignSelf: "flex-start",
    alignItems: "flex-start",
  },
  notificationContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: "20px",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: "20px",
  },
  roleText: {
    color: "#344054",
    fontSize: "14px",
    alignSelf: "center",
    alignItems: "flex-start",
  },
  emailField: {
    fontSize: "14px",
    textOverflow: "ellipsis",
    width: "40%",
  },
  errorLabel: {
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#ff0000",
  },
  divider: {
    margin: "25px 0px",
    backgroundColor: "#EAECF0",
    height: "1px",
  },
  receiverCardTitle: {
    color: "#7A2E0E",
    fontWeight: "400",
    fontSize: "14px",
  },
  emptyReceiverDataCard: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    backgroundColor: "#FEF0C7",
    borderRadius: "8px",
    paddingTop: "12px",
    paddingBottom: "12px",
    paddingLeft: "16px",
    paddingRight: "16px",
    height: "124px",
    marginBottom: "8px",
  },
  bankDataCardContainer: {
    backgroundColor: "#EFF2F6",
    borderRadius: "8px",
    borderColor: "#EFF2F6",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "12px",
  },
  bankDataCard: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  bankCardTitle: {
    color: "#667085",
    fontWeight: "400",
    fontSize: "14px",
  },
  bankCardText: {
    color: "#344054",
    fontWeight: "500",
    fontSize: "14px",
  },
  switchText: {
    fontSize: "14px",
    fontWeight: "500",
    color: "#344054",
  },
  switchHighlightedText: {
    fontSize: "14px",
    fontWeight: "600",
    color: "#3E1C96",
    backgroundColor: "#F4F3FF",
    padding: "4px 6px 4px 6px",
    borderRadius: "6px",
  },
  inputError: {
    fontSize: "14px",
    color: "#FF3252",
    fontWeight: "500",
  },
  usersScreenContainer: {
    width: "824px",
    display: "flex",
    alignSelf: "center",
    flexDirection: "column",
  },
  usersScreenMobileContainer: {
    width: "auto",
    display: "flex",
    alignSelf: "flex-start",
    flexDirection: "column",
    margin: "8px",
    paddingLeft: "8px",
    paddingRight: "8px",
  },
  subtitle: {
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "28px",
    color: "#101828",
  },
  text: {
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#475467",
  },
  mobileText: {
    fontWeight: "500",
    fontSize: "14px",
    color: "#475467",
  },
  approvalText: {
    fontWeight: "500",
    fontSize: "14px",
    color: "#475467",
    maxWidth: "721px",
  },
  userCategoryTitle: {
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#475467",
    marginBottom: "8px",
  },
  usersTableContainer: {
    borderRadius: "8px",
    border: "1px solid #D0D5DD",
    background: "#FFFFFF",
    padding: "24px",
    width: "100%",
  },
  userContainer: {
    borderRadius: "8px",
    border: "1px solid #F2F4F7",
    background: "#F2F4F7",
    padding: "16px",
    height: "85px",
    marginTop: "16px",
  },
  tutorialContainer: {
    borderRadius: "8px",
    border: "1px solid #D0D5DD",
    background: "#F2F4F7",
    padding: "32px 24px",
    gap: "32px",
    display: "flex",
    flexDirection: "column",
    marginTop: "24px",
    alignItems: "center",
  },
  initials: {
    fontWeight: "600",
    fontSize: "18px",
    color: "#344054",
  },
  tutorialTitle: {
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "28px",
    color: "#475467",
  },
  titleLink: {
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "28px",
    color: "#6938EF",
    textDecoration: "underline",
    cursor: "pointer",
    marginTop: "2px",
  },
  noPadding: {
    padding: "0px",
  },
  actionsDropdownItemText: {
    color: "#344054",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "20px",
  },
  actionsDropdownItemDeleteText: {
    color: "#B42318",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "20px",
  },
  actionsDropdownPadding: {
    padding: "2px 16px 2px 16px",
  },
  newDropdownPadding: {
    padding: "10px 16px 10px 16px",
  },
  dropdownItemContainer: {
    display: "flex",
    flexDirection: "row",
    height: "40px",
    alignItems: "center",
  },
  dropdownItemTextContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginLeft: "16px",
    textAlign: "initial",
    marginTop: "16px",
  },
};
