import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router";
import { Typography } from "@mui/material";
import {
  Table,
  TableEmptyState,
  TableSkeleton,
  NotifyWompiModal,
} from "commons";
import { styles } from "./styles";
import { PaymentsContext, UserContext, TransactionsContext } from "contexts";
import { useAbortableRequest, useChat } from "hooks";
import { UiContext } from "contexts/UiContext";
import { GetTotalTransactions, GetTransaction } from "../../services";
import { TransactionsDetails } from "commons/modals/TransactionDetails";
import { SubscriptionModal } from "commons/modals";
import { TRANSACTION_TYPE } from "constants";
import { Box } from "@mui/material";
import { useMediaQuery } from "react-responsive";
import CheckoutModal from "commons/modals/CheckoutModal";

export const ListPage = ({
  pageEmptyState = null,
  section,
  url = "",
  renderRowEndButtons = () => null,
  queries = {},
  tableColumns = () => [],
  renderAutocomplete = () => null,
  pageTitle = "",
  useCheckboxes = false,
  expandLink,
  renderPageFooter = () => null,
  preventListRefreshWhen = () => false,
  isChecked,
  toggleSelectedRow,
  toggleSelectedAll,
  getTableData = (response) => response?.data,
  buttons,
  renderStatusFilters = () => null,
  StatusFilters = () => null,
  subNavigation = null,
}) => {
  const entityType = {
    serviceTrx: TRANSACTION_TYPE.INVOICE,
    services: TRANSACTION_TYPE.INVOICE,
  };
  const [isPageInitialLoading, setIsPageInitialLoading] = useState(true);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const [totals, setTotals] = useState();
  const [tableData, setTableData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedTrx, setSelectedTrx] = useState();
  const [detailsModalIsOpen, setDetailModalIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [transaction, setTransaction] = useState({});
  const [trxRows, setTrxRows] = useState([]);
  const [sourceOfFunds, setSourceOfFunds] = useState({});
  const [sourceOfFundsModal, setSourceOfFundsModal] = useState(false);
  const { subscription } = useContext(PaymentsContext);
  const { getTransactions } = useContext(TransactionsContext);
  const {
    canPayWithCobre,
    displayCobreAlert,
    sourceOfFundsNeeded,
    currentUser,
  } = useContext(UserContext);
  const [wompiModalProps, setWompiModalProps] = useState({
    visible: false,
    totalAmount: 0,
    selectedRowsIds: [],
  });
  const [searchInputText, setSearchInputText] = useState("");
  const queryParameters = new URLSearchParams(window.location.search);
  const id = queryParameters.get("id");
  const [notifyWompiModalIsVisible, setNotifyWompiModalIsVisible] =
    useState(id);
  const [limitExceededAlert, setLimitExceededAlert] = useState({});
  const isMobile = useMediaQuery({ query: "(max-width: 481px)" });

  const {
    statusSelected,
    setStatusSelected,
    perPageSelected,
    setPerPageSelected,
    invoicesScreen,
    tagsSelected,
  } = useContext(PaymentsContext);
  const providerIdSelected = invoicesScreen?.providerIdSelected?.value;
  const serviceIdSelected = invoicesScreen?.serviceIdSelected?.value;
  const {
    hooks: { setOpenModalKeys, setClosedModalKeys },
  } = useContext(UiContext);
  const pageIndex = invoicesScreen?.pageIndex;
  const setPageIndex = invoicesScreen?.setPageIndex;

  useChat();

  useEffect(() => {
    if (currentUser.backoffice_user) {
      navigate("/backoffice-receipts", { replace: true });
    }
  }, [currentUser, navigate]);

  const query = new URLSearchParams(queries).toString();

  useAbortableRequest({
    requestConfig: {
      url: `${url}?${query}`,
    },
    preventRequest: preventListRefreshWhen,
    onInitRequest: () => {
      setLoading(true);
    },
    onAbortRequest: () => {},
    requestThen: (response) => {
      setTableData(getTableData(response));
      setLoading(false);
      setIsPageInitialLoading(false);
    },
  });

  const emptyState =
    !loading &&
    tableData.length === 0 &&
    (!providerIdSelected || providerIdSelected === "all") &&
    (!serviceIdSelected || serviceIdSelected === "all") &&
    (!tagsSelected || tagsSelected.length === 0);

  const closeAndReset = () => {
    setDetailModalIsOpen(false);
    getTransactions(pageIndex, entityType[section]);
  };

  const refreshTransactionsTable = () => {
    getTransactions(pageIndex, entityType[section], searchInputText);
    GetTotalTransactions({ text: searchInputText }).then((res) => {
      setTotals(res);
    });
  };

  const openDetailsModal = (id) => {
    setSelectedTrx(id);
    setDetailModalIsOpen(true);
    setIsLoading(true);
    GetTransaction({ id: id })
      .then((res) => {
        setTransaction(res.transactions.transaction);
        setTrxRows(res.transactions.rows);
        setSourceOfFunds(res.transactions.source);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const closeWompiModal = () => {
    setWompiModalProps({
      ...wompiModalProps,
      visible: false,
    });
    refreshTransactionsTable();
  };
  const closeNotifyWompiModal = (id) => {
    setNotifyWompiModalIsVisible(false);
    getTransactions(pageIndex, entityType[section]);
    navigate("/transactions", {
      replace: true,
    });
  };
  const openWompiModal = ({
    visible,
    totalAmount,
    selectedRowsIds,
    selectedSuppliers,
  }) =>
    setWompiModalProps({
      visible: visible,
      totalAmount: totalAmount,
      selectedRowsIds: selectedRowsIds,
      selectedSuppliers: selectedSuppliers,
    });

  useEffect(() => {
    if (wompiModalProps.visible) {
      setDetailModalIsOpen(false);
    }
  }, [wompiModalProps]);

  const payoutsNumber = wompiModalProps?.selectedSuppliers?.length;

  return (
    <>
      {subscription?.showModalSubscription && (
        <SubscriptionModal subscription={subscription} />
      )}
      <NotifyWompiModal
        visible={notifyWompiModalIsVisible}
        handleClose={closeNotifyWompiModal}
      />
      <CheckoutModal
        handleClose={closeWompiModal}
        canPayWithCobre={canPayWithCobre}
        selectedId={transaction.source_of_funds_id}
        displayCobreAlert={displayCobreAlert}
        redirectUrl={`${process.env.REACT_APP_BASE_URL}/transactions`}
        type={TRANSACTION_TYPE.SERVICE}
        trxId={selectedTrx}
        setLimitExceededAlert={setLimitExceededAlert}
        {...wompiModalProps}
        payoutsNumber={payoutsNumber}
        sourceOfFundsNeeded={sourceOfFundsNeeded}
      />
      <TransactionsDetails
        subscription={subscription}
        isLoading={isLoading}
        transaction={transaction}
        trxRows={trxRows}
        sourceOfFunds={sourceOfFunds}
        visible={detailsModalIsOpen}
        setSourceOfFundsModal={setSourceOfFundsModal}
        onClose={closeAndReset}
        id={selectedTrx}
        sourceOfFundsActive={true}
        openWompiModal={openWompiModal}
        type={entityType[section]}
        closeDrawer={() => {
          setDetailModalIsOpen(false);
        }}
      />
      <div
        style={{
          ...styles.container,
          ...(isMobile && {
            marginRight: "24px",
            marginLeft: "24px",
          }),
        }}
        id="page-wrap"
      >
        {isPageInitialLoading ? (
          <div
            style={{ backgroundColor: "#F6F7F9" }}
            className="fade-in-with-translation"
          >
            <TableSkeleton />
          </div>
        ) : emptyState ? (
          pageEmptyState
        ) : (
          <>
            <div
              className="layout-container"
              style={{
                maxWidth: "100%",
                margin: "auto",
              }}
            >
              <div style={styles.titleContainer}>
                <Typography sx={styles.title} noWrap>
                  {pageTitle}
                </Typography>
                {!loading && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                    }}
                  >
                    {buttons}
                  </div>
                )}
              </div>
              {subNavigation}
              {emptyState ? null : isMobile ? (
                StatusFilters
              ) : (
                <div className="invoices-data-filters">
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "baseline",
                      margin: "0px 2.5%",
                      justifyContent: "space-between",
                      flex: "0 0 auto",
                    }}
                  >
                    {StatusFilters}
                  </Box>
                </div>
              )}

              <Table
                loading={loading}
                loadingSkeleton={<TableSkeleton />}
                pageNumber={pageIndex}
                columns={tableColumns()}
                data={tableData}
                amountModal
                expandIcon
                expandLink={expandLink}
                setSelectedRows={() => {}}
                setPageIndex={() => {}}
                checkboxVisible={useCheckboxes}
                pageCount={totalPages}
                section={section}
                expandAction={openDetailsModal}
                moneyIcon
                hasFileIcon
                toggleSelectedRow={toggleSelectedRow}
                isChecked={isChecked}
                renderRowEndButtons={(row) =>
                  renderRowEndButtons(row, setOpenModalKeys, setClosedModalKeys)
                }
                toggleSelectedAll={toggleSelectedAll}
                perPage={perPageSelected}
                setPerPage={setPerPageSelected}
                perPageVisible={true}
                statusSelected={statusSelected}
                providerIdSelected={providerIdSelected}
                serviceIdSelected={serviceIdSelected}
                onClickRow={() => {}}
                renderAutocomplete={renderAutocomplete}
                isCausationAvailable={false}
                tagsSelected={tagsSelected}
                emptyState={
                  <TableEmptyState
                    title={"No se encontraron resultados para esta búsqueda"}
                    subtitle={"Por favor intente nuevamente"}
                  />
                }
              />
            </div>
          </>
        )}
      </div>
      {!loading &&
        !emptyState &&
        statusSelected !== "paid" &&
        renderPageFooter(setOpenModalKeys, setClosedModalKeys)}
    </>
  );
};
