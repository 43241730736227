import { PrimaryButton } from "commons/buttons";
import { DebtListItem } from "./DebtListItem";
import { DebtListEmptyState } from "./DebtListEmptyState";
import { useState, useEffect } from "react";
import { CantRegisterServiceModal } from "commons/modals/CantRegisterServiceModal";

const styles = {
  container: {
    minWidth: 405,
    display: "flex",
    flexDirection: "column",
  },
  blurContainer: {
    transition: "filter 0.3s ease",
  },
  blurred: {
    filter: "blur(4px)",
  },
  title: {
    color: "#1D2939",
    fontSize: "18px",
    fontWeight: "600",
    lineHeight: "28px",
    marginBottom: "32px",
  },
  buttonContainer: {
    marginBottom: "32px",
  },
};

export const DebtsList = ({
  isLoading,
  debts,
  service,
  identifierValue,
  isChecked,
  toggleSelectedRow,
  addSelectedRow,
  removeSelectedRow,
  backToServiceCompanySearch,
  serviceRegistered,
  modalityType,
}) => {
  const [isCantRegisterServiceModal, setIsCantRegisterServiceModal] =
    useState(false);
  const cantRegisterIsolatedCases = ["CO-S-07623"];

  const showCantRegisterServiceModal =
    isCantRegisterServiceModal && debts && debts.length > 0;

  useEffect(() => {
    if (
      modalityType === "barcode" ||
      cantRegisterIsolatedCases.includes(service.code)
    ) {
      setIsCantRegisterServiceModal(true);
    }
  }, [modalityType, service.code]);

  const className = isLoading
    ? " delayed-fade-in-with-translation"
    : " fade-out-with-translation";
  if (!isLoading && debts && debts.length === 0) {
    return (
      <>
        {serviceRegistered.service && (
          <CantRegisterServiceModal
            visible={showCantRegisterServiceModal}
            handleClose={() => setIsCantRegisterServiceModal(false)}
          />
        )}
        <div
          style={{
            ...styles.blurContainer,
            ...(serviceRegistered.service && showCantRegisterServiceModal
              ? styles.blurred
              : {}),
          }}
        >
          <DebtListEmptyState
            backToServiceCompanySearch={backToServiceCompanySearch}
            serviceRegistered={serviceRegistered}
          />
        </div>
      </>
    );
  }
  if (isLoading) {
    return (
      <div
        style={styles.container}
        className={`debts-search-container-loading${className}`}
      >
        <div style={styles.title}>Buscando...</div>
        <DebtListItem loading />
        <DebtListItem loading />
      </div>
    );
  }
  return (
    <>
      <CantRegisterServiceModal
        visible={showCantRegisterServiceModal}
        handleClose={() => setIsCantRegisterServiceModal(false)}
      />
      <div
        style={{
          ...styles.blurContainer,
          ...(showCantRegisterServiceModal ? styles.blurred : {}),
        }}
      >
        <div style={styles.container}>
          <div style={styles.title}>
            Encontramos las siguientes facturas por pagar
          </div>
          <div style={styles.buttonContainer}>
            <PrimaryButton
              text="Revisar información"
              action={backToServiceCompanySearch}
              width="150px"
            />
          </div>
          {debts.map((debt) => {
            return (
              <DebtListItem
                addSelectedRow={addSelectedRow}
                removeSelectedRow={removeSelectedRow}
                key={debt.debtId}
                debt={debt}
                serviceCompanyName={service.name}
                serviceCompanyLogoUrl={service.logoUrl}
                identifierValue={identifierValue}
                isChecked={isChecked}
                toggleSelectedRow={toggleSelectedRow}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};
