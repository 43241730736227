import React from "react";

export const WarningIcon = ({ width = 44, height = 44, showRect = true }) => (
  <svg width={width} height={height} viewBox="0 0 66 66" fill="none">
    <rect x="5" y="5" width="56" height="56" rx="28" fill="#FEF0C7" />
    <path
      d="M33 28.3334V33M33 37.6667H33.0117M44.6667 33C44.6667 39.4434 39.4433 44.6667 33 44.6667C26.5567 44.6667 21.3333 39.4434 21.3333 33C21.3333 26.5567 26.5567 21.3334 33 21.3334C39.4433 21.3334 44.6667 26.5567 44.6667 33Z"
      stroke="#DC6803"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    {showRect && (
      <rect
        x="5"
        y="5"
        width="56"
        height="56"
        rx="28"
        stroke="#FFFAEB"
        strokeWidth="10"
      />
    )}
  </svg>
);
