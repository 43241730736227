import {
  DRAFT,
  OUTSTANDING_BALANCE,
  PAID_OUTSIDE,
  PENDING,
  SENT,
} from "../constants";

export const isRowEnabled = (section, row) => {
  const isCustomerSection =
    section === "customer" && row.values.status === SENT;
  const isCollectionsSection =
    section === "collections" &&
    (row.values.status === PENDING || row.values.status === SENT);
  const isCausationSection =
    section === "causation" && row.values.causation_state === DRAFT;
  const isPending = row.values.status === PENDING;
  const isOutstandingBalance = row.values.status === OUTSTANDING_BALANCE;
  const isPaidOutside = row.values.status === PAID_OUTSIDE;
  const isServicesTransactionDeleted =
    section === "services" && row.original.transaction_deleted_at;
  return (
    isServicesTransactionDeleted ||
    isCustomerSection ||
    isCollectionsSection ||
    isCausationSection ||
    isPending ||
    isOutstandingBalance ||
    isPaidOutside
  );
};
