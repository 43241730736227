import React from "react";

export const FilterIcon = ({ stroke = "#667085" }) => (
  <svg
    width="18"
    height="12"
    viewBox="0 0 18 12"
    fill="none"
    style={{ paddingTop: "2px", alignSelf: "center" }}
  >
    <path
      d="M4 6H14M1.5 1H16.5M6.5 11H11.5"
      stroke={stroke}
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
