import React from "react";
import { styles } from "../styles";
import { PaperclipIcon } from "assets";
import { Tooltip } from "commons/components";

export const InvoiceNumberRow = ({ cell }) => {
  const row = cell.row.original;

  return (
    <div
      style={{
        ...styles.tableRow,
        gap: "8px",
      }}
    >
      <p
        style={{
          ...styles.revert,
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          maxWidth: "90px",
        }}
      >
        {row.invoice_number?.length > 12 ? (
          <Tooltip title={row.invoice_number}>
            <span>{row.invoice_number}</span>
          </Tooltip>
        ) : (
          <span>{row.invoice_number}</span>
        )}
      </p>
      {row.file_path && (
        <Tooltip title="Este documento tiene un archivo adjunto">
          <span>
            <PaperclipIcon />
          </span>
        </Tooltip>
      )}
    </div>
  );
};
