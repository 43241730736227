import React, { useContext, useEffect, useMemo, useState } from "react";
import * as amplitude from "@amplitude/analytics-browser";
import { styles } from "./styles";
import {
  GetCollectionTransaction,
  GetTotalTransactions,
  SearchCustomers,
} from "../../services";
import { TransactionsContext, UserContext } from "../../contexts";
import {
  Autocomplete,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import {
  DeleteItemModal,
  NotifyWompiModal,
  Submenu,
  Table,
  TableSkeleton,
  PaymentModal,
} from "../../commons";
import {
  collectionsMenuScreens,
  collectionsTransactionsColumns,
  filterOptions,
} from "../../utils";
import { TableEmptyState } from "../../commons/Layout";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { SearchIcon } from "../../assets";
import { useChat } from "../../hooks";
import { TransactionsDetails } from "commons/modals/TransactionDetails";

export const CollectionsTransactionsScreen = (props) => {
  const location = useLocation();
  const [pageIndex, setPageIndex] = useState(1);
  const [totals, setTotal] = useState();
  const [totalPages, setTotalPages] = useState(1);
  const {
    collectionTransactions,
    deletePendingCollectionTransaction,
    getCollectionTransactions,
  } = useContext(TransactionsContext);
  const { canPayWithCobre, displayCobreAlert, currentUser } =
    useContext(UserContext);
  const [deleteItemModalIsVisible, setDeleteItemModalIsVisible] =
    useState(false);
  const [itemToDelete, setItemToDelete] = useState();
  const [itemToClone, setItemToClone] = useState();
  const queryParameters = new URLSearchParams(window.location.search);
  const id = queryParameters.get("id");
  const [notifyWompiModalIsVisible, setNotifyWompiModalIsVisible] =
    useState(id);
  const [detailsModalIsOpen, setDetailModalIsOpen] = useState(false);
  const [selectedTrx, setSelectedTrx] = useState();
  const [wompiModalProps, setWompiModalProps] = useState({
    visible: false,
    totalAmount: 0,
    selectedRowsIds: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [transaction, setTransaction] = useState({});
  const [trxRows, setTrxRows] = useState([]);
  const [searchOptions, setSearchOptions] = useState([]);
  const [customerIdSelected, setCustomerIdSelected] = useState("all");
  const [loading, setLoading] = useState(true);
  const isMobile = useMediaQuery({ query: "(max-width: 481px)" });

  const getSearchOptions = (query) => {
    SearchCustomers(query).then((res) => {
      setSearchOptions(res);
    });
  };

  const columns = useMemo(() => collectionsTransactionsColumns(), []);

  useChat();

  const openDetailsModal = (id) => {
    setSelectedTrx(id);
    setDetailModalIsOpen(true);
    setIsLoading(true);
    GetCollectionTransaction({ id: id })
      .then((res) => {
        setTransaction(res.transactions.transaction);
        setTrxRows(res.transactions.rows);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const refreshTransactionsTable = () => {
    getCollectionTransactions(pageIndex, customerIdSelected).finally(() => {
      setLoading(false);
    });
    GetTotalTransactions({
      type: "collection",
      customerId: customerIdSelected,
    }).then((res) => {
      setTotal(res);
    });
  };

  useEffect(() => {
    amplitude.logEvent("VIEW_LOTS_TAB", {
      user_id: currentUser.id,
      module: "cobros",
    });
    refreshTransactionsTable();
    location.state &&
      location.state.openDetail &&
      openDetailsModal(location.state.openDetail);
  }, [pageIndex, customerIdSelected]);

  useEffect(() => {
    if (totals) {
      if (totals.total > 100) {
        setTotalPages(Math.ceil(totals.total / 100));
      } else {
        setTotalPages(1);
      }
    }
  }, [totals]);

  const closeDeleteItemModal = () => setDeleteItemModalIsVisible(false);
  const openDeleteItemModal = (id) => {
    setItemToDelete(id);
    setDeleteItemModalIsVisible(true);
  };
  const closeNotifyWompiModal = (id) => {
    setNotifyWompiModalIsVisible(false);
    getCollectionTransactions(pageIndex);
    openDetailsModal(id);
  };

  const openWompiModal = ({ visible, totalAmount, selectedRowsIds }) =>
    setWompiModalProps({
      visible: visible,
      totalAmount: totalAmount,
      selectedRowsIds: selectedRowsIds,
    });

  const closeWompiModal = () => {
    setWompiModalProps({
      ...wompiModalProps,
      visible: false,
    });
    refreshTransactionsTable();
  };

  useEffect(() => {
    if (wompiModalProps.visible) {
      setDetailModalIsOpen(false);
    }
  }, [wompiModalProps]);

  const handleSearchChange = (customer) => {
    customer?.id
      ? setCustomerIdSelected(customer?.id)
      : setCustomerIdSelected("all");
    setPageIndex(1);
  };

  const renderAutocomplete = () => {
    return (
      <Autocomplete
        options={searchOptions}
        size="small"
        sx={{ width: isMobile ? "100%" : "35%", minWidth: "164px" }}
        freeSolo
        filterOptions={filterOptions}
        getOptionLabel={(option) => {
          return option?.name || "";
        }}
        onChange={(event, option) => {
          if (option?.id) {
            handleSearchChange(option);
          } else {
            handleSearchChange();
          }
        }}
        onInputChange={(event) => {
          if (event?.target?.value?.length >= 3) {
            getSearchOptions(event.target.value);
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              style: { fontSize: 14 },
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            placeholder={"Buscar por cliente"}
          />
        )}
      />
    );
  };

  return (
    <>
      <NotifyWompiModal
        visible={notifyWompiModalIsVisible}
        handleClose={closeNotifyWompiModal}
        type="collection"
      />
      {wompiModalProps.visible && (
        <PaymentModal
          handleClose={closeWompiModal}
          redirectUrl={`${process.env.REACT_APP_BASE_URL}/transactions`}
          type="collection"
          trxId={selectedTrx}
          canPayWithCobre={canPayWithCobre}
          displayCobreAlert={displayCobreAlert}
          {...wompiModalProps}
          payoutsNumber={wompiModalProps?.selectedRowsIds?.length}
        />
      )}
      <DeleteItemModal
        itemId={itemToDelete}
        action={deletePendingCollectionTransaction}
        handleClose={closeDeleteItemModal}
        title="¿Quieres borrar esta transacción pendiente de pago?"
        subtitle="Si la eliminas, no aparecerá más en Payana"
        visible={deleteItemModalIsVisible}
      />
      {detailsModalIsOpen && (
        <TransactionsDetails
          visible={detailsModalIsOpen}
          onClose={() => setDetailModalIsOpen(false)}
          id={selectedTrx}
          type={"collections"}
          openWompiModal={openWompiModal}
          isLoading={isLoading}
          transaction={transaction}
          trxRows={trxRows}
        />
      )}
      <div>
        <div className="layout-container">
          <div style={styles.titleContainer}>
            <Typography sx={styles.title} noWrap>
              Lotes
            </Typography>
          </div>
          {loading ? (
            <TableSkeleton />
          ) : (
            <Table
              columns={columns}
              data={collectionTransactions}
              setPageIndex={setPageIndex}
              section="collectionTrx"
              moneyIcon
              expandIcon
              expandAction={openDetailsModal}
              checkboxVisible={false}
              pageCount={totalPages}
              deleteIcon
              openDeleteItemModal={openDeleteItemModal}
              renderAutocomplete={renderAutocomplete}
              clearSearchText={() => setCustomerIdSelected("all")}
              emptyState={
                <TableEmptyState
                  title={"No se encontraron resultados para esta búsqueda"}
                  subtitle={"Aquí se mostrarán los cobros que realices."}
                />
              }
            />
          )}
        </div>
      </div>
    </>
  );
};
