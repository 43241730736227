import Switch from "react-ios-switch";
import { AddresseeIcon, ArrowRightIcon, InfoIcon } from "assets";
import styles from "./styles.module.css";
import { Link } from "react-router-dom";

const DisabledReceivers = () => {
  return (
    <div className={styles["disabled-receivers-container"]}>
      <div>
        <div className={styles["disabled-receivers-alert-container"]}>
          <div>
            <InfoIcon color="#475467" />
          </div>
          <div>
            La funcionalidad de agregar destinatario no es compatible con pagos
            parciales y/o retenciones
            <div
              className={styles["disabled-receivers-alert-configuration-link"]}
            >
              <Link
                to="/settings"
                className={
                  styles["disabled-receivers-alert-configuration-link-a"]
                }
              >
                Ir a configuración <ArrowRightIcon color="#667085" />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className={styles["disabled-receivers-alert-receivers"]}>
        <AddresseeIcon color="#5E687D" background="#DEE2E8" />
        <span>Destinatario</span>
      </div>
      <div className={styles["disabled-receivers-switch-container"]}>
        <Switch
          disabled
          handleColor="white"
          offColor="#D0D5DD"
          readOnly
          onColor="#667085"
          style={{ transform: "scale(0.6)", fontSize: "14px" }}
        />
        <span>Habilitar destinatario</span>
      </div>
    </div>
  );
};

export default DisabledReceivers;
