import React from "react";
import { styles } from "../styles";
import { CloseIcon, StarIcon } from "assets";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getWppLink, openInNewTab } from "utils";

export const CausationBanner = ({
  saveInLocalStorage = () => {},
  setCausationBannerVisible = () => {},
  type,
  customStyle = {},
}) => {
  const navigate = useNavigate();

  const phoneNumber = "+573103619890";
  const message = "¡Hola! Necesito ayuda pagando mis documentos causados.";

  const title = {
    siigo: (
      <Typography sx={styles.causationBannerTitle}>
        ¡Causa tus facturas automáticamente en Siigo!
      </Typography>
    ),
    dian: (
      <Typography sx={styles.causationBannerTitle}>
        ¿Tienes Siigo como sistema contable?
      </Typography>
    ),
    xselling: (
      <Typography sx={styles.causationBannerTitle}>
        Ya aprendiste a causar, ahora realiza tus pagos{" "}
        <strong>
          ahorrando el 100% de tus comisiones bancarias y registrando de forma
          automática tus egresos en Siigo.
        </strong>
      </Typography>
    ),
    emptyState: (
      <Typography sx={styles.causationBannerTitle}>
        Causación en Siigo Nube
      </Typography>
    ),
  };

  const description = {
    siigo: <></>,
    dian: (
      <Typography sx={styles.causationBannerDescription}>
        Ahora puedes causar tus facturas desde nuestra plataforma.
      </Typography>
    ),
    xselling: (
      <Typography sx={styles.causationBannerDescription}>
        ¿Necesitas ayuda?{" "}
        <span
          onClick={() => openInNewTab(getWppLink(phoneNumber, message))}
          style={styles.link}
        >
          Envía aquí tu consulta
        </span>
      </Typography>
    ),
    emptyState: <></>,
  };

  const iconSize = {
    siigo: "42",
    dian: "42",
    xselling: "48",
    emptyState: "42",
  };

  return (
    <div style={{ ...styles.causationBannerContainer, ...customStyle }}>
      <div style={{ ...styles.row, gap: "12px" }}>
        <div
          style={{
            width: "24px",
            height: "24px",
            marginRight: type !== "xselling" ? "8px" : "18px",
            marginTop: type !== "xselling" ? "-8px" : "0px",
          }}
        >
          <StarIcon size={iconSize[type]} />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: type === "xselling" ? "column" : "row",
            gap: "4px",
            marginTop: "2px",
          }}
        >
          {title[type]}
          {description[type]}
        </div>
      </div>
      <div style={{ ...styles.row, gap: "8px" }}>
        {type !== "xselling" && (
          <div
            style={styles.causationBannerAction}
            onClick={() => {
              navigate("/payments/causation", {
                state: {
                  causationComingSoon: true,
                  type: type,
                },
                replace: true,
              });
            }}
          >
            <Typography style={styles.causationBannerLink}>
              {type === "emptyState" ? "Empezar ahora" : "Ver más"}
            </Typography>
          </div>
        )}
        {type !== "emptyState" && (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              saveInLocalStorage("isCausationBannerVisible", "false");
              setCausationBannerVisible(false);
            }}
          >
            <CloseIcon color={"#667085"} />
          </div>
        )}
      </div>
    </div>
  );
};
