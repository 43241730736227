import { SecondaryButton } from "commons/buttons";
import { CausationContext } from "contexts";
import React, { useContext, useState } from "react";
import { INITIAL_TAX } from "../constants";
import { RollbackIcon, SigmaIcon } from "assets";

export const ItemTotalizer = ({ handleSetItems, data }) => {
  const { originalItems } = useContext(CausationContext);
  const [isBackToOriginalItems, setIsBackToOriginalItems] = useState(false);

  const handleBackToOriginalItems = () => {
    handleSetItems(originalItems);
  };

  const totalizeItems = (items) => {
    const totalizedItems = items.reduce((acc, item) => {
      const key = item.taxIvaPercentage;
      if (!acc[key]) {
        acc[key] = {
          id: item.id,
          discount: 0,
          quantity: 1,
          taxIva: INITIAL_TAX,
          product: item?.product || {},
          unitValue: 0,
          warehouse: null,
          description: item?.description,
          taxRetefuente: INITIAL_TAX,
          taxIvaPercentage: item?.taxIvaPercentage,
          isSelected: false,
          type: null,
        };
      }

      acc[key].unitValue += item.quantity * item.unitValue;
      acc[key].amountTotal = acc[key].unitValue;
      acc[key].discount += item.discount;
      acc[key].amountTotal = acc[key].unitValue;

      return acc;
    }, {});

    return Object.values(totalizedItems);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "95%",
        margin: "4px auto",
      }}
    >
      <p>Items</p>
      <SecondaryButton
        icon={isBackToOriginalItems ? <RollbackIcon /> : <SigmaIcon />}
        text={isBackToOriginalItems ? "Separar ítems" : "Totalizar ítems"}
        action={() => {
          if (isBackToOriginalItems) {
            handleBackToOriginalItems();
            setIsBackToOriginalItems(false);
          } else {
            const totalizedItems = totalizeItems(data);
            handleSetItems(totalizedItems);
            setIsBackToOriginalItems(true);
          }
        }}
        width="fit-content"
        innerStyle={{
          gap: "8px",
        }}
      />
    </div>
  );
};
