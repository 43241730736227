import { MenuItem, Select, TextField } from "@mui/material";
import { Stack } from "@mui/system";
import { styles } from "../styles";
import { ValidationMessage } from "../../../commons/components";
import { documentTypes } from "../../../constants";

const idTypeWordings = {
  CC: "Cédula de ciudadanía",
  CE: "Cédula de extranjería",
  TI: "Tarjeta de identidad",
  PPT: "Permiso por protección temporal",
};

const LegalRepresentativeForm = ({
  formValues,
  register,
  formState,
  isDianActived,
  setSaveSettingsButtonIsDisabled = () => {},
}) => {
  return (
    <Stack flexGrow={1} gap="16px">
      <Stack spacing={1}>
        <label htmlFor="documentType" style={styles.inputLabel}>
          Tipo de identificación
        </label>
        <Select
          size="small"
          variant="outlined"
          value={formValues.documentType}
          inputProps={{
            id: "documentType",
            style: { fontSize: 14 },
          }}
          sx={{ width: "512px", backgroundColor: "#FFFFFF", fontSize: 14 }}
          {...register("documentType", {
            required: isDianActived,
            onChange: () => setSaveSettingsButtonIsDisabled(false),
          })}
        >
          {documentTypes.map((type) => {
            return (
              <MenuItem key={type} value={type} sx={{ fontSize: 14 }}>
                {idTypeWordings[type] || type}
              </MenuItem>
            );
          })}
        </Select>
        <ValidationMessage errors={formState.errors} field="documentType" />
      </Stack>
      <Stack spacing={1}>
        <label htmlFor="documentNumber" style={styles.inputLabel}>
          NIT Representante Legal
        </label>
        <TextField
          id="documentNumber"
          size="small"
          variant="outlined"
          {...register("documentNumber", {
            required: isDianActived,
            onChange: () => setSaveSettingsButtonIsDisabled(false),
          })}
          value={formValues.documentNumber}
          sx={{ width: "512px", backgroundColor: "#FFFFFF" }}
          InputProps={{ style: { fontSize: 14 } }}
        />
        <ValidationMessage errors={formState.errors} field="documentNumber" />
      </Stack>
    </Stack>
  );
};

export default LegalRepresentativeForm;
