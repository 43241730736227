import { IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import { EditIcon, NewIcon } from "../../../assets";
import { styles } from "../styles";
import { useIsUserAllowedTo } from "utils";
import { permissions } from "constants";
import { Tooltip } from "commons/components";

export const CustomerRow = ({
  cell,
  isIncomplete,
  linkTo,
  itemId,
  isNew,
  setDrawerIsOpen,
  className,
}) => {
  const { isUserAllowedTo } = useIsUserAllowedTo();

  const completeCustomerInformation = (e) => {
    e.stopPropagation();
    setDrawerIsOpen(cell.row.original.customer_id);
  };

  return (
    <div
      style={{
        ...styles.row,
        justifyContent: "flex-start",
        alignItems: "center",
        maxWidth: "200px",
      }}
    >
      <p
        title={cell.value}
        className={`limit-text incompleted-provider-indicator ${
          cell.value !== null ? className : ""
        }`}
        style={{
          color: isIncomplete ? "" : "#475467",
          margin: "revert",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div className="limit-text" style={{ maxWidth: "280px" }}>
          {cell.value !== null ? cell.value.toUpperCase() : "NO INFORMADO"}
        </div>
        {isNew && (
          <div style={{ marginLeft: "8px" }}>
            <NewIcon />
          </div>
        )}
      </p>
      {isUserAllowedTo(permissions.COLLECTIONS_UPDATE) &&
        isIncomplete &&
        cell.row.original.customer_id && (
          <Tooltip title="Completa la información de tu cliente">
            <span
              onClick={(e) => completeCustomerInformation(e)}
              className="incompleted-provider-indicator"
              style={{
                textTransform: "none",
                display: "flex",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    borderRadius: 26,
                    textWrap: "nowrap",
                    padding: "4px 8px",
                    marginRight: 12,
                    background: "#FEF0C7",
                    display: "flex",
                    gap: 6,
                  }}
                >
                  <span>Falta información</span>
                  <EditIcon color={isIncomplete ? "#B54708" : "#000000"} />
                </div>
              </div>
            </span>
          </Tooltip>
        )}
      {isUserAllowedTo(permissions.COLLECTIONS_UPDATE) &&
        isIncomplete &&
        !cell.row.original.customer_id && (
          <Link to={linkTo + itemId}>
            <IconButton>
              <EditIcon />
            </IconButton>
          </Link>
        )}
    </div>
  );
};
