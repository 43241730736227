export const styles = {
  title: {
    fontWeight: "500",
    fontSize: "20px",
    lineHeight: "30px",
    color: "#101828",
  },
  titleContainer: {
    justifyContent: "space-between",
    display: "flex",
    marginLeft: "2.5%",
    marginRight: "2.5%",
    marginTop: "12px",
    alignItems: "center",
  },
  emptyStateTitle: {
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "20px",
    textAlign: "center",
  },
  emptyStateSubtitle: {
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "20px",
    textAlign: "center",
  },
  emptyStateButtonTitle: {
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "20px",
  },
  emptyStateButtonSubtitle: {
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "20px",
  },
  footer: {
    backgroundColor: "#FFFFFF",
    borderTop: "1px solid #EAECF0",
    position: "fixed",
    width: "100%",
    bottom: "0",
    color: "white",
    fontSize: "25px",
    boxShadow: "0px -6px 16px -4px rgba(16, 24, 40, 0.08)",
    height: "64px",
    marginLeft: "auto",
    marginRight: "auto",
    justifyContent: "flex-end",
    display: "flex",
    alignItems: "center",
  },
  footerElements: {
    marginLeft: "auto",
    marginRight: "auto",
    width: "95%",
    justifyContent: "space-between",
    display: "flex",
    alignItems: "center",
  },
  footerText: {
    color: "#000000",
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: "24px",
    marginRight: "12px",
    display: "flex",
    alignSelf: "center",
    marginTop: "auto",
    marginBottom: "auto",
  },
  box: {
    backgroundColor: "#F6F7F9",
    minHeight: "400px",
    display: "flex",
    justifyContent: "center",
  },
  stack: {
    maxWidth: "500px",
    alignItems: "center",
    width: "90%",
    padding: "32px 0px",
  },
  btnInGroup: {
    padding: "14px 16px",
    border: "1px solid #EAECF0",
    background: "#FFF",
    textTransform: "inherit",
    textAlign: "inherit",
    justifyContent: "flex-start",
    color: "#344054",
    WebkitTransition: "none",
    transition: "none",
    "&:hover": {
      border: "1px solid #EAECF0",
    },
    "& .MuiButton-startIcon": {
      borderRadius: "4px",
      background: "#FFF",
      boxShadow: "0px 2px 8px 0px rgba(3, 7, 18, 0.10)",
      margin: "0px",
      height: "32px",
      width: "32px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
};
