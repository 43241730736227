import { createContext, useEffect, useState } from "react";
import {
  GetUser,
  Login,
  GetUsers,
  UpdateCompany,
  GetNotificationEmails,
  AddNotificationEmail,
  DeleteNotificationEmail,
  GetApprovalRequirements,
  CheckSyncErp,
} from "../services";
import { saveInLocalStorage } from "../utils/localStorageHandler";
import { ADMIN, SERVICES, toastOptions } from "../constants";
import { toast } from "react-toastify";
import { hasSession } from "../utils";
import { useAmplitude } from "hooks";

export const UserContext = createContext();

export function UserProvider({ children }) {
  const [currentUser, setCurrentUser] = useState({});
  const [currentCompany, setCurrentCompany] = useState({});
  const [otherUsers, setOtherUsers] = useState({});
  const [loginError, setLoginError] = useState(false);
  const [currentUserIsAdmin, setCurrentUserIsAdmin] = useState(false);
  const [currentUserPermissions, setCurrentUserPermissions] = useState(false);
  const [approvationNeeded, setApprovationNeeded] = useState(false);
  const [notificationEmails, setNotificationEmails] = useState([]);
  const [isCompanySwitchModalVisible, setIsCompanySwitchModalVisible] =
    useState(false);
  const [dianUserType, setDianUserType] = useState(null);
  const [hasSiigoCredentials, setHasSiigoCredentials] = useState(false);
  const { setAmplitudeProps } = useAmplitude();

  const updateCollectionsCheckoutConfiguration = (name, value) => {
    setCurrentCompany((company) => {
      return {
        ...company,
        [name]: value,
      };
    });
  };

  const getMe = async () => {
    return Promise.all([
      GetUser(),
      GetApprovalRequirements(),
      CheckSyncErp(),
    ]).then(([user, approvalRequirements, syncCheck]) => {
      if (user.company) {
        setCurrentCompany(user.company);
        setCurrentUser(user);
        setCurrentUserIsAdmin(user.role.name === ADMIN);
        setApprovationNeeded(approvalRequirements.invoices);
        setCurrentUserPermissions(user.role.permissions);
        setAmplitudeProps(user);
        setHasSiigoCredentials(syncCheck?.syncValidation?.credentials);
      }
    });
  };

  const getOtherUsers = async () => {
    const gottenUsers = await GetUsers();
    if (gottenUsers !== null) {
      setOtherUsers(gottenUsers);
    }
  };

  const updateCompany = async ({
    user,
    pass,
    system,
    name,
    taxRegime,
    approvalRequired,
    sourceOfFundsRequired,
    legalRepresentativeDocumentType,
    legalRepresentativeDocumentNumber,
    collectionsCheckoutPartialPayments,
    collectionsCheckoutRetentions,
  }) =>
    UpdateCompany(currentCompany.id, {
      user_cs: user,
      password_cs: pass,
      name_cs: system,
      name: name,
      tax_regime: taxRegime,
      approval_requires: approvalRequired,
      source_of_funds_required: sourceOfFundsRequired,
      legal_representative: {
        document_type: legalRepresentativeDocumentType,
        document_number: legalRepresentativeDocumentNumber,
      },
      collections_checkout_partial_payments: collectionsCheckoutPartialPayments,
      collections_checkout_retentions: collectionsCheckoutRetentions,
    });

  const getEmailNotifications = async () => {
    const response = await GetNotificationEmails();
    if (response) {
      setNotificationEmails(response);
    } else toast.error("Hubo un problema al traer los mails", toastOptions);
  };

  const addNotificationEmail = async (email) => {
    await AddNotificationEmail(email)
      .then(() => {
        getEmailNotifications();
        toast.success("El mail ha sido agregado con éxito", toastOptions);
      })
      .catch(() =>
        toast.error("Ha ocurrido un error, intente nuevamente", toastOptions)
      );
  };
  const deleteNotificationEmail = async (emailId) => {
    await DeleteNotificationEmail(emailId)
      .then(() => {
        getEmailNotifications();
        toast.success("El mail ha sido borrado con éxito", toastOptions);
      })
      .catch(() =>
        toast.error("Ha ocurrido un error, intente nuevamente", toastOptions)
      );
  };

  const login = async (email, password) => {
    let response = {};
    setLoginError(false);
    const loginResponse = await Login({
      email,
      password,
    });
    response = { ...response, status: loginResponse.status };
    if (loginResponse.status === 200) {
      saveInLocalStorage("token", loginResponse.data.token);
      saveInLocalStorage("refreshToken", loginResponse.data.refreshToken);
      const user = await GetUser();
      setCurrentCompany(user.company);
      setCurrentUser(user);
      response = { ...response, user: user };
      setLoginError(false);
    } else if (loginResponse.status === 401) {
      if (loginResponse.data === "Your company is blocked") {
        response = { ...response, isBlocked: true };
      } else {
        setLoginError(true);
      }
    }
    return response;
  };

  useEffect(() => {
    if (hasSession()) {
      getMe();
    }
  }, []);

  const currentUserIsBackofficer = currentUser?.backoffice_user;
  const currentUserIsLoggedIn = !!currentUser?.id;
  const sourceOfFundsNeeded =
    currentUser?.company?.source_of_funds_required !== undefined
      ? currentUser?.company?.source_of_funds_required
      : true;

  const companyWithWompi = [5081, 860, 2699, 7293, 7837]; //5081 is Siigo test company
  const canPayWithCobre = !companyWithWompi.includes(currentUser?.company?.id);

  const displayCobreAlert = currentUser?.company?.display_cobre_alert || false;
  const newPricing = currentUser?.company?.pricing || false;
  const newPricingMetadata = currentUser?.company?.pricing_metadata || {
    amount: 3300,
  };
  const canIntegrateWithDian = currentUser?.company?.dian || false;
  const canDisplayCausation = currentUser?.company?.causation || false;
  const blockedCausation = currentUser?.company?.causation_blocked || false;
  const canDisplayAssistedEgress = hasSiigoCredentials || false;
  const comissionRetentions =
    currentUser?.company?.comission_retentions?.filter(
      (retention) => retention.key !== "none"
    ) || [];
  const comissionRetentionIsEnabled =
    currentUser?.company?.comission_retention || false;
  const comissionRetentionsIsEmpty =
    currentUser?.company?.comission_retentions?.length === 0;
  const companyCollectionsCommission =
    parseFloat(currentUser?.company?.collection_comission) || 0;
  const companyCollectionsFixedCommission =
    parseFloat(currentUser?.company?.collection_comission_fix_amount) || 0;
  const blockedCompanies = [];
  const blockedCompany = blockedCompanies.includes(currentUser?.company?.id);
  const companiesWithDuesReminders = [5110];
  const companyWithDuesReminders = companiesWithDuesReminders.includes(
    currentUser?.company?.id
  );
  const hasOneAssistedEgress =
    currentUser?.company?.hasOneAssistedEgress || false;
  const hasTransactionsLimit =
    currentUser?.company?.hasTransactionsLimit || false;
  const isReconciliationsEnabled =
    currentUser?.company?.reconciliations || false;

  const isFeatureEnabled = (featureKey) => {
    return currentUser?.company && currentUser?.company[featureKey];
  };

  const [syncBannerForCausationScreen, setSyncBannerForCausationScreen] =
    useState({ visible: false, type: "", title: "", text: "" });

  return (
    <UserContext.Provider
      value={{
        currentUser,
        canPayWithCobre,
        companyCollectionsCommission,
        companyCollectionsFixedCommission,
        canIntegrateWithDian,
        displayCobreAlert,
        sourceOfFundsNeeded,
        currentUserIsBackofficer,
        currentUserIsLoggedIn,
        setCurrentUser,
        currentCompany,
        setCurrentCompany,
        login,
        otherUsers,
        getOtherUsers,
        loginError,
        getMe,
        updateCompany,
        currentUserIsAdmin,
        approvationNeeded,
        notificationEmails,
        getEmailNotifications,
        addNotificationEmail,
        deleteNotificationEmail,
        newPricing,
        newPricingMetadata,
        comissionRetentions,
        comissionRetentionIsEnabled,
        comissionRetentionsIsEmpty,
        canDisplayCausation,
        blockedCompany,
        currentUserPermissions,
        companyWithDuesReminders,
        updateCollectionsCheckoutConfiguration,
        hasCollectionReceiversEnabled: !(
          currentCompany.collections_checkout_configuration &&
          (currentCompany?.collection_checkout_partial_payments ||
            currentCompany?.collection_checkout_retentions)
        ),
        isCompanySwitchModalVisible,
        setIsCompanySwitchModalVisible,
        canDisplayAssistedEgress,
        dianUserType,
        setDianUserType,
        hasOneAssistedEgress,
        hasTransactionsLimit,
        blockedCausation,
        isReconciliationsEnabled,
        hasSiigoCredentials,
        isFeatureEnabled,
        causationScreen: {
          syncBanner: {
            value: syncBannerForCausationScreen,
            setter: setSyncBannerForCausationScreen,
          },
        },
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
