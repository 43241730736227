import React, { useContext } from "react";
import {
  Autocomplete,
  Box,
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { es } from "date-fns/locale";
import Switch from "react-ios-switch";
import { styles } from "../styles";
import { documentTypeINITIAL } from "../constants";
import FileUpload from "commons/modals/ExpandInvoiceModal/components/FileUpload";
import { SyncInformation } from "./SyncInformation/SyncInformation";
import { CausationContext } from "contexts";
import { erpNames } from "constants";
import { CalendarIcon } from "assets";
import { erpLabels } from "constants";

export function Form({
  formValues,
  search,
  checked,
  handleOnChange,
  handleOnChangeAutoComplete,
  getSearchOptionsSupplier,
  setFormValues,
  setChecked,
  isCaused,
  hasFilePath = false,
  selectedFiles,
  setSelectedFiles,
  id,
  lastSyncCausationAt = null,
  supplierFound = false,
  setSupplierFound = () => {},
}) {
  const { erpName } = useContext(CausationContext);
  const havePaymentDueDate =
    formValues?.paymentType?.due_type === -1 ? true : false;

  const haveCenterCost = formValues.documentType?.use_cost_center
    ? true
    : false;

  return (
    <Box sx={{ ...styles.container, paddingLeft: checked ? "32px" : "" }}>
      <Stack spacing={2}>
        <SyncInformation
          lastSyncCausationAt={lastSyncCausationAt}
          formValues={formValues}
          handleOnChangeAutoComplete={handleOnChangeAutoComplete}
          setSupplierFound={setSupplierFound}
        />
        <Stack spacing={1}>
          <label htmlFor="documentType" style={styles.inputLabel}>
            Tipo de documento
          </label>
          <Select
            name="documentType"
            size="small"
            variant="outlined"
            displayEmpty={true}
            onChange={handleOnChange}
            sx={{
              fontSize: "14px",
              "& .MuiSelect-select": {
                fontSize: "14px",
              },
            }}
            value={formValues.documentType}
            inputProps={{
              id: "documentType",
            }}
            disabled={isCaused}
          >
            <MenuItem
              value={documentTypeINITIAL}
              style={{ display: "none" }}
            ></MenuItem>
            {search.invoiceType.map((type) => (
              <MenuItem key={type.id} value={type} sx={{ fontSize: "14px" }}>
                {type.erp_doc_class} -{" "}
                {type?.internal_description || type?.name}
              </MenuItem>
            ))}
          </Select>
        </Stack>
        <Stack spacing={1}>
          <label htmlFor="supplier" style={styles.inputLabel}>
            Proveedor
          </label>
          <Autocomplete
            disabled={isCaused}
            name="supplier"
            size="small"
            options={search.supplier}
            freeSolo
            getOptionLabel={(option) => option?.full_name || ""}
            onChange={(event, value) => {
              handleOnChangeAutoComplete("supplier", value);
              setSupplierFound(true);
            }}
            onInputChange={(event) => {
              getSearchOptionsSupplier("supplier", event?.target?.value);
              setSupplierFound(true);
            }}
            filterOptions={(options, { inputValue }) => {
              const inputValueLower = inputValue.toLowerCase();
              return options?.filter(
                (option) =>
                  option.full_name.toLowerCase().includes(inputValueLower) ||
                  option.identification.toString().includes(inputValueLower)
              );
            }}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option?.id} style={{ fontSize: "14px" }}>
                  {option.full_name}
                </li>
              );
            }}
            value={formValues.supplier}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  style: { fontSize: 14 },
                }}
                sx={{
                  "& .MuiAutocomplete-input": {
                    fontSize: "14px",
                  },
                }}
                placeholder={"Ingresa el nombre"}
              />
            )}
          />
          {!formValues.supplier?.id && !formValues.supplier?.full_name && (
            <Typography
              style={{
                color: "#667085",
                fontWeight: "400",
                fontSize: "14px",
              }}
            >
              Si no encuentras el proveedor, cárgalo en{" "}
              {erpLabels[erpName] || "Siigo Nube"} y vuelve a actualizar.
            </Typography>
          )}
          {formValues.supplier?.full_name && !supplierFound && (
            <Typography
              style={{
                color: "#F04438",
                fontWeight: "400",
                fontSize: "14px",
              }}
            >
              No encontramos el proveedor en{" "}
              {erpLabels[erpName] || "Siigo Nube"}, créalo y vuelve a
              actualizar.
            </Typography>
          )}
        </Stack>
        <Grid
          container
          direction={"row"}
          justifyContent={"space-between"}
          spacing={1}
          sx={{
            "& .MuiGrid-item": { paddingRight: "8px", paddingLeft: "0px" },
          }}
        >
          <Grid item xs={12} md={6}>
            <Stack spacing={1}>
              <label htmlFor="nroComprobante" style={styles.inputLabel}>
                Número de comprobante
              </label>
              <TextField
                disabled={isCaused}
                id="nroComprobante"
                name="nroComprobante"
                size="small"
                variant="outlined"
                onChange={handleOnChange}
                value={formValues.nroComprobante}
                InputProps={{
                  style: { fontSize: 14 },
                }}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            {![erpNames.worldOffice, erpNames.siigoPyme].includes(erpName) && (
              <Stack spacing={1}>
                <label htmlFor="costCenter" style={styles.inputLabel}>
                  Centro de costos
                </label>
                <Autocomplete
                  disabled={!haveCenterCost || isCaused}
                  name="costCenter"
                  size="small"
                  options={search.costCenter}
                  freeSolo
                  getOptionLabel={(option) =>
                    option?.code && option?.name
                      ? `${option.code} - ${option.name}`
                      : ""
                  }
                  onChange={(event, value) =>
                    handleOnChangeAutoComplete("costCenter", value)
                  }
                  onInputChange={(event) => {
                    getSearchOptionsSupplier(
                      "costCenter",
                      event?.target?.value
                    );
                  }}
                  filterOptions={(options, { inputValue }) => {
                    const inputValueLower = inputValue.toLowerCase();
                    return options?.filter(
                      (option) =>
                        option.name.toLowerCase().includes(inputValueLower) ||
                        option.code.toString().includes(inputValueLower)
                    );
                  }}
                  renderOption={(props, option) => {
                    return (
                      <li
                        {...props}
                        key={option?.id}
                        style={{ fontSize: "14px" }}
                      >
                        {option?.code} - {option?.name}
                      </li>
                    );
                  }}
                  sx={{
                    background: !formValues.documentType?.use_cost_center
                      ? "#F9FAFB"
                      : "",
                    "& .MuiAutocomplete-input": {
                      fontSize: "14px",
                    },
                    "& .MuiAutocomplete-option": {
                      fontSize: "14px",
                    },
                  }}
                  value={formValues.costCenter}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        style: { fontSize: 14 },
                      }}
                      placeholder={"Ingresa el centro de costos"}
                    />
                  )}
                />
              </Stack>
            )}
          </Grid>
        </Grid>
        <Grid
          container
          direction={"row"}
          justifyContent={"space-between"}
          spacing={1}
          sx={{
            "& .MuiGrid-item": { paddingRight: "8px", paddingLeft: "0px" },
          }}
        >
          <Grid item xs={12} md={havePaymentDueDate ? 4 : 6}>
            <Stack spacing={1}>
              <label htmlFor="dateElaboration" style={styles.inputLabel}>
                Fecha de elaboración
              </label>
              <LocalizationProvider
                adapterLocale={es}
                dateAdapter={AdapterDateFns}
              >
                <DatePicker
                  id="dateElaboration"
                  name="dateElaboration"
                  value={formValues.dateElaboration}
                  format="dd/MM/yyyy"
                  disabled={isCaused}
                  onChange={(value) =>
                    setFormValues((prev) => ({
                      ...prev,
                      dateElaboration: value,
                    }))
                  }
                  slots={{
                    openPickerIcon: () => <CalendarIcon stroke="#667085" />,
                  }}
                  slotProps={{
                    textField: {
                      InputProps: {
                        style: { fontSize: 14 },
                      },
                      size: "small",
                      error: false,
                    },
                  }}
                />
              </LocalizationProvider>
            </Stack>
          </Grid>
        </Grid>
        {id ? (
          <Box sx={styles.switchContainer}>
            <Switch
              checked={checked}
              className={undefined}
              disabled={!hasFilePath}
              handleColor="white"
              name={undefined}
              offColor="#D0D5DD"
              onChange={() => {
                setChecked(!checked);
              }}
              onColor="#667085"
              pendingOffColor={undefined}
              pendingOnColor={undefined}
              style={{ transform: "scale(0.6)", fontSize: "14px" }}
            />
            <span style={styles.switchText}>Visualizar archivo</span>
          </Box>
        ) : (
          <Box>
            <FileUpload
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
              isInvoiceFile={true}
            />
          </Box>
        )}
      </Stack>
    </Box>
  );
}
