import React, { useContext, useState, useRef } from "react";
import {
  renderAutocomplete,
  ServicesEmptyState,
} from "screens/ServicesScreenCommons/components";
import { ListPage } from "screens/ListPage";
import { servicesPaymentColumns } from "utils";
import { PageFooter } from "./PageFooter";
import { useSelectedRows } from "screens/ListPage/hooks/useSelectedRows";
import { CHECKOUT_MODAL } from "commons/modals/keys";
import { TRANSACTION_TYPE } from "constants/transactions";
import { ServicesCreateButtons } from "../ServicesScreenCommons/components/ServicesCreateButtons";
import { ServicesCreatePaymentModal } from "./ServicesCreatePaymentModal";
import { PaymentsContext, TransactionsContext } from "contexts";
import { MassiveCreateResultModal } from "./MassiveCreateModal/MassiveCreateResultModal";
import { useAbortableRequest } from "hooks";
import { StatusFilters } from "./Components/StatusFilters";
import { useMediaQuery } from "react-responsive";
import { Box } from "@mui/material";
import { SubNavigation } from "commons/Layout/components/SubNavigation";
import { getServicesPayments } from "services";
import { useNavigate } from "react-router-dom";

const getServicePayoutsNumberFromSelectedRows = (selectedServiceRows) => {
  const groupedRowsByServiceId = Object.groupBy(
    selectedServiceRows,
    (row) => row.original.service_id
  );
  return Object.keys(groupedRowsByServiceId).length;
};

export const ServicesPaymentScreen = () => {
  const { selectedRows, isChecked, toggleSelectedRow, toggleSelectedAll } =
    useSelectedRows();
  const csvImportButton = useRef(null);
  const { createInvoicesPayLaterTransaction } = useContext(TransactionsContext);
  const { statusSelected, setStatusSelected } = useContext(PaymentsContext);
  const selectedRowsIds = selectedRows.map((row) => row.original.id);
  const [isManualCreateOpen, setIsManualCreateOpen] = useState(false);
  const [refreshId, setRefreshId] = useState(0);
  const totalAmount = selectedRows.reduce(
    (acc, row) => acc + row.original.amount_total,
    0
  );
  const isMobile = useMediaQuery({ query: "(max-width: 481px)" });
  const navigate = useNavigate();

  const renderPageFooter = (setOpenModalKeys, setClosedModalKeys) => {
    const openCheckoutModal = () => {
      const checkoutModalProps = {
        type: TRANSACTION_TYPE.SERVICE,
        handleClose: () => setClosedModalKeys(CHECKOUT_MODAL),
        totalAmount,
        selectedRowsIds,
        payoutsNumber: getServicePayoutsNumberFromSelectedRows(selectedRows),
      };
      setOpenModalKeys(CHECKOUT_MODAL, checkoutModalProps);
    };
    return (
      <PageFooter
        selectedRows={selectedRows}
        totalAmount={totalAmount}
        selectedRowsIds={selectedRowsIds}
        openWompiModal={openCheckoutModal}
        payLater={() => {
          createInvoicesPayLaterTransaction(
            selectedRowsIds,
            TRANSACTION_TYPE.SERVICE
          );
          navigate("/services/pay-later", { replace: true });
          onClose();
        }}
        showApprove={false}
        showPayLater={true}
      />
    );
  };
  const openManualCreate = () => setIsManualCreateOpen(true);
  const onClose = () => {
    getServicesPayments();
    setIsManualCreateOpen(false);
  };
  const openMassiveCreate = () => csvImportButton.current.click();
  const [query, setQuery] = useState("");
  const [optionsToFilter, setOptionsToFilter] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const getSearchOptions = async (q) => setQuery(q);

  useAbortableRequest({
    requestConfig: {
      url: `/services/agenda?query=${query}`,
    },
    preventRequest: () => query.length > 1 && query.length < 3,
    onInitRequest: () => {},
    onAbortRequest: () => {},
    requestThen: (response) => {
      setOptionsToFilter(response);
    },
  });

  const autocomplete = () =>
    renderAutocomplete({
      isMobile: isMobile,
      inputValue: selectedOption?.name || query,
      searchOptions: optionsToFilter,
      onTableSearchSelected: setSelectedOption,
      getSearchOptions,
    });
  const url = "/services/invoices";
  const queries = {
    ...(selectedOption?.id && { service_id: selectedOption?.id }),
    ...(refreshId && { refresh_id: refreshId }),
    ...(statusSelected && { status: statusSelected }),
  };
  const hasScheduledServices = optionsToFilter?.length > 0;
  return (
    <>
      <ListPage
        url={url}
        queries={queries}
        selectedRows={selectedRows}
        getTableData={(response) => response?.invoices || []}
        buttons={
          <ServicesCreateButtons
            openManualCreate={openManualCreate}
            openMassiveCreate={openMassiveCreate}
          />
        }
        isChecked={isChecked}
        toggleSelectedRow={toggleSelectedRow}
        toggleSelectedAll={toggleSelectedAll}
        section="services"
        pageTitle="Servicios"
        tableColumns={servicesPaymentColumns}
        useCheckboxes
        pageEmptyState={
          <ServicesEmptyState
            openManualCreate={openManualCreate}
            openMassiveCreate={openMassiveCreate}
            currentScreenTitle={"Servicios"}
            hasScheduledServices={hasScheduledServices}
            subNavigation={
              <Box sx={{ marginTop: "4px" }}>
                <SubNavigation type="services" />
              </Box>
            }
            pageTitle={"Servicios"}
          />
        }
        renderAutocomplete={autocomplete}
        renderPageFooter={renderPageFooter}
        StatusFilters={
          <StatusFilters
            statusSelected={statusSelected}
            setStatusSelected={setStatusSelected}
          />
        }
        subNavigation={
          <Box sx={{ marginTop: "4px" }}>
            <SubNavigation type="services" />
          </Box>
        }
        showSubNavigation={true}
      />
      <ServicesCreatePaymentModal
        visible={isManualCreateOpen}
        onClose={onClose}
      />
      <MassiveCreateResultModal
        csvImportButton={csvImportButton}
        onClose={() => {
          setRefreshId((id) => id + 1);
        }}
      />
    </>
  );
};
