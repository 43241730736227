import React, { useContext, useEffect, useState } from "react";
import { TrackJS } from "trackjs";
import { useParams, useNavigate } from "react-router-dom";
import { Box, Dialog } from "@mui/material";
import {
  GetInvoice,
  GetTags,
  SearchBOSuppliers,
  SearchSuppliers,
  GetService,
  showService,
} from "../../../services";
import { styles } from "../../Table/styles";
import { InvoiceFormWithImage, ModalSkeleton } from "./components";
import { PaymentsContext, UserContext } from "../../../contexts";
import { SupplierDetails } from "../SupplierDetails/SupplierDetails";
import { PrimaryButton, SecondaryButton, ThirdButton } from "../../buttons";
import { objectIsEmpty } from "../../../utils/objectUtils";
import { fetchDianPDF } from "../../../services/fetch-dian-url";
import { useChat } from "../../../hooks";
import { useIsUserAllowedTo } from "utils";
import { permissions } from "constants";
import { toast } from "react-toastify";
import { toastOptions } from "constants";
import { causationStates } from "constants";
import { PAYANA_DOCUMENT_NUMBER } from "constants";
import { XIcon } from "assets";

const resolveFilePath = (uploadedFile, invoice) =>
  uploadedFile?.location || invoice.file_path;

export const ExpandInvoiceModal = () => {
  const { id } = useParams();
  const { isUserAllowedTo } = useIsUserAllowedTo();
  const navigate = useNavigate();
  const [invoice, setInvoice] = useState();
  const [supplier, setSupplier] = useState({});
  const [service, setService] = useState({});
  const [style, setStyle] = useState({});
  const [selectedFiles, setSelectedFiles] = useState(undefined);
  const { approvationNeeded, currentUserIsAdmin, currentUser } =
    useContext(UserContext);
  const [createdSupplier, setCreatedSupplier] = useState(null);
  const [createdService, setCreatedService] = useState(null);
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const [searchOptions, setSearchOptions] = useState([]);
  const [isPayanaInvoice, setIsPayanaInvoice] = useState(false);
  const [tags, setTags] = useState([]);
  const [tagsSelected, setTagsSelected] = useState([]);
  const {
    getAllSuppliers,
    getSupplier,
    updateInvoice,
    uploadInvoiceFile,
    approveInvoices,
  } = useContext(PaymentsContext);
  const [formValues, setFormValues] = useState({
    provider_id: "",
    service_id: "",
    invoice_number: "",
    issue_date: null,
    expiration_date: null,
    amount: "",
    type: "",
    concept: "",
  });
  const [collectionNumberError, setCollectionNumberError] = useState({
    helperText: "",
    error: false,
  });
  const [conceptError, setConceptError] = useState({
    helperText: "",
    error: false,
  });
  const invoiceStatusIncludes = (...states) =>
    states.includes(invoice.status.toLowerCase());

  useChat();

  const filePathIsFromDian = invoice?.file_path?.includes(
    "https://catalogo-vpfe.dian.gov.co/document"
  );

  const isCollectionAccount = formValues.type === "collection_account";

  useEffect(() => {
    if (filePathIsFromDian && invoice?.id) {
      fetchDianPDF(invoice?.id).then((response) => {
        response &&
          setInvoice((invoice) => {
            return {
              ...invoice,
              file_path: response.url,
              expiration_date: response.expiration_date,
            };
          });
      });
    }
  }, [invoice?.file_path]);

  useEffect(() => {
    getAllSuppliers();
    Promise.all([GetInvoice({ id: id }), GetTags()]).then(([invoice, tags]) => {
      if (invoice.id) {
        setIsPayanaInvoice(
          +invoice.provider_document_number === PAYANA_DOCUMENT_NUMBER
        );
        setInvoice(invoice);
        setTags(tags);
        setTagsSelected(invoice.tags);
        if (invoice.service_id) {
          setService({
            id: invoice.service_id,
            alias: invoice.service_name,
            referral_code: invoice.service_number,
          });
        } else {
          setSupplier({
            id: invoice.provider_id,
            name: invoice.provider_name,
          });
        }
      } else {
        navigate("/error");
      }
    });
  }, []);

  useEffect(() => {
    if (createdSupplier) {
      setSupplier(createdSupplier);
      setFormValues((formValues) => {
        return {
          ...formValues,
          provider_id: createdSupplier.id,
        };
      });
    }
  }, [createdSupplier]);

  useEffect(() => {
    if (createdService) {
      setService(createdService);
      setFormValues((formValues) => {
        return {
          ...formValues,
          service_id: createdService.id,
        };
      });
    }
  }, [createdService]);

  useEffect(() => {
    if (invoice) {
      if (invoice.provider_id) {
        getSupplier(invoice.provider_id).then((invoiceSupplier) => {
          invoiceSupplier && setSupplier(invoiceSupplier);
        });
      }
      setFormValues({
        provider_id: invoice.provider_id || "",
        service_id: invoice.service_id || "",
        invoice_number: invoice.invoice_number || "",
        issue_date: invoice.issue_date
          ? new Date(invoice.issue_date.slice(0, -1))
          : null,
        expiration_date: invoice.expiration_date
          ? new Date(invoice.expiration_date.slice(0, -1))
          : null,
        amount: invoice.amount_total,
        type: invoice.type,
        concept: invoice.concept || "",
      });
      setStyle({ padding: "0px" });
    }
  }, [invoice]);

  const approveInvoice = async () => {
    const response = await approveInvoices([invoice.id]);
    if (response === 200) {
      navigate("/payments/invoices");
    }
  };

  const saveAndRedirect = async (
    goToCausation = false,
    approveFlow = false
  ) => {
    const uploadedFile = await uploadInvoiceFile(selectedFiles);
    const response = await updateInvoice(id, {
      ...formValues,
      file_path: resolveFilePath(uploadedFile, invoice),
      tags: tagsSelected.map((tag) => tag.id),
    });
    if (response === 200) {
      if (goToCausation && isCollectionAccount) {
        navigate(`/payments/causation/${id}`, {
          replace: true,
          ...(invoice.type === "collection_account" &&
            invoice.pdf_state === "in_process" && {
              state: {
                createCollectionAccount: true,
                invoiceNumber: formValues.invoice_number,
                amount: formValues.amount,
                issueDate: new Date(formValues.issue_date),
              },
            }),
        });
      } else if (approveFlow) {
        approveInvoice();
      } else {
        toast.success("¡La factura se actualizó con éxito!", toastOptions);
        navigate("/payments/invoices");
      }
    }
  };

  const resetForm = () => {
    setFormValues({
      provider_id: "",
      service_id: "",
      invoice_number: "",
      issue_date: null,
      expiration_date: null,
      amount: "",
      type: "",
      concept: "",
    });
  };

  const getAndSetSupplier = (supplier) => {
    getSupplier(supplier.id).then((sup) => {
      sup && setSupplier(sup);
    });
  };

  const getAndSetService = (service) => {
    GetService({ id: service.id }).then((res) => {
      res && setService(res);
    });
  };

  const getSearchOptions = (query) => {
    if (!currentUser.backoffice_user) {
      if (!objectIsEmpty(service)) {
        showService(query).then((resService) => {
          setSearchOptions(resService);
        });
      } else {
        SearchSuppliers({ query: query, withAnayap: false }).then((res) => {
          setSearchOptions(res);
        });
      }
    } else {
      SearchBOSuppliers(query).then((res) => {
        setSearchOptions(res);
      });
    }
  };

  const onTagSelect = (tags) => {
    setTagsSelected(tags);
  };

  const onTagRemove = (tags) => {
    setTagsSelected(tags);
  };

  const onClose = () => {
    resetForm();
    navigate(-1);
  };

  const handleError = (error) => {
    TrackJS.console.error(error);
    navigate("/error");
  };

  const handleSupplierUpdateSuccess = async (supplier) => {
    try {
      setCreatedSupplier(supplier);
      const invoice = await GetInvoice({ id });
      if (invoice.id) {
        setInvoice(invoice);
      } else {
        handleError(new Error(`invoice id undefined invoice_id: ${id}`));
      }
    } catch (error) {
      handleError(error);
    } finally {
      setDrawerIsOpen(false);
    }
  };

  return (
    <>
      <Dialog fullScreen open={!!id} style={style}>
        <div className="modal-header-payana">
          <div style={styles.headerRow}>
            <div
              onClick={() => {
                onClose();
              }}
              style={{ cursor: "pointer" }}
            >
              <XIcon width={16} height={16} strokeWidth={1} />
            </div>
            <div
              className="modalTitle"
              style={{ marginLeft: "16px", fontSize: "16px", marginTop: "3px" }}
            >
              Detalle de comprobante
            </div>
          </div>
          {invoice && isUserAllowedTo(permissions.INVOICES_UPDATE) && (
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "8px",
              }}
            >
              {invoice &&
                approvationNeeded &&
                currentUserIsAdmin &&
                !invoice.approved &&
                invoiceStatusIncludes(
                  "pending",
                  "outstanding_balance",
                  "to_be_approved",
                  "to_be_checked"
                ) && (
                  <ThirdButton
                    text={"Aprobar"}
                    action={() => saveAndRedirect(false, true)}
                  />
                )}
              {invoice &&
              invoiceStatusIncludes(
                "pending",
                "outstanding_balance",
                "to_be_approved",
                "to_be_checked"
              ) &&
              isCollectionAccount &&
              invoice?.causation_state === causationStates.draft ? (
                <>
                  {!isPayanaInvoice && (
                    <SecondaryButton
                      text="Guardar"
                      action={() => saveAndRedirect()}
                      isDisable={
                        collectionNumberError.error ||
                        conceptError.error ||
                        !formValues.amount ||
                        (!formValues.provider_id && !formValues.service_id)
                      }
                      width="100%"
                    />
                  )}
                  <PrimaryButton
                    text="Ir a causar"
                    action={() => {
                      saveAndRedirect(true);
                    }}
                    isDisable={
                      collectionNumberError.error ||
                      conceptError.error ||
                      !formValues.amount ||
                      (!formValues.provider_id && !formValues.service_id)
                    }
                    style={{ marginLeft: "16px" }}
                    width="100%"
                  />
                </>
              ) : (
                <>
                  {invoiceStatusIncludes(
                    "pending",
                    "outstanding_balance",
                    "to_be_approved",
                    "to_be_checked"
                  ) &&
                    isUserAllowedTo(permissions.INVOICES_UPDATE) && (
                      <PrimaryButton
                        text="Guardar"
                        action={() => saveAndRedirect()}
                        isDisable={
                          collectionNumberError.error ||
                          conceptError.error ||
                          !formValues.amount ||
                          (!formValues.provider_id && !formValues.service_id) ||
                          isPayanaInvoice
                        }
                        width="100%"
                      />
                    )}
                </>
              )}
            </Box>
          )}
        </div>
        <Box sx={{ padding: 2 }}>
          {!invoice ? (
            <ModalSkeleton />
          ) : (
            <>
              <InvoiceFormWithImage
                isPayanaInvoice={isPayanaInvoice}
                openSupplierForm={() => setDrawerIsOpen(invoice?.provider_id)}
                filePathIsFromDian={filePathIsFromDian}
                filePath={invoice?.file_path}
                invoiceId={id}
                selectedFiles={selectedFiles}
                setSelectedFiles={setSelectedFiles}
                formValues={formValues}
                setFormValues={setFormValues}
                invoice={invoice}
                supplier={supplier}
                service={service}
                setSupplier={getAndSetSupplier}
                setService={getAndSetService}
                suppliers={[...searchOptions, supplier, service]}
                createdSupplier={createdSupplier}
                setDrawerIsOpen={setDrawerIsOpen}
                getOptions={(query) => getSearchOptions(query)}
                approvationNeeded={approvationNeeded}
                tags={tags}
                onSelect={onTagSelect}
                onRemove={onTagRemove}
                setTags={setTags}
                setTagsSelected={setTagsSelected}
                conceptError={conceptError}
                setConceptError={setConceptError}
                collectionNumberError={collectionNumberError}
                setCollectionNumberError={setCollectionNumberError}
              />
            </>
          )}
        </Box>
      </Dialog>
      <SupplierDetails
        isOpen={!!drawerIsOpen}
        id={drawerIsOpen}
        handleClose={() => setDrawerIsOpen(false)}
        handleSuccess={(supplier, service) => {
          if (service) {
            setCreatedService(service);
          } else {
            handleSupplierUpdateSuccess(supplier);
          }
        }}
        isOnlyService={!objectIsEmpty(service)}
        isOnlySupplier={!objectIsEmpty(supplier)}
      />
    </>
  );
};
