import { Box } from "@mui/material";
import { useContext } from "react";
import { ModalHeader } from "./ModalHeader";
import { PageFooter } from "./PageFooter";
import { useSelectedRows } from "screens/ListPage/hooks/useSelectedRows";
import { TransactionsContext, UiContext } from "contexts";
import { CHECKOUT_MODAL } from "commons/modals/keys";
import { TRANSACTION_TYPE } from "constants";
import { MassiveCreateDebtsList } from "./MassiveCreateDebtsList";
import {
  getPayloadToCreateInvoicesFromDebts,
  groupDebtsByServiceCompany,
} from "./getPayloadToCreateInvoicesFromDebts";

export const MassiveCreateDebtList = ({ onClose, debts = [] }) => {
  const { createInvoicesPayLaterTransaction } = useContext(TransactionsContext);
  const {
    selectedRows,
    isChecked,
    toggleSelectedRow,
    resetSelectedRows,
    selectedRowsIds,
    addSelectedRow,
    removeSelectedRow,
  } = useSelectedRows("debtId");
  const {
    hooks: { setOpenModalKeys, setClosedModalKeys },
  } = useContext(UiContext);
  const totalAmount = selectedRows.reduce(
    (acc, row) => acc + row.original.amount,
    0
  );

  const closeModal = () => {
    onClose();
    setTimeout(() => {
      resetSelectedRows();
    }, 500);
  };
  const openCheckoutModal = () => {
    const debtsGroupedByServiceCompany = groupDebtsByServiceCompany(
      debts,
      selectedRowsIds,
      selectedRows
    );
    const checkoutModalProps = {
      type: TRANSACTION_TYPE.SERVICE,
      handleClose: () => setClosedModalKeys(CHECKOUT_MODAL),
      totalAmount,
      selectedRowsIds,
      customPayload: getPayloadToCreateInvoicesFromDebts(
        debts,
        selectedRowsIds,
        selectedRows
      ),
      payoutsNumber: debtsGroupedByServiceCompany.length,
    };
    setOpenModalKeys(CHECKOUT_MODAL, checkoutModalProps);
  };
  const payLater = async () => {
    await createInvoicesPayLaterTransaction(
      selectedRowsIds,
      TRANSACTION_TYPE.SERVICE,
      getPayloadToCreateInvoicesFromDebts(debts, selectedRowsIds, selectedRows)
    );
    closeModal();
  };
  return (
    <>
      <div className="modal-header-payana">
        <ModalHeader onClose={closeModal} />
      </div>
      <Box sx={{ padding: 2, display: "flex", justifyContent: "center" }}>
        <MassiveCreateDebtsList
          debts={debts}
          isChecked={isChecked}
          toggleSelectedRow={toggleSelectedRow}
          addSelectedRow={addSelectedRow}
          removeSelectedRow={removeSelectedRow}
        />
      </Box>
      <PageFooter
        openWompiModal={openCheckoutModal}
        totalAmount={totalAmount}
        selectedRows={selectedRows}
        selectedRowsIds={selectedRowsIds}
        payLater={payLater}
        showApprove={false}
      />
    </>
  );
};
