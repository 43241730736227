import { TRANSACTION_TYPE } from "constants";

const fixedAmountPerPayoutFields = {
  fallback: "amount",
  [TRANSACTION_TYPE.SERVICE]: "service_comission_amount",
};
const percentageFields = {
  fallback: "payment_percentage",
  [TRANSACTION_TYPE.SERVICE]: "service_comission_percentage",
};

const getPercentageFromAmount = (amount, percentage) =>
  Math.round((amount / 100) * percentage);

const getValueByFieldName = (metadata, fieldsNames) => {
  const fieldName = fieldsNames[metadata.type];
  return metadata[fieldName] ?? metadata[fieldsNames.fallback];
};
const getPercentageValue = (metadata) =>
  getValueByFieldName(metadata, percentageFields);

const getFixedAmountPerPayoutValue = (metadata) =>
  getValueByFieldName(metadata, fixedAmountPerPayoutFields);

const fallbackStrategy = () => ({
  label: "",
  grossComission: 0,
});

const calculateCommissionForCardMethod = (metadata) => {
  const { totalAmount: amount, cardFeePercentage: percentage } = metadata;
  return {
    label: `Comisión Tarjeta de Crédito (${percentage}%)`,
    grossComission: getPercentageFromAmount(amount, percentage),
  };
};
const calculateCommissionForOthersMethod = (metadata) => {
  const { totalAmount: amount } = metadata;
  const percentage = getPercentageValue(metadata);
  return {
    label: `Comisión Payana (${percentage}%)`,
    grossComission: getPercentageFromAmount(amount, percentage),
  };
};
const calculateCommissionForOthersMethodFixedAmount = (metadata) => {
  const { payoutsNumber, formatAmount } = metadata;
  const payoutEntityNames = {
    fallback: "documento",
    invoice: "proveedor",
    service: "servicio",
  };
  const payoutEntityName =
    payoutEntityNames[metadata.type] || payoutEntityNames.fallback;

  const fixedAmountPerPayout = getFixedAmountPerPayoutValue(metadata);

  const label = `Comisión Payana (${formatAmount.format(
    fixedAmountPerPayout
  )}/${payoutEntityName})`;

  return {
    label,
    grossComission: Math.round(payoutsNumber * fixedAmountPerPayout),
  };
};

export {
  fallbackStrategy,
  getPercentageFromAmount,
  getPercentageValue,
  getFixedAmountPerPayoutValue,
  calculateCommissionForCardMethod,
  calculateCommissionForOthersMethod,
  calculateCommissionForOthersMethodFixedAmount,
};
