import { Grid, Stack } from "@mui/material";
import React, { useContext } from "react";
import { Delete } from "./Delete";
import { isAdvance } from "../../constants";
import { PaymentSelect } from "./PaymentSelect";
import { InputDate } from "./InputDate";
import { SelectAdvance } from "./SelectAdvance";
import { InputAmount } from "./InputAmount";
import { CausationContext } from "contexts";
import { InputShareNumber } from "./InputShareNumber";

export const PaymentRow = ({
  paymentsSelected = [],
  handleOnChangePayment,
  paymentsSearch,
  paymentsAdvances,
  handleDeletePayment,
  isCaused,
}) => {
  const { hasErpCausation } = useContext(CausationContext);

  const getGridXs = (defaultXs, siigoXs) =>
    hasErpCausation ? siigoXs : defaultXs;

  return (
    <Stack gap={"8px"} width={"100%"} minWidth={651}>
      {paymentsSelected.map((payment, index) => (
        <Grid
          key={index}
          container
          direction={"row"}
          justifyContent={"space-between"}
          spacing={1}
        >
          <Grid item xs={getGridXs(5, 4)}>
            <PaymentSelect
              paymentsSearch={paymentsSearch}
              payment={payment}
              index={index}
              handleOnChangePayment={handleOnChangePayment}
              isCaused={isCaused}
            />
          </Grid>
          <Grid item xs={3}>
            {payment?.accounting_concept !== isAdvance && (
              <InputDate
                payment={payment}
                index={index}
                handleOnChangePayment={handleOnChangePayment}
                isCaused={isCaused}
              />
            )}
            {payment?.accounting_concept === isAdvance && (
              <SelectAdvance
                paymentsAdvances={paymentsAdvances}
                payment={payment}
                index={index}
                handleOnChangePayment={handleOnChangePayment}
              />
            )}
          </Grid>
          {hasErpCausation && (
            <Grid item xs={2}>
              <InputShareNumber
                payment={payment}
                index={index}
                handleOnChangePayment={handleOnChangePayment}
              />
            </Grid>
          )}
          <Grid item xs={getGridXs(3, 2)}>
            <InputAmount
              payment={payment}
              index={index}
              handleOnChangePayment={handleOnChangePayment}
              isCaused={isCaused}
            />
          </Grid>
          {!isCaused && (
            <Grid item xs={1}>
              <Delete index={index} handleDeletePayment={handleDeletePayment} />
            </Grid>
          )}
        </Grid>
      ))}
    </Stack>
  );
};
