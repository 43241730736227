import { PaymentModal } from "commons";
import { RegisterSourceOfFundsModal } from "commons/modals/RegisterSourceOfFundsModal";
import { useSteps } from "hooks";

const PAYMENT_METHOD_SELECTOR = "PaymentMethodSelector";
const SOURCE_OF_FUNDS = "SourceOfFunds";

const CHECKOUT_MODAL_STEPS = {
  INITIAL_STEP: SOURCE_OF_FUNDS,
  SOURCE_OF_FUNDS,
  SOURCE_OF_FUNDS_NEXT: PAYMENT_METHOD_SELECTOR,
  PAYMENT_METHOD_SELECTOR,
};

const CheckoutModal = ({ sourceOfFundsNeeded, ...props }) => {
  const modalByStepName = {
    [SOURCE_OF_FUNDS]: RegisterSourceOfFundsModal,
    [PAYMENT_METHOD_SELECTOR]: PaymentModal,
    fallback: RegisterSourceOfFundsModal,
  };
  const { setStep, getValueByStepName } = useSteps(CHECKOUT_MODAL_STEPS);
  const Modal = getValueByStepName(modalByStepName);

  const handleClose = () => {
    setStep(CHECKOUT_MODAL_STEPS.INITIAL_STEP);
    props.handleClose();
  };

  return <Modal {...props} setStep={setStep} handleClose={handleClose} />;
};

export { CHECKOUT_MODAL_STEPS };

export default CheckoutModal;
