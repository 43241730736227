import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import { Box, CssBaseline, LinearProgress } from "@mui/material";
import { Sidebar } from "./Sidebar";
import { TopNavbar } from "./TopNavbar/TopNavbar";
import {
  PAGES_WITHOUT_NAVBAR,
  HIDE_NAVBAR_MENU_OPTIONS,
  HIDE_NAVBAR_MENU_USER,
} from "../../screens/SettingsScreen/constants";
import useUserGuiding from "../../hooks/useUserGuiding";
import { hasSession } from "../../utils";
import { UiContext } from "../../contexts/UiContext";
import { CancelTransactionModal } from "../modals/DeleteItemModal/CancelTransactionModal";
import { DeleteItemModal } from "commons/modals";
import { CHECKOUT_MODAL, DELETE_ITEM_MODAL } from "commons/modals/keys";
import { UserContext } from "contexts";
import CheckoutModal from "commons/modals/CheckoutModal";
import { styles } from "./styles";

export function Layout({ children }) {
  const location = useLocation();
  useUserGuiding();
  const hasSidebar =
    hasSession() && !PAGES_WITHOUT_NAVBAR.includes(location.pathname);
  const showMenuOptions =
    hasSession() && !HIDE_NAVBAR_MENU_OPTIONS.includes(location.pathname);

  const showUserMenu =
    hasSession() && !HIDE_NAVBAR_MENU_USER.includes(location.pathname);
  const { state } = useContext(UiContext);
  const { canPayWithCobre, displayCobreAlert, sourceOfFundsNeeded } =
    useContext(UserContext);
  const { isLoadingLayout, openModalKeys, openModalProps } = state;

  return (
    <Box sx={styles.root}>
      <CssBaseline />
      {hasSidebar && (
        <>
          <Sidebar showMenuOptions={showMenuOptions} />
          <TopNavbar
            showMenuOptions={showMenuOptions}
            showUserMenu={showUserMenu}
          />
        </>
      )}
      {location.pathname === "/pay" && (
        <Sidebar showMenuOptions={showMenuOptions} />
      )}
      <Box component="main" sx={styles.mainContent}>
        {isLoadingLayout && (
          <Box sx={{ width: "100%" }}>
            <LinearProgress sx={{ height: 2 }} />
          </Box>
        )}
        {children}
      </Box>
      <CancelTransactionModal
        visible={openModalKeys.includes("cancel-transaction-modal")}
        {...openModalProps["cancel-transaction-modal"]}
      />
      {hasSession() && (
        <CheckoutModal
          visible={openModalKeys.includes(CHECKOUT_MODAL)}
          {...openModalProps[CHECKOUT_MODAL]}
          canPayWithCobre={canPayWithCobre}
          displayCobreAlert={displayCobreAlert}
          sourceOfFundsNeeded={sourceOfFundsNeeded}
        />
      )}
      <DeleteItemModal
        visible={openModalKeys.includes(DELETE_ITEM_MODAL)}
        {...openModalProps[DELETE_ITEM_MODAL]}
      />
    </Box>
  );
}
