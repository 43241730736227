import React, { useState, useContext, useEffect } from "react";
import { Box, Divider } from "@mui/material";
import { CollectionsContext, UserContext } from "../../../contexts";
import {
  PrimaryButton,
  SecondaryButton,
  ThirdButton,
} from "../../../commons/buttons";
import { getAccountTypeLabel } from "../../../constants";
import { styles } from "../styles";
import { objectIsEmpty } from "../../../utils/objectUtils";
import { ReceiverDetails } from "commons/modals/ReceiverDetails";

const BankData = () => {
  const { currentCompany, getMe } = useContext(UserContext);
  const { mainReceiver, getMainReceiver, banks, getBanks } =
    useContext(CollectionsContext);
  const [openModal, setOpenModal] = useState(false);
  const [selectedBank, setSelectedBank] = useState("");

  useEffect(() => {
    getBanks();
    getMainReceiver()
      .then(() => {
        setSelectedBank(banks.find((bank) => bank.id === mainReceiver.bank_id));
      })
      .catch(() => {});
  }, []);

  const handleOpenModal = () => {
    getMe();
    setOpenModal(true);
  };
  const handleCloseModal = () => setOpenModal(false);

  return (
    <Box sx={styles.notificationTable}>
      <ReceiverDetails
        isOpen={!!openModal}
        id={!objectIsEmpty(mainReceiver) ? mainReceiver.id : null}
        handleClose={() => {
          handleCloseModal(false);
        }}
        handleSuccess={(receiver) => {
          handleCloseModal(receiver);
          getMainReceiver();
        }}
        mainReceiver={mainReceiver}
        disableFields={true}
      />

      {objectIsEmpty(mainReceiver) ? (
        <div style={styles.emptyReceiverDataCard}>
          <p style={styles.receiverCardTitle} className="card-subtitle mb-2">
            Para poder recaudar necesitas cargar los datos de tu cuenta
            bancaria.
          </p>
          <ThirdButton
            text={"Completar información bancaria"}
            action={() => handleOpenModal()}
            width="fit-content"
            style={{ marginTop: "8px", marginBottom: "8px" }}
          />
        </div>
      ) : (
        <>
          <div style={styles.bankDataCardContainer}>
            <div style={styles.bankDataCard}>
              <p style={styles.bankCardTitle} className="card-subtitle mb-2">
                Titular
              </p>
              <p style={styles.bankCardText} className="card-subtitle mb-2">
                {currentCompany.name}
              </p>
            </div>
            <Divider sx={{ marginBottom: "8px" }} />
            <div style={styles.bankDataCard}>
              <p style={styles.bankCardTitle} className="card-subtitle mb-2">
                Entidad bancaria
              </p>
              <p style={styles.bankCardText} className="card-subtitle mb-2">
                {mainReceiver.bankName}
              </p>
            </div>
            <Divider sx={{ marginBottom: "8px" }} />
            <div style={styles.bankDataCard}>
              <p style={styles.bankCardTitle} className="card-subtitle mb-2">
                {mainReceiver?.account_type
                  ? getAccountTypeLabel(mainReceiver?.account_type)
                  : "Número de celular"}
              </p>
              <p style={styles.bankCardText} className="card-subtitle mb-2">
                {mainReceiver.account_number}
              </p>
            </div>
            <Divider sx={{ marginBottom: "8px" }} />
            <div style={styles.bankDataCard}>
              <p style={styles.bankCardTitle} className="card-subtitle mb-2">
                {mainReceiver.document_type || "NIT"}
              </p>
              <p style={styles.bankCardText} className="card-subtitle mb-2">
                {mainReceiver.document_number}
              </p>
            </div>
          </div>
          <SecondaryButton
            text={"Editar cuenta bancaria"}
            action={() => handleOpenModal()}
            width="fit-content"
            style={{ marginTop: "8px", marginBottom: "8px" }}
          />
        </>
      )}
    </Box>
  );
};

export default BankData;
