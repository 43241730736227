import { strategiesWithFallback } from "screens/ServicesPaymentScreen/strategiesWithFallback";
import {
  fallbackStrategy,
  getPercentageValue,
  getFixedAmountPerPayoutValue,
  calculateCommissionForCardMethod,
  calculateCommissionForOthersMethod,
  calculateCommissionForOthersMethodFixedAmount,
} from "./commons";
import { PAYMENT_METHOD_CARD_KEY, PAYMENT_METHOD_PSE_KEY } from "constants";

const getCommissionAmount = (metadata) => {
  const { enabled, selectedPaymentMethod } = metadata;

  const isPayingWithCard = selectedPaymentMethod === PAYMENT_METHOD_CARD_KEY;
  const isPayingWithPSE = selectedPaymentMethod === PAYMENT_METHOD_PSE_KEY;
  const hasPercentageDefined = getPercentageValue(metadata);
  const hasFixedAmountPerPayoutDefined = getFixedAmountPerPayoutValue(metadata);

  return strategiesWithFallback(fallbackStrategy)
    .when(
      () => isPayingWithCard,
      () => calculateCommissionForCardMethod(metadata)
    )
    .when(
      () => enabled && isPayingWithPSE && hasPercentageDefined,
      () => calculateCommissionForOthersMethod(metadata)
    )
    .when(
      () => enabled && isPayingWithPSE && hasFixedAmountPerPayoutDefined,
      () => calculateCommissionForOthersMethodFixedAmount(metadata)
    )
    .execute(metadata);
};

export { getCommissionAmount };
